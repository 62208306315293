import request from '@/utils/request'

export function getList(params) {
  return request({
    url: '/maint/notices',
    method: 'GET',
    params
  })
}

/**
 * 获取未读消息数量
 * @param {*} type
 * @returns
 */
export function getUnreadCount(type = 0) {
  return request({
    url: '/maint/notices/unread/' + type,
    method: 'GET'
  })
}

/**
 * 读取消息
 * @param {*} data
 * @returns
 */
export function readNotices(data) {
  return request({
    url: '/maint/notices/read',
    method: 'PUT',
    data
  })
}

/**
 * 读取消息返回信息
 * @param {*} id
 * @returns
 */
export function readNotice(id) {
  return request({
    url: '/maint/notices/read/' + id,
    method: 'PUT'
  })
}

/**
 * 获取推送消息
 * @param {*} id
 * @returns
 */
export function getPushNotices(id) {
  return request({
    url: '/maint/notices/push/' + id,
    method: 'GET'
  })
}

/**
 * 推送消息
 * @returns
 */
export function sendUnreadCount() {
  return request({
    url: '/maint/notices/unread',
    method: 'POST'
  })
}
