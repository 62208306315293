import request from '@/utils/request'

export function getList(params) {
  return request({
    url: '/identity/admin',
    method: 'GET',
    params
  })
}

export function saveUser(params) {
  let methodName = 'post'
  let url = '/identity/admin'
  if (params.id > 0) {
    methodName = 'put'
    url = url + '/' + params.id
  }
  return request({
    url: url,
    method: methodName,
    data: params
  })
}

export function remove(userId) {
  return request({
    url: `/identity/admin/${userId}`,
    method: 'DELETE'
  })
}

export function setRole(userId, roleIds) {
  return request({
    url: `/identity/admin/${userId}/roles`,
    method: 'put',
    data: roleIds
  })
}

export function changeStatus(userId, status) {
  return request({
    url: `/identity/admin/${userId}/status`,
    method: 'put',
    data: { 'value': status }
  })
}

export function changeShopStatus(tenantId, userId, status) {
  return request({
    url: `/identity/admin/${tenantId}/${userId}/status`,
    method: 'put',
    data: { 'value': status }
  })
}

export function changeStatusBatch(params) {
  return request({
    url: '/identity/admin/batch/status',
    method: 'put',
    data: params
  })
}

/**
 * 通过手机号获取所属商家信息
 * @param {*} phone
 * @returns
 */
export function getAdminShop(phone) {
  return request({
    url: '/identity/admin/shop/' + phone,
    method: 'GET'
  })
}

/**
 * 获取外部站点登录信息
 * @returns
 */
export function getSessionCode() {
  return request({
    url: '/identity/session/code/',
    method: 'GET'
  })
}

/**
 * 通过当前商户下用户信息
 * @returns
 */
export function getShopUser() {
  return request({
    url: '/identity/admin/user',
    method: 'GET'
  })
}