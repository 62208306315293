var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "user" }, [
        _c("strong", [_vm._v(_vm._s(_vm.user.name))]),
        _c("br"),
        _vm._v(" "),
        _c("small", [
          _vm._v(
            _vm._s(_vm.user.deptFullName) + "    " + _vm._s(_vm.user.roles)
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "user-content" },
        [
          _c("el-col", { staticClass: "profile", attrs: { span: 6 } }, [
            _c("img", {
              staticClass: "user-avatar",
              attrs: { src: require("@/assets/img/avatar.gif") }
            }),
            _c("br"),
            _vm._v(" "),
            _c("p", [
              _c("span", { staticClass: "title" }, [
                _c("i", { staticClass: "el-icon-phone" }),
                _vm._v("  " + _vm._s(_vm.user.phone))
              ])
            ]),
            _vm._v(" "),
            _c("p", [
              _c("span", { staticClass: "title" }, [
                _c("i", { staticClass: "el-icon-message" }),
                _vm._v("  " + _vm._s(_vm.user.email))
              ])
            ]),
            _vm._v(" "),
            _c("p", [
              _c("span", { staticClass: "title" }, [
                _c("i", { staticClass: "el-icon-open" }),
                _vm._v("  " + _vm._s(_vm.user.status == "1" ? "启用" : "禁用"))
              ])
            ]),
            _vm._v(" "),
            _c("p", [
              _c("span", { staticClass: "title" }, [
                _c("i", { staticClass: "el-icon-location-outline" }),
                _vm._v("  ")
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "el-col",
            { staticStyle: { "padding-left": "10px" }, attrs: { span: 18 } },
            [
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.activeName,
                    callback: function($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName"
                  }
                },
                [
                  _c("el-tab-pane", {
                    attrs: { label: "个人资料", name: "profile" }
                  }),
                  _vm._v(" "),
                  _c("el-tab-pane", {
                    attrs: { label: "最近活动", name: "timeline" }
                  }),
                  _vm._v(" "),
                  _c("el-tab-pane", {
                    attrs: { label: "修改密码", name: "updatePwd" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-timeline",
                { attrs: { reverse: _vm.reverse } },
                _vm._l(_vm.activities, function(activity, index) {
                  return _c(
                    "el-timeline-item",
                    { key: index, attrs: { timestamp: activity.createTime } },
                    [
                      _vm._v(
                        "\n          " + _vm._s(activity.logName) + "\n        "
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }