export default {
  route: {
    dashboard: 'Dashboard',
    identity: 'User Center',
    maintain: 'Maintain',
    messageMgr: 'Message',
    cms: 'Cms',
    menu: 'Menu',
    dept: 'Department',
    user: 'Account',
    role: 'Role',
    task: 'Cron',
    taskLog: 'Cron Log',
    dict: 'Dict',
    loginLog: 'Login Log',
    opsLog: 'Bussiness Log',
    nlogLog: 'Nlog Log',
    cfg: 'Config',
    health: 'health',

    banner: 'Banner',
    article: 'Article',
    editArticle: 'Edit Article',
    file: 'File',
    contacts: 'Contacts',
    channel: 'Channel',
    druid: 'Monitor',
    swagger: 'Docs',
    msg: 'History',
    msgTpl: 'Template',
    msgSender: 'Sender',
    area: 'Area',
    qua: 'QuaManage',
    tranqua: 'TranQuaManage',
    shop: 'Shop',
    shopApply: 'ShopApply',
    shopInfo: 'ShopInfo',
    shopCase: 'ShopCase',
    team: 'Team',
    trans: 'Trans',
    verify: 'Verify',
    consult: 'Consult',
    qinquiry: 'Qinquiry',
    money: 'Money',
    niche: 'Niche',
    coin: 'Coin',
    membership: 'Vip',
    finance: 'Finance',
    shopCoin: 'ShopCoin',
    shopVip: 'ShopVip',
    myNiche: 'MyNiche',
    nicheCenter: 'NicheCenter',
    shopSupplierCoop: 'ShopSupplierCoop',
    cooperatePageDetail: 'CooperateDetail',
    goods: 'Goods',
    quartz: 'Quartz',
    advert: 'Advert',
    report: 'Report',
    corpmember: 'CorpMember',
    charges: 'Charges',
    op_balance: 'OpBalance',
    memberAccount: 'MemberAccount'
  },
  navbar: {
    logOut: 'Log Out',
    dashboard: 'Dashboard',
    profile: 'Profile',
    updatePwd: 'Modify Password',
    github: 'Github',
    screenfull: 'Screenfull',
    theme: 'Theme',
    size: 'Global Size'
  },
  login: {
    title: 'ZiXinBan',
    errorAccount: 'Please enter the correct user name',
    errorPassword: 'The password can not be less than 6 digits',
    logIn: 'Log in',
    register: 'Register',
    username: 'Username',
    password: 'Password'
  },
  button: {
    add: 'Add',
    new: 'New',
    edit: 'Edit',
    delete: 'Delete',
    search: 'Search',
    reset: 'Reset',
    submit: 'Submit',
    cancel: 'Cancel',
    clear: 'Clear',
    back: 'Back',
    export: 'Export',
    disable: 'Disable',
    enable: 'Enable',
    show: 'Show',
    check: 'Check',
    remove: 'Remove',
    process: 'Process',
    confirm: 'Confirm',
    processResult: 'ProcessResult',
    release: 'Release',
    offShelf: 'OffShelf',
    manage: 'Manage',
    enabled: 'Enabled',
    disabled: 'Disabled',
    stream: 'Stream',
    copy: 'Copy',
    filter: 'Filter',
    log: 'Log'
  },
  common: {
    mustSelectOne: 'Please select the record to operate!',
    deleteConfirm: 'Are you sure delete the record?',
    tooltip: 'Tooltip',
    warning: 'Warning',
    optionSuccess: 'success',
    uploadError: 'Upload Error',
    isRequired: ' is required',
    week: {
      mon: 'Mon',
      tue: 'Tue',
      wed: 'Wed',
      thu: 'Thu',
      fri: 'Fri',
      sat: 'Sat',
      sun: 'Sun'
    }
  },

  dashboard: {
    newUser: 'New Users',
    message: 'Message',
    income: 'Income',
    onlineUser: 'Online User',
    document: 'Document',
    date: 'Date',
    name: 'Name',
    addr: 'Address',
    // '邮件营销','联盟广告','视频广告','直接访问','搜索引擎'
    email: 'Email',
    ad: 'Ad',
    vedio: 'Vedio',
    direct: 'Direct',
    searchEngine: 'Search engine',
    userFrom: 'User from'
  },
  config: {
    name: 'Config Name',
    value: 'Config Value',
    descript: 'Descript',
    add: 'Add Config',
    edit: 'Edit Config',
    nameInput: 'Input config name',
    valueInput: 'Input config value',
    lengthValidation: ' must be between 2 and 20 characters'
  }
}
