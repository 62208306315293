<template>
  <span>
    <el-button
      v-bind="customizedAttrs"
      v-on="$listeners"
    >
      <slot />
    </el-button>
    <el-divider v-if="divider" direction="vertical" />
  </span>
</template>

<script>
export default {
  name: 'ZxbButton',
  inheritAttrs: false,
  props: {
    size: {
      type: String,
      default: 'mini'
    },
    type: {
      type: String,
      default: 'text'
    },
    // 是否显示分割线
    divider: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    customizedAttrs() {
      return {
        size: this.size,
        type: this.type,
        // 支持传过来的size覆盖默认的size
        ...this.$attrs
      }
    }
  }
}
</script>
<style scoped>
span{
  float: left;
  display: flex;
  align-items:center;
  justify-content:center;
}
</style>
