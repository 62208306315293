import request from '@/utils/request'

export function preConnect() {
  var shop = true
  return request({
    url: `/im/pre/connect/${shop}`,
    method: 'POST'
  })
}

export function shopGetContacts() {
  var shop = true
  return request({
    url: `/im/chan/${shop}`,
    method: 'GET'
  })
}

/**
 * 服务商创建聊天
 * @param {*} orderId
 * @returns
 */
export function createChat(orderId) {
  return request({
    url: `/im/shop/chan/${orderId}`,
    method: 'POST'
  })
}

export function pullMessage(params) {
  return request({
    url: `/im/pull`,
    method: 'GET',
    params
  })
}

export function sendMessage(chanId, data) {
  return request({
    url: `/im/send/${chanId}`,
    method: 'POST',
    data
  })
}

export function readMessage(chanId) {
  return request({
    url: `/im/read/${chanId}`,
    method: 'POST'
  })
}

/**
 * 获取未读消息数量
 * @returns
 */
export function getUnreadCount() {
  const shop = true
  return request({
    url: `/im/unread/count/${shop}`,
    method: 'POST'
  })
}

// export function userGetContacts() {
//   return request({
//     url: '/im/chan/false',
//     method: 'GET'
//   })
// }
