var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-carousel",
    {
      attrs: {
        interval: 5000,
        arrow: "hover",
        "indicator-position": "none",
        loop: true
      }
    },
    _vm._l(_vm.imgList, function(item, index) {
      return _c(
        "el-carousel-item",
        {
          key: index,
          nativeOn: {
            click: function($event) {
              return _vm.linkTo(item.redirectUrl)
            }
          }
        },
        [
          _c("img", {
            staticClass: "img",
            attrs: { src: item.imagePath, alt: "" }
          })
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }