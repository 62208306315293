export default {
  quaType: {
    finance_taxation: 1, // 工商财税
    intellectual_property: 2, // 商标知产
    system_authentication: 3, // 认证/许可
    personnel_qualification: 4, // 人员资格
    credit_rating: 5, // 企业资质
    custom_qualification: 10 // 自定义
  },
  // 订单类型
  order_type: {
    pay_notice_niche: 'pay_notice_niche', // 购买商机
    pay_notice_coin: 'pay_notice_coin', // 购买资信币
    pay_notice_member: 'pay_notice_member', // 购买会员
    pay_notice_cooperate: 'pay_notice_cooperate', // 撮合业务
    pay_notice_niche_company: 'pay_notice_niche_company', // 精线索
    pay_notice_shop_declare: 'pay_notice_shop_declare', // 渠道资质办理
    pay_notice_channel: 'pay_notice_channel', // 业务合作
    pay_notice_credit_apply: 'pay_notice_credit_apply'// 业务合作
  },

  // 支付方式 1：支付宝 2：微信 3:银联 4：资信币 5:微信小程序 6:会员抵扣 8:会员买断商机 9:支付宝h5支付 10:会员商机临时买断
  pay_type: {
    alipay: 1, // 支付宝
    wechat: 2, // 微信
    UnionPay_B2B: 3, // 银联公对公
    coin: 4, // 资信币
    miniProgram: 5, // 小程序
    vip: 6, // 会员抵扣
    UnionPay_B2C: 7, // 银联公对私
    buyout: 8, temporaryBuyOut: 10, alipayH5: 9
  },

  sms_type: { login: 1, register: 2, forget: 3, consult: 4, bank: 5, demand: 6, quota: 7, bindphone: 9, microRegister: 14, setPwd: 40, fourCode: 60 },

  // 入驻状态
  settledStatus: { init: 0, pending: 1, approved: 2, reject: 3, cancellation: 4 },
  realNameStatus: { init: 0, pending: 1, approved: 2, reject: 3 },
  scopeAuthStatus: { init: 0, pending: 1, approved: 2, reject: 3 },

  // 典型案例及团队成员审核状态 0：审核中 1：通过 2：未通过
  case_status: { pending: 0, approved: 1, reject: 2 },
  // 出售求购状态 0:待审核 1：已发布 2：审核失败 4：已下架
  sell_buy_status: { pending: 0, approved: 1, reject: 2, off: 4 },
  // 咨询回复状态 1:未处理 2：已回复
  consult_status: { pending: 1, approved: 2, reject: 3 },
  verify_type: { case: 0, team: 1, trans: 2, qinquiry: 3, server: 4 },
  // 会员类型 0：青铜 1：白银 2：黄金 3：钻石
  vipType: { BC: 0, SC: 1, GC: 2, DC: 3 },
  // 会员状态 非会员：0， 正常会员：1， 过期会员：2
  vipStatus: { none: 0, normal: 1, expire: 2 },
  // 热点地区  服务办理：1，服务商：2，商机：3，求购：4，出售：5
  hot_area: { server: 1, supplier: 2, niche: 3, buying: 4, oversell: 5 },
  // 广告 投放初始化：0， 投放中：1，待投放：2，已下架：3
  advert_status: { init: 0, put: 1, wait: 2, down: 3 },
  // 热门地区  1：服务项目  2：服务商  3：商机中心 6:精线索
  hot_area_type: { service: 1, supplier: 2, niche: 3, company: 6 },

  verify_list_status: { all: -1, wait: 0, pass: 1, fail: 2 },
  team_status: { pending: 0, approved: 1, reject: 2 },
  server_status: { pending: 0, approved: 1, reject: 2, pass: 3, off: 4 },
  trans_status: { pending: 0, approved: 1, reject: 2, off: 4 },
  qinquiry_status: { pending: 0, approved: 1, reject: 2, off: 4 },
  vip_unit: { week: 0, month: 1, quarter: 3, year: 12 },
  verify_status: { approved: 1, reject: 2 },

  // 账户状态  未开通：0， 审核中：1，审核失败：2，使用中：3， 冻结中：4
  account_status: { init: 0, pending: 1, reject: 2, enabled: 3, disabled: 4 },

  // 结算账户开通状态 未开户：0， 平台审核中：1， 平台审核失败：2， 供应商审核中：3， 供应商审核失败：4， 启用/审核成功：10， 禁用/审核成功：11
  adapay_status: { init: 0, pending: 1, reject: 2, adapayPending: 3, adapayReject: 4, enabled: 10, disabled: 11 },

  // 订单费用类型 支付：0， 提现：1， 结算：2， 退款：3
  cost_type: { pay: 0, withdraw: 1, settle: 2, refund: 3 },

  // 订单可用优惠
  coupon_type: { vip: 1, coin: 6 },
  // 优惠券状态 0：正常 1：冻结 2：已使用 3：过期
  coupon_status: { normal: 0, disabled: 1, hasUse: 2, expire: 3 },

  // 商机状态 11:已查看 0:暂停询价 1:已发布 2:延迟发布 3:买断 4:临时买断
  niche_status: { look: 11, draft: 0, published: 1, delay: 2, buyout: 3, temporaryBuyout: 4 },
  // 撮合业务 -1:全部 0:待处理 1:洽谈中 2:待付款 3:已完成 4:已退回 5:已失败
  cooperate_status: { all: -1, init: 0, negotiate: 1, payment: 2, success: 3, returned: 4, fail: 5 },

  // 订单状态 未开始：0，  待付款：1， 待接单：2，已支付等待支付结果：3， 交易/支付结果
  //  待出证/服务中：5，待出证/待相关单位审批：6,
  // 待买家核对确认/已出证：7， 待交付：8，待验收：9， 待评价：10，
  //  交易成功：11， 退款中：12， 退款成功：13， 退款失败：14
  order_status: {
    init: 0, payment: 1, order: 2, pay_pending: 3,
    pay_fail: 4, certificate: 5, check: 6, confirm: 7, deliver: 8,
    receive: 9, evaluatet: 10, success: 11, refunding: 12,
    refund_success: 13, refund_Fail: 14, closed: -100
  },

  // 订单类型 会员：1  提现：2  资质买卖：3 余额充值：4  商机查看：5  资信币：6  撮合交易：7
  order_types: { vip: 1, cash_out: 2, pay_notice_server: 3, balance: 4, pay_notice_niche: 5, pay_notice_coin: 6, pay_notice_cooperate: 7, pay_notice_niche_company: 8, pay_notice_declare: 9, pay_notice_channel: 10, pay_notice_shop_declare: 11, pay_notice_credit_apply: 13 },

  meta_type: { text: 0, image: 1, audio: 2, video: 3, file: 4, event: 5 },

  shop_declare_status: { closed: -100, init: 0, wait_pay: 1, wait_verify: 2, verify_fail: 3, verify_success: 4, wait_deliver: 5, wait_push_ems: 6, wait_receipt: 7, success: 11 },
  shop_declare_step: { closed: -100, one: 10, two: 20, three: 30, waitPay: 35, four: 40, five: 50, six: 60, waitPersonPay: 65, seven: 70, eight: 80, nine: 90, ten: 100 },

  // 活动状态
  activity_status: { enable: 0, disable: 1 },

  // init未入驻/未提交 Legal(待认证)=1 WaitPay(待支付)=0 Pending(审核中)=2 Approved(通过)=3 Rejected(未通过)=4 Refund(已退款)=5
  channel_apply_status: { init: -1, legal: 1, waitPay: 0, pendding: 2, approved: 3, reject: 4, refund: 5 },
  // Init(未入驻)=0 Pending(待审核)=1 Approved(通过)=2 Reject(未通过)=3 Cancellation(已注销)=4
  channel_apply_list_status: { init: 0, pendding: 1, approved: 2, reject: 3, cancellation: 4 },
  // 实名认证状态
  auth_status: { none: 0, pending: 1, approved: 2, reject: 3 },
  auth_type: { none: 0, person: 1, company: 2 },

  // 申报类型 初审0 年审1 置换2 升级3
  declare_type: { first: 0, year: 1, change: 2, upgrade: 3 },
  declare_status: { closed: -100, init: 0, wait_verify: 1, wait_put_organ: 2,
    wait_put_card: 3, verify_fail: 4, wait_check: 5, wait_push_ems: 6, wait_receive: 7, success: 11 },

  // 代客下单申报状态
  /**
   * Init(申请中)=0 WaitVerify(审核中)=1 WaitPutOrgan(审核中)=2 WaitPutCard(审核中)=3
   * VerifyFail(审核失败)=4 WaitCheck(待验收)=5 WaitPushEMS(待交付)=6 WaitReceive(待收件)=7 Success(已完成)=11 Closed(申报终止)=-100
   */
  declare_order_status: { init: 0, waitVerify: 1, waitPutOrgan: 2, waitPutCard: 3, verifyFail: 4,
    waitCheck: 5, waitPushEMS: 6, waitReceive: 7, success: 11, closed: -100 },
  declare_step: { init: 0, create_order: 1, wait_pay: 2, auth: 3, confirm_applicant: 4, confirm_pay: 5, check: 6, put_organ: 7, organ_check: 8, wait_check: 9, wait_put: 10, wait_receive: 11, success: 12 },
  // WaitVerify(待审核)=0 VerifySuccess(通过)=1 VerifyFail(审核失败)=2
  declare_custom_status: { waitVerify: 0, verifySuccess: 1, verifyFail: 2 },

  // 代客下单申报步骤
  declare_custom_step: {
  // 申报终止
    closed: -100,
    // 申报信息/创建
    init: 0,
    // 信息提交
    submitInfo: 1,
    // 首付低于3成，销售修改价格低于原价审核
    fianceCheck: 2,
    // 合同盖章
    waitStamp: 3,
    // 合同上传
    waitUploadContract: 5,
    // 订单确认
    createOrder: 11,
    // 订单支付
    waitPay: 12,
    // 完善信息 - 实名认证
    auth: 13,
    // 完善信息 - 申请人确认
    confirmApplicant: 14,
    // 申报材料审核 - 支付确认
    confirmPay: 15,
    // 申报材料审核
    check: 16,
    // 提交受理单位
    putOrgan: 17,
    // 受理单位审核
    organCheck: 18,
    // 获取证书 - 待验收
    waitCheck: 19,
    // 领证 - 待付尾款
    waitLastPay: 20,
    // 领证 - 待交付
    waitPut: 21,
    // 领证 - 待收件
    waitReceive: 22,
    // 领证 - 完成
    success: 23
  },

  /**
   * Init(申请中)=0 WaitVerify(审核中)=1 VerifyFail(审核失败)=2 VerifySuccess(审核通过)=3 Closed(申请终止)=-100
   */
  declare_credit_company_status: {
    init: 0, waitVerify: 1, verifyFail: 2, verifySuccess: 3, closed: -100
  },

  credit_apply_status: {
    hasExpire: -10, pending: 0, waitVerify: 1, approved: 2, rejected: 3
  },

  UnionBank: [
    { id: '01020000', label: '工商银行', icon: 'gongshang' },
    { id: '01030000', label: '农业银行', icon: 'nongye' },
    { id: '01040000', label: '中国银行', icon: 'zhonghang' },
    { id: '01050000', label: '建设银行', icon: 'construction' },
    { id: '03010000', label: '交通银行', icon: 'communication' },
    { id: '03020000', label: '中信银行', icon: 'zhongxin' },
    { id: '03030000', label: '光大银行', icon: 'guangda' },
    { id: '03040000', label: '华夏银行', icon: 'huaxia' },
    { id: '03050000', label: '民生银行', icon: 'minsheng' },
    { id: '03060000', label: '广发银行', icon: 'guangfa' },
    { id: '03080000', label: '招商银行', icon: 'merchants' },
    { id: '03090000', label: '兴业银行', icon: 'xingye' },
    { id: '03100000', label: '浦发银行', icon: 'pufa' },
    { id: '03134402', label: '平安银行', icon: 'pingan' },
    { id: '04030000', label: '邮政银行', icon: 'youzheng' },
    { id: '03130011', label: '北京银行', icon: 'beijing' },
    { id: '03130031', label: '上海银行', icon: 'shanghai' },
    { id: '03133201', label: '南京银行', icon: 'nanjing' },
    { id: '03133301', label: '杭州银行', icon: 'hangzhou' },
    { id: '03160000', label: '浙商银行', icon: 'zheshang' },
    { id: '04020031', label: '上海农商', icon: 'shangnong' },
    { id: '04478210', label: '兰州银行', icon: 'lanzhou' },
    { id: '05818200', label: '甘肃银行', icon: 'gansu' },
    { id: '05847000', label: '贵州银行', icon: 'guizhou' },
    { id: '03180000', label: '渤海银行', icon: 'bohai' },
    { id: '04020011', label: '北京农商', icon: 'beinong' },
    { id: '88002538', label: '三湘银行', icon: 'sanxiang' }
  ],

  UnionPay_B2B: [
    { id: '01020000', label: '工商银行', icon: 'gongshang' },
    { id: '01030000', label: '农业银行', icon: 'nongye' },
    { id: '01040000', label: '中国银行', icon: 'zhonghang' },
    { id: '01050000', label: '建设银行', icon: 'construction' },
    { id: '03010000', label: '交通银行', icon: 'communication' },
    { id: '03020000', label: '中信银行', icon: 'zhongxin' },
    { id: '03030000', label: '光大银行', icon: 'guangda' },
    { id: '03040000', label: '华夏银行', icon: 'huaxia' },
    { id: '03050000', label: '民生银行', icon: 'minsheng' },
    { id: '03060000', label: '广发银行', icon: 'guangfa' },
    { id: '03080000', label: '招商银行', icon: 'merchants' },
    { id: '03090000', label: '兴业银行', icon: 'xingye' },
    { id: '03100000', label: '浦发银行', icon: 'pufa' },
    { id: '03134402', label: '平安银行', icon: 'pingan' },
    { id: '04030000', label: '邮政银行', icon: 'youzheng' },
    { id: '03130011', label: '北京银行', icon: 'beijing' },
    { id: '03130031', label: '上海银行', icon: 'shanghai' },
    { id: '03133201', label: '南京银行', icon: 'nanjing' },
    { id: '03133301', label: '杭州银行', icon: 'hangzhou' },
    { id: '03160000', label: '浙商银行', icon: 'zheshang' },
    { id: '04020031', label: '上海农商', icon: 'shangnong' },
    { id: '04478210', label: '兰州银行', icon: 'lanzhou' },
    { id: '05818200', label: '甘肃银行', icon: 'gansu' },
    { id: '05847000', label: '贵州银行', icon: 'guizhou' }
  ],
  UnionPay_B2C: [
    { id: '01020000', label: '工商银行', icon: 'gongshang' },
    { id: '01030000', label: '农业银行', icon: 'nongye' },
    { id: '01040000', label: '中国银行', icon: 'zhonghang' },
    { id: '01050000', label: '建设银行', icon: 'construction' },
    { id: '03010000', label: '交通银行', icon: 'communication' },
    { id: '03020000', label: '中信银行', icon: 'zhongxin' },
    { id: '03030000', label: '光大银行', icon: 'guangda' },
    { id: '03040000', label: '华夏银行', icon: 'huaxia' },
    { id: '03050000', label: '民生银行', icon: 'minsheng' },
    { id: '03060000', label: '广发银行', icon: 'guangfa' },
    { id: '03080000', label: '招商银行', icon: 'merchants' },
    { id: '03090000', label: '兴业银行', icon: 'xingye' },
    { id: '03100000', label: '浦发银行', icon: 'pufa' },
    { id: '03134402', label: '平安银行', icon: 'pingan' },
    { id: '04030000', label: '邮政银行', icon: 'youzheng' },
    { id: '03130011', label: '北京银行', icon: 'beijing' },
    { id: '03130031', label: '上海银行', icon: 'shanghai' },
    { id: '03133201', label: '南京银行', icon: 'nanjing' },
    { id: '03133301', label: '杭州银行', icon: 'hangzhou' },
    { id: '03160000', label: '浙商银行', icon: 'zheshang' },
    { id: '03180000', label: '渤海银行', icon: 'bohai' },
    { id: '04020011', label: '北京农商', icon: 'beinong' },
    { id: '04020031', label: '上海农商', icon: 'shangnong' },
    { id: '05847000', label: '贵州银行', icon: 'guizhou' },
    { id: '88002538', label: '三湘银行', icon: 'sanxiang' }
  ]
}
