import request from '@/utils/request'

/**
 * 获取我的商机
 * @param {Object} params
 * @returns
 */
export function getMyNiche(params) {
  return request({
    url: '/shop/niche',
    method: 'GET',
    params
  })
}

/**
 * 获取商机列表
 * @param {Object} params
 * @returns
 */
export function getNicheCenterList(params) {
  return request({
    url: '/niche/page',
    method: 'GET',
    params
  })
}

/**
 * 获取商机支付方式
 * @param {Number} id
 */
export function getPayType(id) {
  return request({
    url: '/shop/pay/' + id,
    method: 'GET'
  })
}

/**
 * 获取商机详情 管理端
 * @param {Number} id
 * @returns
 */
export function getNicheDetails(id) {
  return request({
    url: '/niche/' + id,
    method: 'GET'
  })
}

/**
 * 管理端获取列表
 * @param {Object} params
 * @returns
 */
export function getNicheList(params) {
  return request({
    url: '/niche/list',
    method: 'GET',
    params
  })
}

/**
 * 上架商机
 * @param {Number} id
 * @returns
 */
export function releaseNiche(id) {
  return request({
    url: '/niche/up/' + id,
    method: 'PUT'
  })
}

/**
 * 下架商机
 * @param {Number} id
 * @returns
 */
export function soldoutNiche(data) {
  return request({
    url: '/niche/down/' + data.id,
    method: 'PUT',
    data
  })
}

/**
 * 新增及修改商机
 * @param {Object} data
 * @returns
 */
export function saveNiche(data) {
  let methodName = 'POST'
  let url = '/niche/add'
  if (data.id > 0) {
    methodName = 'PUT'
    url = '/niche/' + data.id
  }
  return request({
    url,
    method: methodName,
    data
  })
}

/**
 * 删除商机
 * @param {Number} id
 * @returns
 */
export function deleteNiche(id) {
  return request({
    url: '/niche/' + id,
    method: 'DELETE'
  })
}

/**
 * 商户端获取商机信息
 * @param {Number} id
 * @returns
 */
export function getNicheDetailMerchant(id) {
  return request({
    url: '/shop/niche/detail/' + id,
    method: 'GET'
  })
}

/**
 * 精线索列表
 * @param {Object} params
 * @returns
 */
export const getCompanyList = (params) => {
  return request({
    url: '/niche/company',
    method: 'GET',
    params
  })
}

/**
 * 获取精线索地区信息
 * @returns
 */
export const getCompanyArea = () => {
  return request({
    url: '/niche/company/area',
    method: 'GET'
  })
}

/**
 * 导出拓客名单
 * @returns
 */
export const exportCompany = (data) => {
  return request({
    url: '/niche/company/export',
    method: 'PUT',
    data
  })
}

/**
 * 导入拓客名单
 * @param {*} data
 * @returns
 */
export const importCompany = (data) => {
  return request({
    url: '/niche/company/import',
    method: 'POST',
    data
  })
}

/**
 * 导入记录 6导入记录 7发布记录
 * @param {Object} params
 * @returns
 */
export const importCompanyLogs = (params) => {
  return request({
    url: '/niche/company/import/logs',
    method: 'GET',
    params
  })
}

/**
 * 获取撮合业务列表
 * @param {*} params
 * @returns
 */
export const getCooperatePageData = (params) => {
  return request({
    url: '/niche/cooperate/page',
    method: 'GET',
    params
  })
}

/**
 * 获取撮合业务完成率
 * @returns
 */
export const getCooperateReport = (params) => {
  return request({
    url: '/niche/cooperate/report/page',
    method: 'GET',
    params
  })
}

/**
 * 获取撮合详情
 * @param {*} id
 * @returns
 */
export const getCooperateDetail = (id) => {
  return request({
    url: '/niche/cooperate/detail/' + id,
    method: 'GET'
  })
}

/**
 * 复制一个撮合订单
 * @param {*} id
 * @returns
 */
export const copyCooperateItem = (id) => {
  return request({
    url: '/niche/cooperate/copy/' + id,
    method: 'PUT'
  })
}

/**
 * 保存这个撮合订单
 * @param {*} data
 * @returns
 */
export const saveCooperateItem = (data) => {
  return request({
    url: '/niche/cooperate/save',
    method: 'POST',
    data
  })
}

/**
 * 更改这个撮合订单状态
 * @param {*} data
 * @returns
 */
export const changeCooperateItem = (data) => {
  return request({
    url: '/niche/cooperate/change',
    method: 'PUT',
    data
  })
}

/**
 * 获取撮合订单页所需的商户列表
 * @param {*} params
 * @returns
 */
export const getCooperateShop = (params) => {
  return request({
    url: '/niche/cooperate/shop',
    method: 'GET',
    params
  })
}

/**
 * 获取简单服务商信息
 * @param {*} name
 * @returns
 */
export const getCooperate = (name) => {
  return request({
    url: '/shop/search/' + name,
    method: 'GET'
  })
}

/**
 * 获取商机购买记录
 * @param {*} id
 * @returns
 */
export const getNicheBuyLog = (id) => {
  return request({
    url: '/shop/niche/buy/' + id,
    method: 'GET'
  })
}

/**
 * 我的精线索页下载
 * @param {*} id
 */
export const downloadCompany = (id) => {
  return request({
    url: '/niche/company/down/' + id,
    method: 'GET'
  })
}

/**
 * 精线索下载记录
 * @param {*} id
 * @returns
 */
export const downloadCompanyLog = (params) => {
  return request({
    url: '/niche/company/down/log',
    method: 'GET',
    params
  })
}
