<template>
  <ul :class="simpleWrapClasses" :style="styles">
    <li title="上一页" :class="prevClasses" @click.stop="prev">
      <i class="el-icon-arrow-left icon" />
    </li>
    <div :class="simplePagerClasses" :title="currentPage + '/' + allPages">
      <input type="text" :value="currentPage" autocomplete="off" spellcheck="false" @keydown="keyDown" @blur="blur" @keyup="keyUp" @change="keyUp">
      <span>/</span>
      {{ allPages }}
    </div>
    <li title="下一页" :class="nextClasses" @click.stop="next">
      <i class="el-icon-arrow-right icon" />
    </li>
  </ul>
</template>

<script>

const prefixCls = 'page'
function oneOf(value, validList) {
  for (let i = 0; i < validList.length; i++) {
    if (value === validList[i]) {
      return true
    }
  }
  return false
}
export default {
  name: 'Page',
  props: {
    current: {
      type: Number,
      default: 1
    },
    total: {
      type: Number,
      default: 0
    },
    pageSize: {
      type: Number,
      default: 10
    },
    pageSizeOpts: {
      type: Array,
      default() {
        return [10, 20, 30, 40]
      }
    },
    placement: {
      validator(value) {
        return oneOf(value, ['top', 'bottom'])
      },
      default: 'bottom'
    },
    size: {
      validator(value) {
        return oneOf(value, ['small'])
      }
    },
    simple: {
      type: Boolean,
      default: false
    },
    showTotal: {
      type: Boolean,
      default: false
    },
    showElevator: {
      type: Boolean,
      default: false
    },
    showSizer: {
      type: Boolean,
      default: false
    },
    className: {
      type: String
    },
    styles: {
      type: Object
    },
    prevText: {
      type: String,
      default: ''
    },
    nextText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      prefixCls: prefixCls,
      currentPage: this.current,
      currentPageSize: this.pageSize
    }
  },
  computed: {
    isSmall() {
      return !!this.size
    },
    allPages() {
      const allPage = Math.ceil(this.total / this.currentPageSize)
      return (allPage === 0) ? 1 : allPage
    },
    simpleWrapClasses() {
      return [
        `${prefixCls}`,
        `${prefixCls}-simple`,
        {
          [`${this.className}`]: !!this.className
        }
      ]
    },
    simplePagerClasses() {
      return `${prefixCls}-simple-pager`
    },
    wrapClasses() {
      return [
        `${prefixCls}`,
        {
          [`${this.className}`]: !!this.className,
          'mini': !!this.size
        }
      ]
    },
    prevClasses() {
      return [
        `${prefixCls}-prev`,
        {
          [`${prefixCls}-disabled`]: this.currentPage === 1,
          [`${prefixCls}-custom-text`]: this.prevText !== ''
        }
      ]
    },
    nextClasses() {
      return [
        `${prefixCls}-next`,
        {
          [`${prefixCls}-disabled`]: this.currentPage === this.allPages,
          [`${prefixCls}-custom-text`]: this.nextText !== ''
        }
      ]
    },
    firstPageClasses() {
      return [
        `${prefixCls}-item`,
        {
          [`${prefixCls}-item-active`]: this.currentPage === 1
        }
      ]
    },
    lastPageClasses() {
      return [
        `${prefixCls}-item`,
        {
          [`${prefixCls}-item-active`]: this.currentPage === this.allPages
        }
      ]
    }
  },
  watch: {
    total(val) {
      const maxPage = Math.ceil(val / this.currentPageSize)
      if (maxPage < this.currentPage) {
        this.currentPage = (maxPage === 0 ? 1 : maxPage)
      }
    },
    current(val) {
      this.currentPage = val
    },
    pageSize(val) {
      this.currentPageSize = val
    }
  },
  methods: {
    changePage(page) {
      if (+this.currentPage !== +page) {
        this.currentPage = page
        this.$emit('update:current', page)
        this.$emit('on-change', page)
      }
    },
    prev() {
      const current = this.currentPage
      if (current <= 1) {
        return false
      }
      this.changePage(current - 1)
    },
    next() {
      const current = this.currentPage
      if (current >= this.allPages) {
        return false
      }
      this.changePage(current + 1)
    },
    fastPrev() {
      const page = this.currentPage - 5
      if (page > 0) {
        this.changePage(page)
      } else {
        this.changePage(1)
      }
    },
    fastNext() {
      const page = this.currentPage + 5
      if (page > this.allPages) {
        this.changePage(this.allPages)
      } else {
        this.changePage(page)
      }
    },
    onSize(pageSize) {
      this.currentPageSize = pageSize
      this.$emit('on-page-size-change', pageSize)
      this.changePage(1)
    },
    onPage(page) {
      this.changePage(page)
    },
    keyDown(e) {
      const key = e.keyCode
      const condition = (key >= 48 && key <= 57) || (key >= 96 && key <= 105) || key === 8 || key === 37 || key === 39
      if (!condition) {
        e.preventDefault()
      }
    },
    blur(e) {
      const val = parseInt(e.target.value)
      let page = 1
      if (val > this.allPages) {
        page = this.allPages
      } else if (val <= 0 || !val) {
        page = 1
      } else {
        page = val
      }
      e.target.value = page
      this.changePage(page)
    },
    keyUp(e) {
      const key = e.keyCode
      const val = parseInt(e.target.value)
      if (key === 38) {
        this.prev()
      } else if (key === 40) {
        this.next()
      } else if (key === 13) {
        let page = 1
        if (val > this.allPages) {
          page = this.allPages
        } else if (val <= 0 || !val) {
          page = 1
        } else {
          page = val
        }
        e.target.value = page
        this.changePage(page)
      }
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.page-simple{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.page-prev,
.page-next {
  display: inline-block;
  float: left;
  width: 28px;
  height: 28px;
  line-height: 26px;
  list-style: none;
  text-align: center;
  cursor: pointer;
  color: #444444;
  font-family: Arial;
  background-color: #F0F2F5;
  border-radius: 3px;
  border: 1px solid #D7DBE0;

  .icon{
    font-size: 12px;
  }
}

.page-simple-pager {
  float: left;
  margin-right: 8px;
  line-height: 28px;
  font-size: 12px;
  color: #444444;

  input {
    width: 30px;
    height: 28px;
    margin: 0 8px;
    padding: 5px 8px;
    text-align: center;
    background-color: #fff;
    outline: 0;
    border: 1px solid #d7dde4;
    box-sizing: border-box;
    border-radius: 4px;
    transition: border-color .2s ease-in-out;
  }
  span {
    padding: 0 8px 0 2px;
}
}

.page-disabled {
  border: 0;
  cursor: not-allowed;
}
</style>
