var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("p", { staticClass: "line" }),
    _vm._v(" "),
    _vm.data.contractNo && _vm.data.contractNo !== "-"
      ? _c("p", { staticClass: "contractNo" }, [
          _vm._v("\n    合同编号："),
          _c("ins", [_vm._v(_vm._s(_vm.data.contractNo))])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticStyle: { "line-height": "1.3" } },
      [
        _c("h3", [_vm._v("第一章 协议双方")]),
        _c("h4", [_vm._v("甲方：")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      公司名称：" +
              _vm._s(_vm.bankData.name) +
              "         （以下简称“甲方”）\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v("\n      联系人： "),
          _c("ins", [_vm._v("    " + _vm._s(_vm.data.jContact) + "    ")]),
          _vm._v("                 联系电话： "),
          _c("ins", [_vm._v("   " + _vm._s(_vm.data.jPhone) + "   ")])
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v("\n      地址：" + _vm._s(_vm.bankData.address) + "\n    ")
        ]),
        _vm._v(" "),
        _c("h4", [_vm._v("乙方：")]),
        _vm._v(" "),
        _c("p", [
          _vm._v("\n      公司名称："),
          _c("ins", [_vm._v("   " + _vm._s(_vm.data.shopName) + "    ")]),
          _vm._v("      （以下简称“乙方”）\n    ")
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v("\n      联系人："),
          _c("ins", [_vm._v("   " + _vm._s(_vm.data.mainContact) + "   ")]),
          _vm._v("                       联系电话："),
          _c("ins", [
            _vm._v("   " + _vm._s(_vm.data.mainContactMobileNo) + "    ")
          ])
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v("\n      地址："),
          _c("ins", [_vm._v("   " + _vm._s(_vm.data.contactAddress) + "   ")])
        ]),
        _vm._v(" "),
        _c("h3", [_vm._v("第二章 定义与解释")]),
        _vm._v(
          "\n    除非本协议中另有定义，下列词语在本协议项下均应具有以下特定涵义：\n    "
        ),
        _c("br"),
        _vm._v(
          " 1、资信办平台：是指由甲方开发并运营的资质认证产业数字化平台资信办www.zixinban.com。\n    "
        ),
        _c("br"),
        _vm._v(
          " 2、资质认证服务机构：是指经评定符合对应等级条件，并满足资信办平台服务开通条件，自主选择签署本协议并根据资信办平台资质认证服务机构开通流程所展示的收费标准，支付相应费用后获取的特殊会员身份资格（实际名称以开通流程及前台页面展示为准），为企业或个人提供资质认证咨询服务的合作商。\n    "
        ),
        _c("br"),
        _vm._v(
          " 3、授权业务：是指资信办平台获得发证机构授权开展的评审、评估、咨询等相关服务。发证机构、证书名称及价格以资信办商家后台（https://manage.zixinban.com/）的“在线申报”功能页面下单显示为准。甲方有权随时更改授权业务的类目信息及价格等。\n    "
        ),
        _c("br"),
        _vm._v(
          " 4、撮合业务：是指在资信办平台入驻的商家之间的业务合作。业务合作涉及的发证机构、证书名称及价格以资信办商家后台（https://manage.zixinban.com/）的“在线申报”功能页面下单显示为准。甲方有权随时更改撮合业务的类目信息及价格等。\n    "
        ),
        _c("br"),
        _vm._v(
          " 5、客户：指在协议合作期内，因通过乙方推广而知悉资信办平台的授权业务和撮合业务，且通过乙方办理资信办平台授权业务和撮合业务的企业或个人。\n    "
        ),
        _c("br"),
        _vm._v(" "),
        _c("h3", [_vm._v("第三章 总则")]),
        _vm._v(" "),
        _c("b", [_vm._v("第一条：")]),
        _vm._v(
          "甲方和乙方本着自愿、平等、公平、诚实、信用的原则，经友好协商，根据中华人民共和国有关法律、法规的规定签订本协议，并由双方共同遵守。双方应本着善意解释和履行本协议，特别应当尽其全力，彼此应表明其谨慎、忠实和合作。双方同意，在协议范围内，双方的关系确定为合作关系。上述条款是一项实质责任。\n    "
        ),
        _c("br"),
        _vm._v(" "),
        _c("b", [_vm._v("第二条：")]),
        _vm._v(
          "鉴于乙方接受甲方合作理念，具备甲方所需推广的业务基础，符合甲方资信办平台资质认证服务机构评定标准且诚意向甲方提出签约申请，并在资信办平台支付会员费至少"
        ),
        _c("ins", [_vm._v(_vm._s(_vm.data.finalPrice))]),
        _vm._v("元，故甲方同意乙方成为资信办平台的 "),
        _c("ins", [_vm._v(_vm._s(_vm.data.name))]),
        _vm._v(" 资质认证服务机构，并且享受 "),
        _c("ins", [_vm._v(_vm._s(_vm.data.name))]),
        _vm._v(
          " 资质认证服务机构的相关权益，会员费只用于购买资质认证服务机构权益，不能提现，不能转让，一经支付不可退款。\n    "
        ),
        _c("br"),
        _vm._v(" "),
        _c("b", [_vm._v("第三条：")]),
        _vm._v(
          "甲方给予乙方提供业务所需的技术、培训、宣传物料、网络推广、咨询服务等支持以便乙方更好的开展市场拓展工作。\n    "
        ),
        _c("br"),
        _vm._v(" "),
        _c("b", [_vm._v("第四条：")]),
        _vm._v(
          "甲方根据资质认证服务机构的相关政策，根据乙方的申请和对乙方经营能力的审核，同意乙方推广资信办平台授权业务和撮合业务，在协议合作期内，乙方为甲方资信办平台的"
        ),
        _c("ins", [_vm._v(_vm._s(_vm.data.name))]),
        _vm._v("资质认证服务机构。\n    "),
        _c("br"),
        _vm._v(" "),
        _c("h3", [_vm._v("第四章\t合作期及合作模式")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("b", [_vm._v("第一条：")]),
        _vm._v("本协议项下的合作期，自 "),
        _c("ins", [_vm._v(_vm._s(_vm.data.cooperate))]),
        _vm._v(" 。\n    "),
        _c("br"),
        _vm._v(" "),
        _c("b", [_vm._v("第二条：")]),
        _vm._v(
          "本协议规定，任一客户通过乙方推广而知悉甲方资信办平台授权业务和撮合业务，并欲办理甲方资信办平台授权业务和撮合业务，乙方均需对其进行辅导，并协助其签署所属项目服务合同。除前述合同约定外，乙方不得以其自身或甲方名义与客户签订任何形式的其他协议，或给予任何相关的口头或书面承诺。\n    "
        ),
        _c("br"),
        _vm._v(" "),
        _c("b", [_vm._v("第三条：资质认证服务机构经营服务帮助")]),
        _vm._v(
          "\n    甲方可以协助乙方洽谈客户、签单；为乙方提供市场培训指导，及相关的业务话术培训；如出现多个乙方销售价格不统一情形的，甲方有权出面协调解决；对市场上出现的同行竞争，甲方有权及时调整市场竞争策略；如出现大客户压价情形，乙方可与甲方协商有关产品价格的具体事宜。\n    "
        ),
        _c("h3", [_vm._v("第五章 甲方权利和义务")]),
        _vm._v(" "),
        _c("br"),
        _c("b", [_vm._v("第一条：")]),
        _vm._v(
          "甲方将颁布资质认证服务机构相关管理政策，乙方应无条件按照政策执行。\n    "
        ),
        _c("br"),
        _c("b", [_vm._v("第二条：")]),
        _vm._v("甲方将协助乙方进行推广方案的制定及推广人员的培训。\n    "),
        _c("br"),
        _c("b", [_vm._v("第三条：")]),
        _vm._v(
          "甲方有权在甲方产品网站或其他相关网站上，公布乙方的名称、联系方式、推广权限说明和客户监督投诉电话等。\n    "
        ),
        _c("br"),
        _c("b", [_vm._v("第四条：")]),
        _vm._v("甲方将辅导和支持乙方开展市场宣传推广活动。\n    "),
        _c("br"),
        _c("b", [_vm._v("第五条：")]),
        _vm._v(
          "甲方将向乙方传授必要的技术知识与推广技巧，并及时向乙方提供最新的公开市场材料等市场支持，以便于乙方开展推广活动。\n    "
        ),
        _c("br"),
        _c("b", [_vm._v("第六条：")]),
        _vm._v(
          "甲方有权对乙方的推广行为及相关经营活动进行监督、检查和管理，以确认乙方是否违反甲方的相关销售策略，若发现有违反行为，甲方有权提出整改要求，制定整改措施。乙方拒绝整改或经整改后仍有违反行为的，甲方有权单方解除本协议，本协议在甲方书面通知到达乙方时解除，甲方有权冻结乙方会员权益，且已收取的会员费不予退还，由乙方承担全部损失。\n    "
        ),
        _c("br"),
        _c("b", [_vm._v("第七条：")]),
        _vm._v(
          "甲方有权要求乙方所有涉及甲方资信办平台授权业务和撮合业务推广的员工必须接受甲方的培训方案，遵守甲方制订的资信办平台相关管理制度。如乙方有任何违反法律法规或本合作协议的任一规定的行为以及其他不诚信行为时，甲方有权单方解除本协议，本协议在甲方书面通知到达乙方时解除，甲方有权冻结乙方资质认证服务机构权益，且已收取的会员费不予退还，由乙方承担全部损失。\n    "
        ),
        _c("br"),
        _c("h3", [_vm._v("第六章 乙方权利和义务")]),
        _vm._v(" "),
        _c("b", [_vm._v("第一条：")]),
        _vm._v(
          "乙方将指定专人负责与本协议项下合作有关的一切工作，指定人员发生变化时，乙方须提前五个工作日书面通知甲方，未按约履行通知义务产生的损失由乙方承担。\n    "
        ),
        _c("br"),
        _c("b", [_vm._v("乙方联系人：")]),
        _vm._v(_vm._s(_vm.data.mainContact) + "                  "),
        _c("b", [_vm._v("e-mail：")]),
        _vm._v(_vm._s(_vm.data.mainContactEmail) + "                         "),
        _c("b", [_vm._v("电话：")]),
        _vm._v(_vm._s(_vm.data.mainContactMobileNo) + "\n    "),
        _c("br"),
        _c("b", [_vm._v("第二条：")]),
        _vm._v(
          "本协议签订后，乙方应在开始推广业务前指派相关人员参加甲方规定的培训，获得【资信办平台授权业务和撮合业务】推广必需的知识和技巧；推广开始后，如甲方有培训安排，甲方应事先通过与乙方协商,由乙方根据培训安排指派相关人员参加。同时乙方应指定专人负责推广流程的协调，保证推广工作的顺利开展。\n    "
        ),
        _c("br"),
        _c("b", [_vm._v("第三条：")]),
        _vm._v(
          "乙方在推广【资信办平台授权业务和撮合业务】时，应按照甲方提供的统一宣传资料，向客户进行解释说明，未经甲方事先书面同意，乙方不得使用或分发非甲方提供的宣传资料。乙方应对客户的主体资格进行审查，并协助甲方或甲方指定的第三方对客户进行身份确认。如果甲方或甲方指定的第三方发现客户无法通过身份确认的，甲方有权拒绝向该客户提供【资信办平台授权业务和撮合业务】。\n    "
        ),
        _c("br"),
        _c("b", [_vm._v("第四条：")]),
        _vm._v(
          "乙方在推广【资信办平台授权业务和撮合业务】时，应严格遵守本协议附件及甲方公布并随时更新的相关规定。\n    "
        ),
        _c("br"),
        _c("b", [_vm._v("第五条：")]),
        _vm._v(
          "乙方应允许甲方派遣人员对乙方经营情况进行实地检查，并允许甲方调取乙方相关原始资料。\n    "
        ),
        _c("br"),
        _c("b", [_vm._v("第六条：")]),
        _vm._v(
          "乙方应采取合理的保护和保密措施，以确保与甲方产品产品有关的任何商业资料不会流失、被盗用或被任何第三者取得。\n    "
        ),
        _c("br"),
        _c("b", [_vm._v("第七条：")]),
        _vm._v(
          "乙方应自行负责解决，在宣传或向客户推介产品时，由于偏离甲方提供的宣传资料的内容而引起的一切纠纷，并承担相应不利后果。\n    "
        ),
        _c("br"),
        _c("b", [_vm._v("第八条：")]),
        _vm._v(
          "客户在使用【资信办平台授权业务和撮合业务】过程中遇到问题并向乙方寻求帮助的，乙方应根据甲方传授的知识或依据甲方提供的相关资料向客户提供帮助。乙方不能解决的问题，乙方应及时通知甲方，由甲方协助解决。\n    "
        ),
        _c("br"),
        _c("b", [_vm._v("第九条：")]),
        _vm._v("经双方协商确定：乙方本次支付会员费为人民币【 "),
        _c("ins", [_vm._v(_vm._s(_vm.data.finalPrice))]),
        _vm._v(" 元】，大写："),
        _c("ins", [_vm._v(_vm._s(_vm.upper(_vm.data.finalPrice, true)))]),
        _vm._v("  ，用于购买 "),
        _c("ins", [_vm._v(_vm._s(_vm.data.name))]),
        _vm._v(
          " 资质认证服务机构会员身份，享受【资信办平台授权业务】最低成交价 "
        ),
        _c("ins", [_vm._v(_vm._s(_vm.data.authDiscount * 10))]),
        _vm._v(" 折优惠、享受【资信办平台撮合业务】最低成交价 "),
        _c("ins", [_vm._v(_vm._s(_vm.data.matchDiscount * 10))]),
        _vm._v(" 折优惠，同时赠送【 "),
        _c("ins", [_vm._v(_vm._s(_vm.data.amount))]),
        _vm._v(" 资信币】，大写： "),
        _c("ins", [_vm._v(_vm._s(_vm.upper(_vm.data.amount, false)))]),
        _vm._v(
          " ，该资信币只能用于在资信办平台办理【资信办平台授权业务和撮合业务】的费用抵扣。乙方应于本协议签订之日起1个工作日内将上述会员费一次性支付至甲方指定账户。会员费一经支付不予退还，乙方对此已经明确知晓且没有任何异议。\n    "
        ),
        _c("b", [
          _c("br"),
          _c("br"),
          _vm._v(" 甲方收款信息：\n      "),
          _c("br"),
          _vm._v(" 公司名称：" + _vm._s(_vm.bankData.name) + "\n      "),
          _c("br"),
          _vm._v(" 纳税人识别号：" + _vm._s(_vm.bankData.no) + "\n      "),
          _c("br"),
          _vm._v(" 地址及电话：" + _vm._s(_vm.bankData.address) + "\n      "),
          _c("br"),
          _vm._v(
            " 开户行及账号： " +
              _vm._s(_vm.bankData.bankName) +
              "  " +
              _vm._s(_vm.bankData.bankNo) +
              "\n    "
          )
        ]),
        _vm._v(" "),
        _c("h3", [_vm._v("第七章\t终止")]),
        _vm._v(" "),
        _c("b", [_vm._v("第一条：")]),
        _vm._v("违约\n    "),
        _c("br"),
        _c("b", [_vm._v("第一款：")]),
        _vm._v("一般性违约\n    "),
        _c("br"),
        _vm._v(
          "如任何一方违反本协议所规定的义务，违约方在收到守约方要求纠正其违约行为的通知之日，应立即停止其违约行为，并在十日内赔偿守约方因此受到的所有损失。如违约方继续进行违约行为或不履行其义务，守约方除就其所有损失而获得违约方赔偿外，亦有权立即书面通知违约方单方面终止本协议，本协议在守约方书面通知到达违约方时终止。\n    "
        ),
        _c("br"),
        _c("b", [_vm._v(" 第二款：违规行为")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          "1.\t违反保密义务，致使与甲方产品有关的任何商业资料流失、被盗用或被第三者取得，或盗取其它乙方相关客户资料；\n    "
        ),
        _c("br"),
        _vm._v(
          "2.通过欺诈手段，故意违反或规避甲方各项行为准则或管理制度，给甲方内部管理、声誉或利益造成严重损害的行为：\n    "
        ),
        _c("br"),
        _vm._v("3.私自篡改订单，私自扣押客户款项；\n    "),
        _c("br"),
        _vm._v(
          "4.在销售过程中由于服务态度、过度承诺、极端销售、工作疏忽等，引起客户严重投诉，或给甲方或客户造成重大影响；\n    "
        ),
        _c("br"),
        _vm._v("5.低价倾销、明折暗扣、滥收费用、扰乱市场；\n    "),
        _c("br"),
        _vm._v("6.造假，或引导、协助客户造假；\n    "),
        _c("br"),
        _vm._v("7.超出甲方宣传资料范围，夸大产品内容或保证效果的行为；\n    "),
        _c("br"),
        _vm._v("8.其他甲方认定为违规的行为。\n\n    "),
        _c("br"),
        _c("b", [_vm._v("第二条：终止")]),
        _vm._v(" "),
        _c("br"),
        _c("b", [_vm._v("第一款：")]),
        _vm._v("本协议于下列任一情形出现时即终止\n    "),
        _c("br"),
        _vm._v("1.合作期限届满而任何一方决定不再续签；\n    "),
        _c("br"),
        _vm._v(
          "2.在合作期限届满前，甲方赠送给乙方的资信币使用完毕，且乙方不再充值；\n    "
        ),
        _c("br"),
        _vm._v(
          "3.在合作期限届满前，甲方赠送给乙方的资信币的余额不足以抵扣办理甲方产品的费用，且乙方不再充值；\n    "
        ),
        _c("br"),
        _vm._v("4.任何一方宣布破产或进入清算或解散程序；\n    "),
        _c("br"),
        _vm._v(
          "5.不可抗力持续三十日以上，任何一方据此发出书面通知之日；\n    "
        ),
        _c("br"),
        _vm._v("6.甲方提前30日书面通知乙方解除本协议；\n    "),
        _c("br"),
        _vm._v(
          "7.乙方有以下行为之一的，甲方有权书面通知乙方单方面终止本协议，本协议在甲方书面通知到达乙方时终止，甲方有权冻结乙方会员权益，且已收取的会员费不予退还：\n    "
        ),
        _c("br"),
        _vm._v("（1）乙方拖欠甲方任何费用；\n    "),
        _c("br"),
        _vm._v("（2）乙方违反本协议书中任何条款。\n    "),
        _c("br"),
        _vm._v(
          "8.乙方有权要求提前终止双方合作关系，但必须提前30日书面通知甲方确认，甲方有权冻结乙方会员权益，且已收取的会员费不予退还。\n    "
        ),
        _c("br"),
        _vm._v(
          "9.如乙方发生如下变更事项，则需提前10日书面通知甲方，甲方有权决定是否提前终止协议：\n    "
        ),
        _c("br"),
        _vm._v("（1）乙方股东发生变更；\n    "),
        _c("br"),
        _vm._v("（2）乙方与第三方进行合并或业务重组；\n    "),
        _c("br"),
        _vm._v("（3）乙方管理层发生变动；\n    "),
        _c("br"),
        _vm._v("（4）其他可能影响到乙方业务经营的变更事项。\n\n    "),
        _c("br"),
        _c("b", [_vm._v("第二款：终止后之事项")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          "1.本协议之终止并不影响本协议项下未完成之结算或任何一方之付款义务以及其他在终止之日前已产生的权利或义务，包括但不限于：\n    "
        ),
        _c("br"),
        _vm._v("（1）支付所有应付给甲方的费用；\n    "),
        _c("br"),
        _vm._v("（2）归还甲方所有业务单据及相关权证资料；\n    "),
        _c("br"),
        _vm._v("（3）向甲方承担因投诉、违规等原因所产生的相关责任；\n    "),
        _c("br"),
        _vm._v(
          "（4）销毁或归还任何带有甲方商业标识的物品外包装或宣传资料等。\n    "
        ),
        _c("br"),
        _vm._v(
          "2.本协议之终止并不影响已有客户在甲方产品相关业务有效期内享受相关服务。\n    "
        ),
        _c("br"),
        _vm._v(
          "3.本协议到期，若甲、乙双方任何一方不继续合作，协议期满后30个工作日内做好客户交接，乙方未按约定完成交接工作的，应当向甲方支付违约金【 / 】元。\n    "
        ),
        _c("br"),
        _c("h3", [_vm._v("第八章\t保密义务")]),
        _vm._v(" "),
        _c("b", [_vm._v("第一条：")]),
        _vm._v("一般义务\n    "),
        _c("br"),
        _vm._v(
          "除法律明确规定以外，甲方不得向第三者展示乙方提交的宣传资料及其它相关资料；乙方不得向第三者泄露甲方按本协议规定提供给乙方的经营技术秘密及其它有损于甲方利益的信息；\n    "
        ),
        _c("br"),
        _c("b", [_vm._v("第二条：协议终止")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          "以上规定的保密义务在本协议期满后仍然有效。甲方按本协议提供给乙方的宣传资料及其它相关资料归甲方所有，乙方须负责妥善保管，并在协议终止后应立即归还，且不得复制。\n    "
        ),
        _c("br"),
        _c("h3", [_vm._v("第九章 适用法律与争议之解决")]),
        _vm._v(" "),
        _c("b", [_vm._v("第一条：")]),
        _vm._v("适用法律\n    "),
        _c("br"),
        _vm._v(
          "本协议之签署、效力、解释和执行以及本协议项下争议之解决均应适用中华人民共和国法律。\n    "
        ),
        _c("br"),
        _c("b", [_vm._v("第二条：")]),
        _vm._v("协商与调解\n    "),
        _c("br"),
        _c("b", [_vm._v("第一款：")]),
        _vm._v(
          "对于因本协议的解释及执行而产生之争议，应首先由双方通过友好协商和解决。\n    "
        ),
        _c("br"),
        _c("b", [_vm._v("第二款：")]),
        _vm._v(
          "如争议未能于前述方式在开始协商后十日内解决，则任何一方有权在甲方所在地法院提交法律诉讼请求。\n    "
        ),
        _c("br"),
        _c("h3", [_vm._v("第十章 附则")]),
        _vm._v(" "),
        _c("b", [_vm._v("第一条：")]),
        _vm._v("通知\n    "),
        _c("br"),
        _c("b", [_vm._v("第一款：")]),
        _vm._v(
          "双方之间的任何通知或书面函件必须以中文写成，以传真、E-mail、专人送达。\n    "
        ),
        _c("br"),
        _vm._v("双方在合作期内的通知方式如下："),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _c("b", [_vm._v("甲方名称：" + _vm._s(_vm.bankData.name))]),
        _vm._v(" "),
        _c("br"),
        _vm._v("收件地址：" + _vm._s(_vm.bankData.address) + "\n    "),
        _c("br"),
        _vm._v("收件人： " + _vm._s(_vm.data.jContact) + "\n    "),
        _c("br"),
        _vm._v("指定电子邮箱： " + _vm._s(_vm.data.jEmail) + "\n    "),
        _c("br"),
        _vm._v("联系电话：" + _vm._s(_vm.data.jPhone) + "\n    "),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _c("b", [_vm._v("乙方名称：")]),
        _c("ins", [_vm._v(" " + _vm._s(_vm.data.shopName))]),
        _vm._v(" "),
        _c("br"),
        _vm._v("收件地址："),
        _c("ins", [_vm._v(" " + _vm._s(_vm.data.contactAddress))]),
        _vm._v(" "),
        _c("br"),
        _vm._v("收件人："),
        _c("ins", [_vm._v(" " + _vm._s(_vm.data.mainContact))]),
        _vm._v(" "),
        _c("br"),
        _vm._v("指定电子邮箱："),
        _c("ins", [_vm._v(" " + _vm._s(_vm.data.mainContactEmail))]),
        _vm._v(" "),
        _c("br"),
        _vm._v("联系电话："),
        _c("ins", [_vm._v(" " + _vm._s(_vm.data.mainContactMobileNo))]),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _c("b", [_vm._v("第二款：")]),
        _vm._v(
          "任何通知方式的更改均须以上述联系E-mail、专人送达收到的书面通知为准，所有通知及函件均应以上述方式送达。上述联系信息适用于各方往来联系、书面文件、司法诉讼文书的送达。双方联系信息出现变更的，变更一方应在变更后3日内书面告知另一方，未告知的视为未变更。按照上述信息送达的，签收的，签收当时为送达；未签收的，自邮寄/发送之日起5日内视为送达。\n    "
        ),
        _c("br"),
        _c("b", [_vm._v("第二条：")]),
        _vm._v("其他\n    "),
        _c("br"),
        _vm._v("本协议由双方于 "),
        _c("ins", [_vm._v(_vm._s(_vm.data.startTimeText))]),
        _vm._v(
          " 在中国杭州市 签署生效，并于本协议项下合作期满后自动终止。\n    "
        ),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v("\n\n    附件一：\n    "),
        _c(
          "h3",
          { staticStyle: { "text-align": "center", "font-weight": "bold" } },
          [_vm._v("资质认证服务机构专享特权规则")]
        ),
        _vm._v(
          "\n    尊敬的合作商，为保障您的合法权益，请您在参加活动前仔细阅读本规则，以免造成误解。如果您是未成年人，请在监护人的陪同和帮助下阅读本规则所有条款。当您点击确认阅读本规则，即视为您已阅读、理解本规则，并同意按本规则执行。\n    "
        ),
        _c("br"),
        _vm._v("一 活动内容\n    "),
        _c("br"),
        _vm._v("您本次支付服务费 "),
        _c("ins", [_vm._v(_vm._s(_vm.data.finalPrice))]),
        _vm._v(" 元，将享受 "),
        _c("ins", [_vm._v(_vm._s(_vm.data.name))]),
        _vm._v(
          "资质认证服务机构专项特权（在本次购买的资质认证服务机构会员身份有效期内有效，过期无效）。\n    "
        ),
        _c(
          "el-table",
          { staticStyle: { width: "100%" }, attrs: { data: _vm.tableData } },
          [
            _c("el-table-column", {
              attrs: { prop: "fullName", label: "机构等级" }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "price", label: "服务费(元)" }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "机构专享特权" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      _c("p", { domProps: { innerHTML: _vm._s(row.desc) } })
                    ]
                  }
                }
              ])
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("br"),
        _vm._v("二 资信币使用规则\n    "),
        _c("br"),
        _vm._v("2.1 资信币构成：\n    "),
        _c("br"),
        _vm._v(
          "您的资信币账户所显示的资信币是资信办官方（包括网站及小程序等）虚拟商品的预付费凭证，通过指定的充值方式及兑换比例进行充值或活动赠送的资信币；\n    "
        ),
        _c("br"),
        _vm._v("2.2 资信币有效期\n    "),
        _c("br"),
        _vm._v(
          "本合同约定赠送的资信币在本次购买的资质认证服务机构会员身份有效期内有效，过期无效。\n    "
        ),
        _c("br"),
        _vm._v("2.3 资信币使用细则\n    "),
        _c("br"),
        _vm._v(
          "1）包含充值、赠送的资信币在内的账户余额可用于购买商机、购买精线索、办理【资信办平台授权业务和撮合业务】的费用抵扣，资信币不能用于转赠或提现等其他用途。\n    "
        ),
        _c("br"),
        _vm._v(
          "2）办理【资信办平台授权业务和撮合业务】时，您可以选择直接在资信办商家后台（https://manage.zixinban.com/）的“在线申报”功能直接下单，以申报记录作为您办理【资信办平台授权业务和撮合业务】的凭证。若您选择线下申报，双方需签署书面《服务确认单》约定具体执行的服务内容、服务价格、服务周期等。《服务确认单》经甲、乙双方授权代表签字或加盖公章之日起生效。\n    "
        ),
        _c("br"),
        _vm._v(
          "甲方授权代表人：" +
            _vm._s(_vm.data.jContact) +
            "                                           乙方授权代表人：" +
            _vm._s(_vm.data.mainContact) +
            "\n    "
        ),
        _c("br"),
        _c("ins", [
          _vm._v(
            "双方授权代表人出现变更的，变更一方应在变更后3日内书面告知另一方，未告知的视为未变更，由此产生的一切后果由过错方承担。"
          )
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v("3）赠送的资信币在如下情形会失效：\n    "),
        _c("br"),
        _vm._v("您主动注销用户账号；\n    "),
        _c("br"),
        _vm._v("您的服务协议被终止；\n    "),
        _c("br"),
        _vm._v(
          "您利用或者我们有合理怀疑您利用您的注册账号从事作弊行为以获取不正当经济利益的情形；\n    "
        ),
        _c("br"),
        _vm._v(
          "其他根据用户协议、相关说明或规则导致我们无法向您提供服务或停用服务的情形。\n    "
        ),
        _c("br"),
        _vm._v("三 正当性保证\n    "),
        _c("br"),
        _vm._v(
          "我们包含赠送在内的所有优惠推广活动仅面向正当、合法使用资信办的用户。一旦您存在利用我们的规则漏洞进行任何形式的作弊行为（包括但不限于通过我们的的活动获得不正当的经济利益），我们有权取消与作弊行为相关账户赠送资信币，追回您作弊所得的不正当经济利益、关闭作弊账户或与您相关的所有账户，并保留取消您的后续使用我们服务/产品的权利，及依据严重程度追究您的法律责任。\n    "
        ),
        _c("br"),
        _vm._v("四 特别说明\n    "),
        _c("br"),
        _vm._v(
          "您完全理解并同意，资信办有权随时修改本规则内容，届时将通过在“资信办”上公布修改后的协议，该公布将视为资信办已通知用户；同时，资信办也可通过其他适当方式通知用户。如果您选择继续支付会员费或继续使用资信币即表示您同意并接受修改后的规则并受其约束；如果您不同意对我们对本规则的修改，请立即放弃支付或停止使用本服务。\n  "
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "sign" }, [
      _c("span", [_vm._v("甲方：（盖章）")]),
      _vm._v(" "),
      _c("span", [_vm._v("乙方：（盖章）")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "sign" }, [
      _c("span", [_vm._v("日期：")]),
      _vm._v(" "),
      _c("span", [_vm._v("日期：")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }