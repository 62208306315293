import Cookies from 'js-cookie'

const TokenKey = 'ngcc_mis_token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function setChannelStatus(val = 1) {
  return Cookies.set('channel', val)
}

export function getChannelStatus(val = 1) {
  return Cookies.get('channel')
}

export function removeChannelStatus(val = 1) {
  return Cookies.remove('channel')
}
