import request from '@/utils/request'

/**
 * 获取在线人数
 * @param {*} data
 * @returns
 */
export function getOnLineUser() {
  return request({
    url: '/api/monitor/online/user',
    method: 'GET'
  })
}

/**
 * 获取在线人数
 * @param {*} data
 * @returns
 */
export function getOnLineUserLine() {
  return request({
    url: '/api/monitor/online/user_line',
    method: 'GET'
  })
}
