var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "content" }, [
      _c(
        "ul",
        _vm._l(_vm.recommendNiche, function(item) {
          return _c("li", { key: item.id, staticClass: "item" }, [
            _c(
              "p",
              {
                staticClass: "title",
                on: {
                  click: function($event) {
                    return _vm.goToPage("/niche/store")
                  }
                }
              },
              [_vm._v("\n          " + _vm._s(item.title) + "\n        ")]
            ),
            _vm._v(" "),
            _c("p", { staticClass: "time" }, [
              _c("i", { staticClass: "el-icon-time" }),
              _vm._v(
                " " + _vm._s(item.publishOn.substring(0, 10)) + "发布\n        "
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "address" }, [
              _c("i", { staticClass: "el-icon-location-outline" }),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(item.areaName))]),
              _vm._v(_vm._s(item.qName) + "\n        ")
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "mask" }, [
              _c("span", [_vm._v("备注：")]),
              _vm._v(_vm._s(item.mark) + "\n        ")
            ])
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }