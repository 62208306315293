import router from './router'
import store from './store'
import { Message, MessageBox } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken, setToken, setChannelStatus, getChannelStatus } from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'
import _enum from './api/enum'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
  // start progress bar
  NProgress.start()
  // set page title
  var title = to.name || to.meta.title
  document.title = getPageTitle(title)

  // determine whether the user has logged in
  // 在这里处理前台传来的token
  if (to.query.token) {
    setToken(to.query.token)
  }

  const hasToken = getToken()

  if (hasToken) {
    delete to.query.token
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({ path: '/' })
      NProgress.done()
    } else {
      const hasRoles = store.getters.roles && store.getters.roles.length > 0
      if (hasRoles) {
        next()
      } else {
        try {
          await store.dispatch('user/getInfo')
          // 可以请求一些基础数据
          store.dispatch('common/getThreeAreaList')
          const accessRoutes = await store.dispatch('menu/getSideMenus')
          accessRoutes.push({ path: '*', redirect: '/404', hidden: true })
          router.addRoutes(accessRoutes)
          next({ ...to, replace: true })
        } catch (error) {
          console.log(error)
          // remove token and go to login page to re-login
          await store.dispatch('user/resetToken')
          Message.error(error || 'Has Error')
          next(`/login?redirect=${to.path}`)
          NProgress.done()
        }
      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()

  const permissions = store.getters.permissions

  // 权限是否包含渠道申报
  if (permissions?.includes('/declare/channel')) {
    const profile = store.getters.profile
    //
    const channel = getChannelStatus()
    if (profile.channelStatus === _enum.channel_apply_status.reject && !channel) {
      // 缓存一下说明本次登录已经弹出了
      setChannelStatus()
      MessageBox.confirm('您的服务机构申请审核失败，赶紧去完善资料！', '通知', {
        confirmButtonText: '查看',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        router.push('/declare/channel')
      }).catch(() => {

      })
    }
  }
})
