import request from '@/utils/request'

/**
 * 获取我的资金
 * @returns
 */
export function getBalanceData(params) {
  return request({
    url: '/shop/balance/owner',
    method: 'GET',
    params
  })
}

/**
 * 获取我的提现列表
 * @param {*} params
 * @returns
 */
export function getWithdrawList(params) {
  return request({
    url: '/order/cash_out/apply',
    method: 'GET',
    params
  })
}

/**
 * 申请提现
 * @param {*} data
 * @returns
 */
export function applyWithdraw(data) {
  return request({
    url: '/order/cash_out/apply',
    method: 'POST',
    data
  })
}

/**
 * 获取收款账户详情（对私户）
 * @returns
 */
export function getPersonalCollection() {
  return request({
    url: '/shop/member/detail',
    method: 'GET'
  })
}

/**
 * 新增个人收款账户
 * @param {*} data
 * @returns
 */
export function savePersonalAccount(data) {
  return request({
    url: '/shop/member',
    method: 'POST',
    data
  })
}

/**
 * 删除个人收款账户
 * @returns
 */
export function deletePersonalAccount() {
  return request({
    url: '/shop/member',
    method: 'DELETE'
  })
}

/**
 * 开通企业结算账户（对公户）
 * @param {*} data
 * @returns
 */
export function saveComponyAccount(data, profile) {
  let method = ''
  if (profile.corMemberStatus === 0) {
    method = 'POST'
  } else {
    method = 'PUT'
  }
  return request({
    url: '/shop/corpmember',
    method,
    data
  })
}

/**
 * 更新企业结算账户（对公户）
 * @param {*} data
 * @returns
 */
export function changeCompanyAccount(data) {
  return request({
    url: '/shop/corpmember',
    method: 'PUT',
    data
  })
}

/**
 * 获取服务商开通结算账户的必要信息
 * @returns
 */
export function getShopProfile() {
  return request({
    url: '/shop/profile',
    method: 'GET'
  })
}

/**
 * 获取企业结算账户详情（未开或审核失败）
 * @returns
 */
export function getCompanyAccountDetail() {
  return request({
    url: '/shop/corpmember/detail',
    method: 'GET'
  })
}

/**
 * 获取企业结算账户详情（已开）
 * @returns
 */
export function getSelfSettleAccountDetail() {
  return request({
    url: '/shop/corpmember/info',
    method: 'GET'
  })
}

/**
 * 后台获取企业结算账户详情（用于审核）
 * @returns
 */
export function getCompanyAccountVerifyDetail(id) {
  return request({
    url: '/shop/corpmember/detail/' + id,
    method: 'GET'
  })
}
