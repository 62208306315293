var render = function(_h, _vm) {
  var _c = _vm._c
  return _vm._m(0)
}
var staticRenderFns = [
  function(_h, _vm) {
    var _c = _vm._c
    return _c("div", [
      _c("p", { staticClass: "line" }),
      _vm._v(" "),
      _c("span", { staticStyle: { "line-height": "1.3" } }, [
        _c("h3", [_vm._v("【第一章 用户资格】")]),
        _vm._v(
          "\n    您确认，在您开始注册、使用资信办平台服务前，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力，具体是\n    "
        ),
        _c("br"),
        _vm._v(
          "1、年满十八岁具备完全民事权利能力和民事行为能力的自然人；\n    "
        ),
        _c("br"),
        _vm._v(
          "2、据中国法律法规成立并合法存在的机关、企事业单位、社团组织和其他组织。\n    "
        ),
        _c("br"),
        _vm._v(
          "若您不具备前述与您行为相适应的民事行为能力，则您及您的监护人应依照法律规定承担因此而导致的一切后果。\n    "
        ),
        _c("br"),
        _vm._v(
          "此外，您还需遵从国家相关法律法规，并确保您不是任何国家、国际组织或者地域实施的贸易限制、制裁或其他法律、规则限制的对象，否则您可能无法正常注册及使用资信办平台服务。\n    "
        ),
        _c("h3", [_vm._v("【第二章 资信办平台服务】")]),
        _vm._v(
          "\n    要直接注册为服务商，或普通用户转为服务商，您还需要了解以下信息。 "
        ),
        _c("br"),
        _vm._v("2.1 服务商平台管理\n    "),
        _c("br"),
        _vm._v(
          "通过在资信办平台创建服务产品，您可以发布服务信息及与雇主达成交易。基于资信办平台的管理需要，一个用户只能对应1家服务商。若需要多个服务商则应创建新的用户账号。\n    "
        ),
        _c("br"),
        _vm._v(
          "如果您要短暂停止服务，可下架全部服务产品，或直接执行“关闭”功能，但应当在关停前将已达成的交易继续承担完成服务、完成售后及维权投诉处理等交易保障责任。\n    "
        ),
        _c("br"),
        _vm._v(
          "如您主动向资信办平台申请关停服务，您应当提前30日在服务主页中醒目的位置进行公示，同时应当对您关停前已达成的交易继续承担完成服务、完成售后及维权投诉处理等交易保障责任。\n    "
        ),
        _c("br"),
        _vm._v("2.2 服务产品的信息发布和推广\n    "),
        _c("br"),
        _vm._v(
          "通过资信办平台提供的服务，您有权通过文字、图片、视频、音频等形式在其他平台上发布服务信息、招揽和物色交易对象、以达成交易。\n    "
        ),
        _c("br"),
        _vm._v("您不得在资信办平台上发布以下信息： "),
        _c("br"),
        _vm._v("（1） 国家禁止或限制的； "),
        _c("br"),
        _vm._v("（2）\n    侵犯他人知识产权或其它合法权益的； "),
        _c("br"),
        _vm._v("2.3 服务产品的交易\n    "),
        _c("br"),
        _vm._v(
          "通过资信办平台提供的服务产品，您有权在平台进行服务产品交易，完成服务产品的交付及交付后的款项收支，发票管理等行为。\n    "
        ),
        _c("br"),
        _vm._v(
          "您不得违反《银行卡业务管理办法》使用银行卡，或利用信用卡套取现金；您不得在明知或应知他人可能盗刷银行卡情形下而与对方进行交易。\n    "
        ),
        _c("br"),
        _vm._v(
          "您应当遵守诚实信用原则，确保您所交易的服务产品信息真实、与您实际发布的服务信息相符，并在交易过程中切实履行您的交易承诺。\n    "
        ),
        _c("br"),
        _vm._v(
          "依法纳税是每一个公民、企业应尽的义务，您应对销售额/营业额超过法定免征额部分及时、足额地向税务主管机关申报纳税。用户通过资信办平台进行交易，应按照《资信办平台发票管理规则》办理发票开具和收取相关事宜。\n    "
        ),
        _c("br"),
        _vm._v("2.4服务商及/或服务的评价 "),
        _c("br"),
        _vm._v(
          "您可以在资信办平台提供的评价系统中对与您达成交易的雇主进行评价。\n    "
        ),
        _c("br"),
        _vm._v(
          "您的评价行为应遵守资信办平台规则的相关规定，评价内容应当客观真实，不应包含任何污言秽语、色情低俗、广告信息及法律法规与本协议列明的其他禁止性信息；您不应以不正当方式帮助他人提升信用或利用评价权利对其他用户实施威胁、敲诈勒索。\n    "
        ),
        _c("br"),
        _vm._v(
          "资信办平台可按照相关规定对您实施上述行为所产生的评价信息进行删除或屏蔽。 "
        ),
        _c("br"),
        _vm._v("2.5 交易争议处理\n    "),
        _c("br"),
        _vm._v(
          "您在资信办平台交易过程中与雇主发生争议时，您与雇主中的任意一方均有权选择以下途径解决： "
        ),
        _c("br"),
        _vm._v("（1）\n    争议双方自主协商； "),
        _c("br"),
        _vm._v("（2） 使用资信办平台提供的争议调处服务； "),
        _c("br"),
        _vm._v("（3）\n    请求消费者协会或者其他依法成立的调解组织调解； "),
        _c("br"),
        _vm._v("（4） 向有关行政部门投诉； "),
        _c("br"),
        _vm._v(
          "（5）\n    根据与争议对方达成的相关协议（如有）提请仲裁机构仲裁； "
        ),
        _c("br"),
        _vm._v("（6）向人民法院提起诉讼。\n    "),
        _c("br"),
        _vm._v(
          "如用户将纠纷告知资信办平台知悉纠纷情况的，资信办平台有权通过电子邮件及电话联系，向纠纷双方了解纠纷情况，并将所了解的情况通过电子邮件互相通知对方。\n    "
        ),
        _c("br"),
        _vm._v("如您对调处决定不满意，您仍有权采取其他争议处理途径解决争议。 "),
        _c("br"),
        _vm._v("2.6 资信办平台服务费用\n    "),
        _c("br"),
        _vm._v(
          "资信办平台向您提供的服务付出了大量的成本，因此平台会收取合理的费用，资信办平台会明示收费的业务相关信息。\n    "
        ),
        _c("br"),
        _vm._v(
          "如未来资信办平台进行费用调整，平台会采取合理途径并以足够合理的期限提前通过法定程序的方式通知您，确保您有充分选择的权利。\n  "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }