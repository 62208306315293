<template>
  <el-carousel :interval="5000" arrow="hover" indicator-position="none" :loop="true">
    <el-carousel-item v-for="(item, index) in imgList" :key="index" @click.native="linkTo(item.redirectUrl)">
      <img class="img" :src="item.imagePath" alt>
    </el-carousel-item>
  </el-carousel>
</template>

<script>
import { validURL } from '@/utils/validate'
import { getHomeBanner } from '@/api/maint/cfg'
export default {
  name: 'HomeBanner',
  data() {
    return {
      imgList: []
    }
  },
  mounted() {
    this.getBanner()
  },
  methods: {
    async getBanner() {
      try {
        const res = await getHomeBanner()
        const banner = JSON.parse(res[0].value)
        if (banner.length === 2) {
          this.imgList = [...banner, ...banner]
        } else {
          this.imgList = banner
        }
      } catch (error) {
        console.log(error)
      }
    },

    linkTo(url) {
      if (!url) {
        return
      }
      if (validURL(url)) {
        window.open(url)
      } else {
        this.$router.push({ path: url })
      }
    }
  }
}
</script>

<style scoped lang="scss">
/deep/.el-carousel__container {
  height: 340px;
}

.img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
</style>
