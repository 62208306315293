import request from '@/utils/request'

export function getList() {
  return request({
    url: '/identity/menus',
    method: 'GET'
  })
}

export function listForRouter(params) {
  return request({
    url: '/identity/menus/routers',
    method: 'GET',
    params
  })
}

export function save(data) {
  let methodName = 'post'
  let url = '/identity/menus'
  if (data.id > 0) {
    methodName = 'put'
    url = url + '/' + data.id
  }
  return request({
    url: url,
    method: methodName,
    data
  })
}

export function delMenu(id) {
  return request({
    url: `/identity/menus/${id}`,
    method: 'DELETE'
  })
}
export function menuTreeListByRoleId(roleId) {
  return request({
    url: `/identity/menus/${roleId}/menuTree`,
    method: 'GET'
  })
}

export function menuSync() {
  return request({
    url: '/identity/menus/sync',
    method: 'PUT'
  })
}
