<template>
  <div class="dashboard-container">
    <el-row :gutter="20" class="panel-group">
      <el-col :span="6">
        <div class="card-panel" @click="handleSetOnlineData()">
          <div class="card-panel-icon-wrapper icon-people">
            <svg-icon icon-class="peoples" class-name="card-panel-icon" />
          </div>
          <div class="card-panel-description">
            <div class="card-panel-text">
              在线人数
            </div>
            <el-tooltip placement="right" effect="light">
              <div slot="content" style="width:600px">
                <el-table :data="online.users">
                  <el-table-column width="160px" property="time" label="时间" />
                  <el-table-column width="160px" property="shop_name" label="商户" />
                  <el-table-column property="name" label="用户名" />
                  <el-table-column property="account" label="登录账户" />
                </el-table>
              </div>
              <count-to :start-val="0" :end-val="online.count" :duration="2600" class="card-panel-num" />
            </el-tooltip>
          </div>
        </div>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="24">
        <div class="chart section">
          <v-chart :options="lineData" />
        </div>
      </el-col>
    </el-row>

    <div class="footer">
      <!-- 底部 -->
      <div class="bottom">
        <p class="title">快捷审核入口</p>
        <i class="line" />
        <!-- 快捷操作 -->
        <div class="content">
          <div
            v-for="item in shortcutItems"
            :key="item.name"
            @click="goToPage(item.path)"
          >
            <img :src="item.src" :alt="item.name">
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CountTo from 'vue-count-to'
import { getOnLineUser, getOnLineUserLine } from '@/api/gateway/online'
import ECharts from 'vue-echarts/components/ECharts'
import { mapGetters } from 'vuex'
import 'echarts/lib/chart/bar'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/pie'
import 'echarts/lib/chart/map'
import 'echarts/lib/chart/radar'
import 'echarts/lib/chart/scatter'
import 'echarts/lib/chart/effectScatter'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/polar'
import 'echarts/lib/component/geo'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/title'
import 'echarts/lib/component/visualMap'
import 'echarts/lib/component/dataset'
import 'echarts/map/js/world'
import 'zrender/lib/svg/svg'

export default {
  name: 'Dashboard',
  components: {
    CountTo,
    chart: ECharts
  },
  data() {
    return {
      online: {},
      timer: null,
      lineData: {
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['24小时实时在线人数']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '24小时实时在线人数',
            type: 'line',
            stack: '总量',
            data: []
          }
        ]
      },
      shortcutItems: [
        {
          name: '商户入驻',
          src: require('@/assets/images/shop/apply.png'),
          path: '/apply'
        },
        {
          name: '机构签约',
          src: require('@/assets/images/shop/apply_channel.png'),
          path: '/apply/channel'
        },
        {
          name: '代客申报',
          src: require('@/assets/images/shop/declare.png'),
          path: '/declare'
        },
        {
          name: '渠道申报',
          src: require('@/assets/images/shop/declare_shop.png'),
          path: '/declare/shop'
        },
        {
          name: '发票审核',
          src: require('@/assets/images/shop/invoice.png'),
          path: '/invoice'
        },
        {
          name: '产品合作',
          src: require('@/assets/images/shop/apply_credit.png'),
          path: '/apply/credit'
        },
        {
          name: '客户报备',
          src: require('@/assets/images/shop/custom_declare.png'),
          path: '/declare/custom'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['profile', 'permissions'])
  },
  mounted() {
    this.initData()
  },
  beforeDestroy() {
    this.timer && clearTimeout(this.timer)
  },
  methods: {
    goToPage(path) {
      if (!path) return
      this.$store.dispatch('user/getUserInfo')
      if (!this.permissions.includes(path)) {
        this.$router.push('/403')
        return
        // return this.$message.warning('暂无权限，请联系管理员')
      }
      this.$router.push({ path })
    },
    async handleSetOnlineData() {
      try {
        this.online.count = 0
        this.getData()
      } catch (error) {
        console.log(error)
      }
    },
    // 获取日志信息
    async initData() {
      try {
        this.getData()
        this.timer = setInterval(async() => {
          this.getData()
        }, 10000)
      } catch (error) {
        console.log(error)
      }
    },
    async getData() {
      this.online = await getOnLineUser()
      const line = await getOnLineUserLine()
      this.lineData.xAxis.data = line.times
      this.lineData.series[0].data = line.datas
    }
  }
}
</script>
  <style rel="stylesheet/scss" lang="scss" scoped>
    .el-row {
      margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

    .dashboard {

    &-container {
      padding: 15px;
    background-color: #f0f2f5;
    }

    &-text {
      font-size: 14px;
    line-height: 22px;
    padding-bottom: 15px;
    }
  }
    .panel-group {
      margin-top: 18px;
      margin-left: 0px !important;
      margin-right: 0px !important;

      .card-panel-col {
        margin-bottom: 32px;
      }

      .card-panel {
        height: 108px;
        cursor: pointer;
        font-size: 12px;
        position: relative;
        overflow: hidden;
        color: #666;
        background: #fff;
        box-shadow: 4px 4px 40px rgba(0, 0, 0, .05);
        border-color: rgba(0, 0, 0, .05);

        &:hover {
          .card-panel-icon-wrapper {
            color: #fff;
          }

          .icon-people {
            background: #40c9c6;
          }

          .icon-message {
            background: #36a3f7;
          }

          .icon-money {
            background: #f4516c;
          }

          .icon-shopping {
            background: #34bfa3
          }
        }

        .icon-people {
          color: #40c9c6;
        }

        .icon-message {
          color: #36a3f7;
        }

        .icon-money {
          color: #f4516c;
        }

        .icon-shopping {
          color: #34bfa3
        }

        .card-panel-icon-wrapper {
          float: left;
          margin: 14px 0 0 14px;
          padding: 16px;
          transition: all 0.38s ease-out;
          border-radius: 6px;
        }

        .card-panel-icon {
          float: left;
          font-size: 48px;
        }

        .card-panel-description {
          float: right;
          font-weight: bold;
          margin: 26px;
          margin-left: 0px;

          .card-panel-text {
            line-height: 18px;
            color: rgba(0, 0, 0, 0.45);
            font-size: 16px;
            margin-bottom: 12px;
          }

          .card-panel-num {
            font-size: 20px;
          }
        }
      }
    }

    .echarts {
      width: 100%;
    height: 100%;
  }

    .box-card {
      height: 108px;
  }

    .chart {
      height: 350px;
  }

    .section {
      padding: 20px;
    background-color: white;
    border: 1px solid #ebeef5;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
  }

  .box-card > div > div > .svg-icon {
      width: 4em;
    height: 4em;
    color: #34bfa3;
  }

    .card-panel-text {
      padding-top: 10px;
    font-size: 16px;
    color: gray;
  }

    .card-panel-num {
      padding-top: 10px;
    font-size: 20px;
    font-weight: bold;
  }

  .footer {
    display: flex;

    .bottom {
      width        : 100%;
      min-width    : 100%;
      height       : 240px;
      background   : #ffffff;
      box-shadow   : 14px 17px 29px 28px rgba(122, 175, 255, 0.03);
      border-radius: 12px;

      .title {
        height      : 60px;
        padding-left: 30px;
        line-height : 60px;
        font-size   : 16px;
        font-weight : 700;
        color       : #333333;
      }

      .line {
        display         : block;
        width           : 100%;
        height          : 1px;
        opacity         : 0.4;
        background-color: #ccc;
      }

      .content {
        display        : flex;
        justify-content: space-between;
        align-items    : center;
        height         : 177px;
        padding        : 0 50px;

        div {
          display       : flex;
          flex-direction: column;
          align-items   : center;
          cursor        : pointer;

          img {
            width: 60px;
          }

          span {
            font-size  : 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color      : #333333;
            margin-top : 15px;
          }
        }
      }
    }
  }
  </style>
