var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-drawer",
    _vm._g(_vm._b({}, "el-drawer", _vm.customizedAttrs, false), _vm.$listeners),
    [
      _c(
        "span",
        {
          staticClass: "drawer__title",
          attrs: { slot: "title" },
          slot: "title"
        },
        [_vm._v("\n    " + _vm._s(_vm.title ? _vm.title + "：" : "") + "\n  ")]
      ),
      _vm._v(" "),
      _c("section", { staticClass: "drawer-content" }, [_vm._t("default")], 2)
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }