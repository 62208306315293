import { list as threeArea } from '@/api/maint/area'

const state = {
  threeArea: []
}

const mutations = {
  ADD_THREE_AREA: (state, area) => {
    state.threeArea = area
  }
}

const actions = {

  // 获取地区列表
  async getThreeAreaList({ dispatch, commit }, data = { include_zh: true }) {
    try {
      const res = await threeArea(data)
      commit('ADD_THREE_AREA', deleteChildren(res))
      return Promise.resolve(res)
    } catch (error) {
      console.log(error)
      return Promise.reject(error)
    }
  }
}

// eslint-disable-next-line no-unused-vars
function deleteChildren(source) {
  source.forEach((item) => {
    if (!item.children || !item.children.length) {
      item.children = undefined
    } else if (item.children.length) {
      deleteChildren(item.children)
    }
  })
  return source
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

