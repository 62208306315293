import { validPhonenumber } from '@/utils/validate'
import { getAdminShop } from '@/api/identity/admin'
import { getPhoneCode, register } from '@/api/account'
import { debounce } from '@/utils'
import UserAgreement from '@/components/Agreement/User'
import TencentCaptcha from '@/utils/tencent-captcha'
export default {
  name: 'login',
  components: { UserAgreement },
  data() {
    const validPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入手机号'))
      } else if (!validPhonenumber(value)) {
        callback(new Error('请输入正确的手机号'))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error(this.$t('login.errorPassword')))
      } else {
        callback()
      }
    }
    return {
      showLogin: true,
      isAgree: false,
      agreeDialog: false,

      loginForm: {
        tenantId: '',
        phone: '',
        password: '',
        isRemembered: true
      },
      registerForm: {
        phone: '',
        password: '',
        smsValidateCode: ''
      },
      loginPhoneForm: {
        phone: '',
        smsValidateCode: '',
        tenantId: ''
      },

      selectTab: 0,
      // tab标签数据
      tabList: [{ label: '密码登录' }, { label: '验证码登录' }],
      isHasSupplierList: false,
      supplierOptions: [],
      loginRules: {
        tenantId: [{ required: true, message: '当前手机号已绑定多个服务商账号，请选择本次要登录的账号', trigger: 'change' }],
        phone: [{ required: true, trigger: 'blur', validator: validPhone }],
        password: [{ required: true, trigger: 'blur', validator: validatePassword }]
      },
      loginPhoneRules: {
        tenantId: [{ required: true, message: '当前手机号已绑定多个服务商账号，请选择本次要登录的账号', trigger: 'change' }],
        phone: { required: true, validator: validPhone, trigger: 'blur' },
        smsValidateCode: { required: true, message: '请输入短信验证码', trigger: 'blur' }
      },
      registerRules: {
        phone: { required: true, validator: validPhone, trigger: 'blur' },
        password: [{ required: true, message: '请设置登录密码', trigger: 'blur' }, { validator: validatePassword, trigger: 'blur' }],
        smsValidateCode: { required: true, message: '请输入短信验证码', trigger: 'blur' }
      },
      loading: false,
      smsFlag: false,
      verificationCodeText: '获取验证码',
      pwdType: 'password',
      redirect: '/'
    }
  },
  mounted() {
    this.init()
  },
  beforeDestroy() {
    clearTimeout(this.timer)
    this.loginPhoneForm.tenantId = ''
    this.loginForm.tenantId = ''
  },
  methods: {

    toAgree() {
      this.agreeDialog = false
      this.isAgree = true
    },

    // 打开登录页
    openLogin() {
      this.selectTab = 0
      this.isHasSupplierList = false
      this.showLogin = true
      this.$refs.registerFormRef.resetFields()
      this.clearConut()
      this.$nextTick(() => {
        this.$refs.loginFormRef.resetFields()
      })
      const line = document.getElementsByClassName('line')
      line[0].style.transform = 'translateX(80px)'
    },

    // 清除计数
    clearConut() {
      this.verificationCodeText = '获取验证码'
      clearInterval(this.timer)
      this.smsFlag = false
    },

    // 打开注册框
    openRegister() {
      this.showLogin = false
      this.$nextTick(() => {
        this.$refs.registerFormRef.resetFields()
      })
      this.clearConut()
    },

    changeLoginType(val) {
      const line = document.getElementsByClassName('line')
      this.selectTab = val
      this.supplierOptions = []
      this.isHasSupplierList = false
      if (val === 1) {
        line[0].style.transform = 'translateX(266px)'
        this.clearCount()
        this.loginPhoneForm.tenantId = null

        this.$nextTick(() => {
          this.$refs.loginPhoneFormRef.resetFields()
        })
      } else {
        line[0].style.transform = 'translateX(80px)'
        this.loginForm.tenantId = null

        this.$nextTick(() => {
          this.$refs.loginFormRef.resetFields()
        })
      }
    },

    getPhoneCode(val) {
      const phoneNumber = val === 'login' ? this.loginPhoneForm.phone : this.registerForm.phone
      const sendType = val === 'login' ? 1 : 2
      if (!validPhonenumber(phoneNumber)) {
        return this.$message.warning('请输入正确的手机号码')
      }
      this.sendPhoneCode(phoneNumber, sendType)
    },

    // 发送手机验证码
    async sendPhoneCode(phoneNumber, sendType) {
      const obj = {
        sendType,
        data: { phoneNumber }
      }
      try {
        const res = await getPhoneCode(obj)
        if (res.status !== 400) {
          this.$message.success('手机验证码发送成功')
          this.smsFlag = true
          let seconds = 60
          this.timer = setInterval(() => {
            seconds -= 1
            this.verificationCodeText = ` ${seconds} 秒`
            if (seconds === 0) {
              this.clearCount()
            }
          }, 1000)

          return
        } else if (res.status === 400 && res.type === 'need_captcha') {
          const { appid, bizState, randstr, ticket, ret } = await TencentCaptcha()
          obj.data.result = { appid, bizState, randstr, ticket }
          if (ret !== 0) { return this.$message.error('图形验证失败') }

          const res = await getPhoneCode(obj)
          if (res) {
            this.$message.success('手机验证码发送成功')
            this.smsFlag = true
            let seconds = 60
            this.timer = setInterval(() => {
              seconds -= 1
              this.verificationCodeText = ` ${seconds} 秒`
              if (seconds === 0) {
                this.clearCount()
              }
            }, 1000)
          }
        }
      } catch (error) {
        console.log(error)
        // 图形验证码校验
      }
    },

    // 清除计数
    clearCount() {
      this.verificationCodeText = '获取验证码'
      clearInterval(this.timer)
      this.smsFlag = false
    },

    // 获取服务商
    searchSupplier: debounce(async function(val) {
      this.getSuppliers(val)
    }, 500),

    async getSuppliers(val) {
      this.supplierOptions = []
      if (!val) { return }
      try {
        const res = await getAdminShop(val)
        this.supplierOptions = res
        if (this.supplierOptions.length === 0) {
          this.isHasSupplierList = false
        } else if (this.supplierOptions.length > 1) {
          this.isHasSupplierList = true
        } else {
          this.loginForm.tenantId = res[0].tenantId
          this.loginPhoneForm.tenantId = res[0].tenantId
          this.registerForm.tenantId = res[0].tenantId
          this.isHasSupplierList = false
        }

        return Promise.resolve()
      } catch (error) {
        console.log(error)
      }
    },

    init() {
      const redirect = this.$route.query.redirect
      const phone = window.localStorage.getItem('phone')

      if (redirect) {
        this.redirect = redirect
      }

      if (phone) {
        this.loginForm.phone = phone
        this.loginPhoneForm.phone = phone
        this.loginForm.isRemembered = true
      } else {
        this.loginForm.isRemembered = false
      }
    },
    showPwd() {
      if (this.pwdType === 'password') {
        this.pwdType = ''
      } else {
        this.pwdType = 'password'
      }
    },

    async getAdminShop(val) {
      if (val === 'pwd') {
        try {
          const res = await getAdminShop(this.loginForm.phone)
          return Promise.resolve(res)
        } catch (error) {
          console.log(error)
        }
      } else {
        try {
          const res = await getAdminShop(this.loginPhoneForm.phone)
          return Promise.resolve(res)
        } catch (error) {
          console.log(error)
        }
      }
    },

    submitRegister() {
      this.$refs.registerFormRef.validate(async(valid) => {
        if (!valid) { return }
        if (!this.isAgree) { return this.$message('请阅读并同意《资信办平台注册协议》') }

        // 登录
        this.loading = true
        try {
          await register(this.registerForm)
          this.$message.success('注册成功，将自动登录')
          await this.getSuppliers(this.registerForm.phone)
          try {
            await this.$store.dispatch('user/login', { phone: this.registerForm.phone, password: this.registerForm.password, tenantId: this.registerForm.tenantId })
            // 登录完成
            this.$router.push({ path: '/apply/go' })
          } catch (error) {
            console.log(error)
          }
        } catch (error) {
          console.log(error)
        } finally {
          this.loading = false
        }
      })
    },

    async handleLogin(val) {
      // 没有触发 登录时手动触发

      if (this.loginForm.isRemembered) {
        window.localStorage.setItem('phone', this.loginForm.phone)
      } else {
        window.localStorage.removeItem('phone')
      }

      if (val === 'pwd') {
        if (!this.loginForm.tenantId && this.loginForm.phone) {
          try {
            const res = this.supplierOptions.length > 0 ? this.supplierOptions : await getAdminShop(this.loginForm.phone)
            this.supplierOptions = res
            if (this.supplierOptions.length === 0) {
              this.isHasSupplierList = false
              return this.$message.warning('该账号未入驻')
            } else if (this.supplierOptions.length > 1) {
              this.isHasSupplierList = true
            } else {
              this.loginForm.tenantId = res[0].tenantId
              this.isHasSupplierList = false
            }
          } catch (error) {
            console.log(error)
          }
        }
        this.$refs.loginFormRef.validate(async valid => {
          if (valid) {
            if (this.supplierOptions.length > 1 && !this.loginForm.tenantId) {
              return
            }
            this.loading = true
            try {
              await this.$store.dispatch('user/login', this.loginForm)
              // 登录完成
              this.supplierOptions = []
              this.$router.push({
                path: this.redirect
              })
            } catch (error) {
              console.log(error)
            } finally {
              this.loading = false
            }
          } else {
            return false
          }
        })
      } else {
        if (!this.loginPhoneForm.tenantId && this.loginPhoneForm.phone) {
          try {
            const res = this.supplierOptions.length > 0 ? this.supplierOptions : await getAdminShop(this.loginPhoneForm.phone)
            this.supplierOptions = res
            if (this.supplierOptions.length === 0) {
              this.isHasSupplierList = false
              return this.$message.warning('该账号未入驻')
            } else if (this.supplierOptions.length > 1) {
              this.isHasSupplierList = true
            } else {
              this.loginPhoneForm.tenantId = res[0].tenantId
              this.isHasSupplierList = false
            }
          } catch (error) {
            console.log(error)
          }
        }
        this.$refs.loginPhoneFormRef.validate(async valid => {
          if (this.supplierOptions.length > 1 && !this.loginPhoneForm.tenantId) {
            return
          }
          if (valid) {
            try {
              this.loading = true
              await this.$store.dispatch('user/phoneLogin', this.loginPhoneForm)
              // 登录完成
              this.supplierOptions = []
              this.$router.push({
                path: this.redirect
              })
            } catch (error) {
              console.log(error)
            } finally {
              this.loading = false
            }
          } else {
            return false
          }
        })
      }
    }
  }
}
