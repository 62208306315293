var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.sidebar.opened,
            expression: "!sidebar.opened"
          }
        ],
        staticClass: "hamburger-container",
        attrs: { "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar }
      }),
      _vm._v(" "),
      _c("breadcrumb", { staticClass: "breadcrumb-container" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          [
            _c(
              "div",
              {
                staticClass: "international right-menu-item",
                on: {
                  click: function($event) {
                    return _vm.$router.push("/")
                  }
                }
              },
              [
                _c("svg-icon", {
                  attrs: { "class-name": "icon", "icon-class": "home" }
                }),
                _vm._v("首页\n      ")
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "international right-menu-item",
                on: { click: _vm.toBrochure }
              },
              [
                _c("svg-icon", {
                  staticStyle: { color: "#666" },
                  attrs: { "class-name": "icon", "icon-class": "education" }
                }),
                _vm._v("操作手册\n      ")
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "permission",
                    rawName: "v-permission",
                    value: ["/message"],
                    expression: "['/message']"
                  }
                ],
                staticClass: "international right-menu-item",
                on: {
                  click: function($event) {
                    return _vm.$router.push("/message")
                  }
                }
              },
              [
                _c(
                  "el-badge",
                  {
                    staticClass: "hover-effect",
                    attrs: { value: _vm.msgCount, max: 99 }
                  },
                  [
                    _c("svg-icon", {
                      attrs: {
                        "class-name": "icon",
                        "icon-class": "msg_plain_black"
                      }
                    }),
                    _vm._v(
                      _vm._s(_vm.isAdmin ? "消息" : "客户消息") + "\n        "
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "international right-menu-item" },
              [
                _c("svg-icon", {
                  attrs: { "class-name": "icon", "icon-class": "QRcode_black" }
                }),
                _vm._v("\n        小程序\n        "),
                _vm._m(0)
              ],
              1
            )
          ],
          _vm._v(" "),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item",
              attrs: { trigger: "click" }
            },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _vm.profile.avatar
                  ? _c("img", {
                      staticClass: "user-avatar",
                      attrs: { src: _vm.profile.avatar }
                    })
                  : _c("img", {
                      staticClass: "user-avatar",
                      attrs: { src: require("@/assets/img/avatar.gif") }
                    }),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.profile.phone))]),
                _vm._v(" "),
                _c("i", { staticClass: "el-icon-arrow-down" })
              ]),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/account/profile" } },
                    [
                      _c("el-dropdown-item", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("navbar.profile")) +
                            "\n          "
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    { attrs: { to: "/account/updatePwd" } },
                    [
                      _c("el-dropdown-item", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("navbar.updatePwd")) +
                            "\n          "
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-dropdown-item", [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.reload }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("navbar.reload")) +
                            "\n          "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("el-dropdown-item", { attrs: { divided: "" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.logout }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("navbar.logOut")) +
                            "\n          "
                        )
                      ]
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "applets" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/applets.jpg"),
          alt: "微信小程序"
        }
      }),
      _vm._v(" "),
      _c("span", [_vm._v("您也可以扫描二维码")]),
      _vm._v(" "),
      _c("span", [_vm._v("在手机上查看店铺")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }