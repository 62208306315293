import request from '@/utils/request'

export function getPage(params) {
  return request({
    url: '/maint/bank',
    method: 'GET',
    params
  })
}

/**
 * 保存
 * @param {Object} data
 * @returns
 */
export function addBank(data) {
  return request({
    url: '/maint/bank',
    method: 'POST',
    data
  })
}

/**
 * 修改
 * @param {Object} data
 * @returns
 */
export function editBank(id, data) {
  return request({
    url: `/maint/bank/${id}`,
    method: 'PUT',
    data
  })
}

/**
 * 删除
 * @param {Number} id
 * @returns
 */
export function deleteBank(id) {
  return request({
    url: '/maint/bank/' + id,
    method: 'DELETE'
  })
}

/**
 * 获取详情
 * @param {Number} id
 * @returns
 */
export function getDetail(id) {
  return request({
    url: '/maint/bank/' + id,
    method: 'GET'
  })
}

/**
 * 商户获取可用银行账户
 * @returns
 */
export function getEnable() {
  return request({
    url: '/maint/bank/enable',
    method: 'GET'
  })
}

/**
 * 冻结|解冻
 * @param {Number} id
 * @returns
 */
export function updateBank(id) {
  return request({
    url: `/maint/bank/status/${id}`,
    method: 'PUT'
  })
}

/**
 * 获取可用的银行账户列表
 * @returns
 */
export function getEnableBankList() {
  return request({
    url: '/maint/bank/enable/list',
    method: 'GET'
  })
}
