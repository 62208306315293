var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "login-container",
      staticStyle: { "background-image": "url('/img/bg.webp')" }
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "container" }, [
        _c(
          "section",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showLogin,
                expression: "showLogin"
              }
            ],
            key: "login",
            staticClass: "content"
          },
          [
            _c("div", { staticClass: "title-container" }),
            _vm._v(" "),
            _c(
              "h3",
              { staticClass: "register-title" },
              [
                _vm._l(_vm.tabList, function(item, index) {
                  return _c(
                    "span",
                    {
                      key: index,
                      class: { active: index === _vm.selectTab },
                      on: {
                        click: function($event) {
                          return _vm.changeLoginType(index)
                        }
                      }
                    },
                    [_vm._v(_vm._s(item.label))]
                  )
                }),
                _vm._v(" "),
                _c("i", { staticClass: "line" })
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "el-form",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.selectTab === 0,
                    expression: "selectTab === 0"
                  }
                ],
                key: "loginFormRef",
                ref: "loginFormRef",
                staticClass: "login-form",
                attrs: {
                  model: _vm.loginForm,
                  rules: _vm.loginRules,
                  "label-position": "left"
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { prop: "phone" } },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: {
                          maxlength: "11",
                          "auto-complete": "on",
                          placeholder: _vm.$t("login.phone"),
                          name: "phone",
                          type: "text"
                        },
                        on: {
                          input: function($event) {
                            return _vm.searchSupplier(_vm.loginForm.phone)
                          }
                        },
                        model: {
                          value: _vm.loginForm.phone,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.loginForm,
                              "phone",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "loginForm.phone"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "svg-container",
                            attrs: { slot: "prefix" },
                            slot: "prefix"
                          },
                          [_c("i", { staticClass: "el-icon-mobile-phone" })]
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.isHasSupplierList
                  ? _c(
                      "el-form-item",
                      { attrs: { prop: "tenantId" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: "选择账号" },
                            model: {
                              value: _vm.loginForm.tenantId,
                              callback: function($$v) {
                                _vm.$set(_vm.loginForm, "tenantId", $$v)
                              },
                              expression: "loginForm.tenantId"
                            }
                          },
                          [
                            _vm._l(_vm.supplierOptions, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.shopName,
                                  value: item.tenantId
                                }
                              })
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "svg-container",
                                attrs: { slot: "prefix" },
                                slot: "prefix"
                              },
                              [_c("i", { staticClass: "el-icon-s-home" })]
                            )
                          ],
                          2
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { prop: "password" } },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: {
                          "show-password": "",
                          placeholder: _vm.$t("login.password"),
                          name: "password"
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleLogin("pwd")
                          }
                        },
                        model: {
                          value: _vm.loginForm.password,
                          callback: function($$v) {
                            _vm.$set(_vm.loginForm, "password", $$v)
                          },
                          expression: "loginForm.password"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "svg-container",
                            attrs: { slot: "prefix" },
                            slot: "prefix"
                          },
                          [_c("i", { staticClass: "el-icon-lock" })]
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "remembered" },
                  [
                    _c("el-col", { attrs: { span: 24 } }, [
                      _c(
                        "p",
                        { staticClass: "fl", attrs: { span: 10 } },
                        [
                          _c(
                            "el-checkbox",
                            {
                              model: {
                                value: _vm.loginForm.isRemembered,
                                callback: function($$v) {
                                  _vm.$set(_vm.loginForm, "isRemembered", $$v)
                                },
                                expression: "loginForm.isRemembered"
                              }
                            },
                            [
                              _vm._v(
                                "\n                记住账号\n              "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticClass: "footer-button",
                    attrs: { loading: _vm.loading, type: "primary" },
                    nativeOn: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.handleLogin("pwd")
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("login.logIn")))]
                ),
                _vm._v(" "),
                _c("p", { staticClass: "fr" }, [
                  _vm._v("\n          还没有账号？去\n          "),
                  _c(
                    "span",
                    {
                      staticClass: "blue-text",
                      attrs: { title: "$t('login.register')" },
                      on: {
                        click: function($event) {
                          return _vm.openRegister()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("login.register")))]
                  )
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.selectTab !== 0,
                    expression: "selectTab !== 0"
                  }
                ],
                key: "loginPhoneFormRef",
                ref: "loginPhoneFormRef",
                staticClass: "login-form",
                attrs: {
                  model: _vm.loginPhoneForm,
                  "label-width": "0",
                  rules: _vm.loginPhoneRules
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { prop: "phone" } },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: {
                          maxlength: "11",
                          "auto-complete": "on",
                          placeholder: "请输入手机号码"
                        },
                        on: {
                          input: function($event) {
                            return _vm.searchSupplier(_vm.loginPhoneForm.phone)
                          }
                        },
                        model: {
                          value: _vm.loginPhoneForm.phone,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.loginPhoneForm,
                              "phone",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "loginPhoneForm.phone"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "svg-container",
                            attrs: { slot: "prefix" },
                            slot: "prefix"
                          },
                          [_c("i", { staticClass: "el-icon-mobile-phone" })]
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.isHasSupplierList
                  ? _c(
                      "el-form-item",
                      { attrs: { prop: "tenantId" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "选择账号" },
                            model: {
                              value: _vm.loginPhoneForm.tenantId,
                              callback: function($$v) {
                                _vm.$set(_vm.loginPhoneForm, "tenantId", $$v)
                              },
                              expression: "loginPhoneForm.tenantId"
                            }
                          },
                          [
                            _vm._l(_vm.supplierOptions, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.shopName,
                                  value: item.tenantId
                                }
                              })
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "svg-container",
                                attrs: { slot: "prefix" },
                                slot: "prefix"
                              },
                              [_c("i", { staticClass: "el-icon-s-home" })]
                            )
                          ],
                          2
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { prop: "smsValidateCode" } },
                  [
                    _c(
                      "el-input",
                      {
                        staticClass: "auth-code",
                        attrs: { maxlength: "6", placeholder: "请输入验证码" },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.getPhoneCode("login")
                          }
                        },
                        model: {
                          value: _vm.loginPhoneForm.smsValidateCode,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.loginPhoneForm,
                              "smsValidateCode",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "loginPhoneForm.smsValidateCode"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "svg-container",
                            attrs: { slot: "prefix" },
                            slot: "prefix"
                          },
                          [_c("i", { staticClass: "el-icon-chat-line-round" })]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "buttonPosition fr",
                        attrs: { disabled: _vm.smsFlag },
                        on: {
                          click: function($event) {
                            return _vm.getPhoneCode("login")
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.verificationCodeText) +
                            "\n          "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticClass: "footer-button",
                    attrs: { loading: _vm.loading, type: "primary" },
                    nativeOn: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.handleLogin($event)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("login.logIn")))]
                ),
                _vm._v(" "),
                _c("p", { staticClass: "fr" }, [
                  _vm._v("\n          还没有账号？去\n          "),
                  _c(
                    "span",
                    {
                      staticClass: "blue-text",
                      attrs: { title: "$t('login.register')" },
                      on: {
                        click: function($event) {
                          return _vm.openRegister()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("login.register")))]
                  )
                ])
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "section",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.showLogin,
                expression: "!showLogin"
              }
            ]
          },
          [
            _vm._m(1),
            _vm._v(" "),
            _c(
              "el-form",
              {
                ref: "registerFormRef",
                attrs: {
                  model: _vm.registerForm,
                  "label-width": "0",
                  rules: _vm.registerRules
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { prop: "phone" } },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: {
                          maxlength: "11",
                          placeholder: "请输入手机号码"
                        },
                        model: {
                          value: _vm.registerForm.phone,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.registerForm,
                              "phone",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "registerForm.phone"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "svg-container",
                            attrs: { slot: "prefix" },
                            slot: "prefix"
                          },
                          [_c("i", { staticClass: "el-icon-mobile-phone" })]
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { prop: "smsValidateCode" } },
                  [
                    _c(
                      "el-input",
                      {
                        staticClass: "auth-code",
                        attrs: { maxlength: "6", placeholder: "请输入验证码" },
                        model: {
                          value: _vm.registerForm.smsValidateCode,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.registerForm,
                              "smsValidateCode",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "registerForm.smsValidateCode"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "svg-container",
                            attrs: { slot: "prefix" },
                            slot: "prefix"
                          },
                          [_c("i", { staticClass: "el-icon-chat-line-round" })]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "buttonPosition fr",
                        attrs: { disabled: _vm.smsFlag },
                        on: {
                          click: function($event) {
                            return _vm.getPhoneCode("register")
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.verificationCodeText) +
                            "\n          "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { prop: "password" } },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: {
                          placeholder: "请设置6-16位字母数字组合密码",
                          "show-password": "",
                          autocomplete: "off"
                        },
                        model: {
                          value: _vm.registerForm.password,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.registerForm,
                              "password",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "registerForm.password"
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "svg-container",
                            attrs: { slot: "prefix" },
                            slot: "prefix"
                          },
                          [_c("i", { staticClass: "el-icon-lock" })]
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-checkbox",
                          {
                            model: {
                              value: _vm.isAgree,
                              callback: function($$v) {
                                _vm.isAgree = $$v
                              },
                              expression: "isAgree"
                            }
                          },
                          [_vm._v("\n              已阅读且同意\n            ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "blue-text",
                            attrs: { title: "《资信办平台注册协议》" },
                            on: {
                              click: function($event) {
                                _vm.agreeDialog = true
                              }
                            }
                          },
                          [_vm._v("《资信办平台注册协议》")]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "footer-button",
                            attrs: { loading: _vm.loading, type: "primary" },
                            nativeOn: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.submitRegister($event)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("login.register")))]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("p", { staticClass: "fr" }, [
                      _vm._v("\n            已有账号？去\n            "),
                      _c(
                        "span",
                        {
                          staticClass: "blue-text",
                          attrs: { title: "$t('login.logIn')" },
                          on: {
                            click: function($event) {
                              return _vm.openLogin()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("login.logIn")))]
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "《资信办平台服务协议》",
            visible: _vm.agreeDialog,
            width: "1200px",
            "append-to-body": true
          },
          on: {
            "update:visible": function($event) {
              _vm.agreeDialog = $event
            }
          }
        },
        [
          _c("User-agreement"),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: {
                    width: "230px",
                    display: "block",
                    margin: "0 auto"
                  },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.toAgree()
                    }
                  }
                },
                [_vm._v("同意并继续")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "login-header" }, [
      _c("h1", { staticClass: "logo" }, [
        _c("img", { attrs: { src: require("@/assets/img/logo.png"), alt: "" } })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", { staticClass: "register-title" }, [
      _c("p", { staticClass: "text" }, [_vm._v("欢迎注册资信办")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }