import request from '@/utils/request'

export function login(data) {
  return request({
    url: '/identity/session',
    method: 'post',
    data
  })
}

export function getInfo() {
  return request({
    url: '/identity/session',
    method: 'GET'
  })
}

export function logout(token) {
  return request({
    url: '/identity/session',
    method: 'DELETE'
  })
}

export function updatePwd(params) {
  return request({
    url: '/identity/session/password',
    method: 'put',
    data: params
  })
}

export function phoneLogin(data) {
  return request({
    url: '/identity/session/sms',
    method: 'POST',
    data
  })
}

/**
 * 获取验证码
 * @param {*} param0
 * @returns
 */
export function getPhoneCode({ sendType, data }) {
  return request({
    url: `/identity/session/send/sms/${sendType}`,
    method: 'POST',
    data
  })
}

export function getCodeNotCaptcha({ sendType, data }) {
  return request({
    url: `/maint/sms/send/${sendType}`,
    method: 'POST',
    data
  })
}

export function register(data) {
  return request({
    url: `/identity/session/register`,
    method: 'POST',
    data
  })
}

export function opslogs(data) {
  return request({
    url: `/maint/opslogs`,
    method: 'GET',
    params: data
  })
}
