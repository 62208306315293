import request from '@/utils/request'

export function getList(params) {
  return request({
    url: '/maint/cfgs',
    method: 'GET',
    params
  })
}

export function exportXls(params) {
  return request({
    url: '/maint/cfgs/execl',
    method: 'GET',
    params
  })
}

export function save(params) {
  let methodName = 'post'
  let url = '/maint/cfgs'
  if (params.id > 0) {
    methodName = 'put'
    url = url + '/' + params.id
  }
  return request({
    url: url,
    method: methodName,
    data: params
  })
}

export function remove(id) {
  return request({
    url: `/maint/cfgs/${id}`,
    method: 'DELETE'
  })
}

export function getBaseData(code) {
  return request({
    url: '/maint/cfgs/' + code,
    method: 'GET'
  })
}

/**
   * 获取核心优势
   */
export function getAdvantages() {
  return request({
    url: '/maint/cfgs/Advantage',
    method: 'GET'
  })
}

/**
 * 获取首页banner
 * @returns
 */
export function getHomeBanner() {
  return request({
    url: '/maint/cfgs/ShopHomeBanner',
    method: 'GET'
  })
}

/**
 * 精线索所属行业类型
 * @returns
 */
export function getCompanyIndustry() {
  return request({
    url: '/maint/cfgs/Niche_Company_Industry',
    method: 'GET'
  })
}

export function clearCache() {
  return request({
    url: '/maint/cfgs/clear/cache',
    method: 'DELETE'
  })
}

/**
 * 获取活动时间
 * @returns
 */
export function getActivityTime() {
  return request({
    url: '/maint/cfgs/activityTime',
    method: 'GET'
  })
}

/**
 * 客户行业
 * @returns
 */
export function getCrmIndustry() {
  return request({
    url: '/maint/cfgs/crm_industry',
    method: 'GET'
  })
}

/**
 * 意向产品
 * @returns
 */
export function getCrmProduct() {
  return request({
    url: '/maint/cfgs/crm_product',
    method: 'GET'
  })
}

/**
 * 客户等级
 * @returns
 */
export function getCrmLevel() {
  return request({
    url: '/maint/cfgs/crm_level',
    method: 'GET'
  })
}

/**
 * 素材内容分类
 * @returns
 */
export function getContentTypes() {
  return request({
    url: '/maint/cfgs/material_content_type',
    method: 'GET'
  })
}

/**
 * 素材产品分类
 * @returns
 */
export function getProductTypes() {
  return request({
    url: '/maint/cfgs/material_product_type',
    method: 'GET'
  })
}

