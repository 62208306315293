import { Message } from 'element-ui'

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * 校验用户名,必须是5-32位
 * @param {string} str
 * @returns {Boolean}
 */
export function isvalidUsername(str) {
  var reg = /^[0-9A-Za-z]{5,32}$/
  return reg.test(str)
}

/**
 * 社会信用代码
 * @param {*} str
 * @returns
 */
export function validSocialCode(str) {
  // return /^[0-9A-Z]{18}/.test(str)
  // if (str.length < 15) {
  //   return 0
  // }
  const reg = /[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{7}/g
  return reg.test(str)
}

/**
 * 税号
 * @param {*} str
 * @returns
 */
export function validTaxNumber(str) {
  const taxNumberChinaRegex = /^[A-Za-z0-9]{15,20}$/
  return taxNumberChinaRegex.test(str)
}

/**
 * 身份证校验
 * @param {*} str
 * @returns
 */
export function validIdCode(str) {
  const reg = /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$)/
  return reg.test(str)
}

/**
 * 密码校验
 * @param {*} str
 * @returns
 */
export function validPassword(str) {
  return /^[0-9A-Za-z]{6,16}$/.test(str)
}

/**
 *
 * @param {*} str
 * @returns
 */
export function validPwd(str) {
  return /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/.test(str)
}

/**
 * 手机号校验
 * @param {string} str
 * @returns {Boolean}
 */
export function validPhonenumber(str) {
  return /^1[3456789]\d{9}$/.test(str)
}

/**
 * 手机及电话号码校验
 * @param {*} str
 * @returns
 */
export function validTelAndMobilenumber(str) {
  return (
    /^([0-9]{3,4}-)?[0-9]{7,8}$/.test(str) ||
        /^((\+?86)|(\(\+86\)))?(13[012356789][0-9]{8}|15[012356789][0-9]{8}|18[02356789][0-9]{8}|147[0-9]{8}|1349[0-9]{7})$$/.test(
          str
        )
  )
}

/**
 * 常用
 * @param {*} str
 * @returns
 */
export function validTelAndMobile(str) {
  return (
    /^([0-9]{3,4}-)?[0-9]{7,8}$/.test(str) ||
        /^((\+?86)|(\(\+86\)))?(1[3456789]\d{9})$$/.test(str)
  )
}

/**
 * url地址校验
 * @param {string} url
 * @returns {Boolean}
 */
export function validURL(url) {
  const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
  return reg.test(url)
}

export function validurl(url) {
  const reg = /(https?|ftp|file):\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/
  return reg.test(url)
}

/**
 * 小写校验
 * @param {string} str
 * @returns {Boolean}
 */
export function validLowerCase(str) {
  const reg = /^[a-z]+$/
  return reg.test(str)
}

/**
 * 大写校验
 * @param {string} str
 * @returns {Boolean}
 */
export function validUpperCase(str) {
  const reg = /^[A-Z]+$/
  return reg.test(str)
}

/**
 * 字符校验
 * @param {string} str
 * @returns {Boolean}
 */
export function validAlphabets(str) {
  const reg = /^[A-Za-z]+$/
  return reg.test(str)
}

/**
 * 邮箱校验
 * @param {string} email
 * @returns {Boolean}
 */
export function validEmail(email) {
  // eslint-disable-next-line no-useless-escape
  const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return reg.test(email)
}

/**
 * 字符串校验
 * @param {string} str
 * @returns {Boolean}
 */
export function isString(str) {
  if (typeof str === 'string' || str instanceof String) {
    return true
  }
  return false
}

/**
 * 数组校验
 * @param {Array} arg
 * @returns {Boolean}
 */
export function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]'
  }
  return Array.isArray(arg)
}

/**
 * 数字校验
 * @param {*} str
 * @returns
 */
export function isNumber(str) {
  // const reg = /^[0-9]+.?[0-9]*/
  const reg = /^[0-9]+\.{0,1}[0-9]{0,3}$/
  return reg.test(str)
}

/**
 * 整数校验
 * @param {Number} str
 * @returns
 */
export function isRealInteger(str) {
  const reg = /^[0-9]+$/
  return reg.test(str)
}

// 验证是否整数+0
export function isInteger(value) {
  const re = /^(0|\+?[1-9][0-9]*)$/
  return re.test(value)
  // if (!rsCheck) {
  //   callback(new Error('请输入正整数或0'))
  // } else {
  //   callback()
  // }
}

/**
 * 金额校验 保留两位小数
 * @param {Number} str
 * @returns
 */
export function validMoney(str) {
  const reg = /^(([1-9]\d*)|([0-9]\d*\.\d?[1-9]{1}))$/
  return reg.test(str)
}

/**
 * 金额校验 保留两位小数 且可为0
 * @param {Number} str
 * @returns
 */
export function validZeroMoney(str) {
  const reg = /^(([0-9]\d*)|([0-9]\d*\.\d?[1-9]{1}))$/
  return reg.test(str)
}

/**
 * 校验金额整数
 */
export function validateNumber(str) {
  const reg = /^(0|\+?[1-9][0-9]*)$/
  return reg.test(str)
}

export function validateMoney(str) {
  const reg = /^([1-9]{1}[0-9]{0,3}(\,[0-9]{3,4})*(\.[0-9]{0,2})?|[1-9]{1}\d*(\.[0-9]{0,2})?|0(\.[0-9]{0,2})?|(\.[0-9]{1,2})?)$/
  return reg.test(str)
}

/**
 * 校验个人银行卡
 * @param {String} str
 * @returns
 */
export function validCard(str) {
  const reg = /^([1-9]{1})(\d{15}|\d{17}|\d{18})$/
  return reg.test(str)
}

/**
 * 校验对公银行卡
 * @param {*} str
 * @returns
 */
export function validCompanyCard(str) {
  const reg = /^[0-9]\d{7,29}$/
  return reg.test(str)
}

/**
 * 匹配快递信息
 * @param {*} str
 * @returns
 */
export function validExpress(str) {
  const reg = /^[A-Za-z0-9]{4,40}$/
  return reg.test(str)
}

/**
 * 图像大小及格式校验
 */
export function isAccordantImage(file, size = 5) {
  let isJPG = false
  switch (file.type) {
    case 'image/png':
      isJPG = true
      break
    case 'image/jpeg':
      isJPG = true
      break
    default:
      isJPG = false
      break
  }
  const isLimit = file.size / 1024 / 1024 < size
  if (!isJPG) {
    Message.error('上传图片仅支持PNG、JPG 格式!')
    return false
  }
  if (!isLimit) {
    Message.error(`上传图片大小不能超过 ${size} MB!`)
    return false
  }
  return isJPG && isLimit
}

export function isFile(file, size = 100) {
  let isFile = false
  const name = file.name
  if (name.endsWith('.rar') || name.endsWith('.zip') || name.endsWith('.pdf') || name.endsWith('.jpg') || name.endsWith('.doc') || name.endsWith('.docx')) {
    isFile = true
  } else {
    isFile = false
  }

  const isLimit = file.size / 1024 / 1024 < size

  if (!isFile) {
    Message.error('上传文件仅支持ZIP、RAR、7Z、DOC、DOCX 格式!')
    return false
  }
  if (!isLimit) {
    Message.error(`上传文件大小不能超过 ${size} MB!`)
    return false
  }
  return isFile && isLimit
}

export function isInvoiceFile(file, size = 5,) {
  let isFile = false
  const name = file.name
  if (name.endsWith('.png') || name.endsWith('.pdf') || name.endsWith('.jpg')) {
    isFile = true
  } else {
    isFile = false
  }

  const isLimit = file.size / 1024 / 1024 < size

  if (!isFile) {
    Message.error('上传文件仅支持PNG、JPG、PDF格式!')
    return false
  }
  if (!isLimit) {
    Message.error(`上传文件大小不能超过 ${size} MB!`)
    return false
  }
  return isFile && isLimit
}
