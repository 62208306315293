
export default class ChatWebSocket {
  constructor(url) {
    this.url = url
  }

  ws = null

  onOpen() {
    console.log('open')
    this.ws = new WebSocket(this.url)
    this.ws.onopen = () => {
      console.log('状态打开...')
    }
    this.ws.onmessage = (res) => {
      console.log('onmessage...')
    }
    this.ws.onclose = () => {
      console.log('状态关闭...')
    }
  }

  onMessage(cb) {
    this.ws.onmessage = (res) => {
      cb && cb(res)
    }
  }

  onClose() {
    this.ws.close()
  }
}
