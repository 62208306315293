<template>
  <div class="app-container">

    <div class="app-content">

      <!-- 顶部信息 -->
      <div class="top">
        <div class="avatar">
          <el-avatar :size="92" :src="profile.avatar" @error="!!profile.avatar">
            <img src="@/assets/images/shop/user.png">
          </el-avatar>
        </div>

        <div class="user">
          <p class="name">{{ profile.shopName }}</p>
          <p class="mobile">

            <span><i class="el-icon-mobile" /> {{ profile.phone }}</span>
            <img v-if="vipData.vipStatus !== vipStatus.none" :src="vipImg">
            <img v-if="channel.status !== vipStatus.none&&channel.code&&channel.code!=='CS001'" :src="require(`@/assets/images/channel/${channel.code}${channel.status===vipStatus.expire?'_expire':''}.png`)">
          </p>
          <p v-if="vipData.vipStatus !== vipStatus.none||channel.status !== vipStatus.none" class="record" @click="showLogs">
            购买记录<i class="el-icon-arrow-right" />
          </p>
        </div>

        <div v-if="vipData.vipStatus !== vipStatus.none" class="vip">
          <template v-if="vipData.vipStatus === vipStatus.normal">
            <p class="img">
              <img :src="vipLogo" alt="会员logo">
            </p>
            <p>有效期至：{{ vipData.memberData.expireOn }}</p>
          </template>
          <template v-if="vipData.vipStatus === vipStatus.expire">
            <div class="expire-log">
              <img :src="vipLogo" alt="过期会员">
            </div>
          </template>
        </div>

        <!-- <div class="checking-in" @click="openChecking">
          {{ signData.signDay?`已签${signData.signDay}天`:'领资信币' }}
        </div> -->
      </div>

      <!-- 中部信息 -->
      <div class="middle">
        <div class="left">
          <Banner />
        </div>

        <div class="right">
          <section>
            <p class="part-one" @click="goToPage('/owner_coin')">
              <span>我的资信币</span>
              <i class="icon el-icon-arrow-right" />
            </p>
            <i class="line" />
            <div class="part-two">
              <p class="icon">
                <img src="@/assets/images/shop/coin.png" alt="">
              </p>
              <p class="content">
                <span>{{ coinTotal }}</span>
                <span>资信币总余额</span>
              </p>
              <p class="btn" @click="goToPage('/finance/purchasecoin')">立即充值</p>
            </div>
          </section>
          <section>
            <p class="part-one" @click="goToPage('/owner/capital')">
              <span>我的资金</span>
              <i class="icon el-icon-arrow-right" />
            </p>
            <i class="line" />
            <div class="part-two">
              <p class="icon">
                <img src="@/assets/images/shop/wallet.png" alt="">
              </p>
              <p class="content">
                <span>{{ balanceData.total }}</span>
                <span>总余额</span>
              </p>
            </div>
          </section>
        </div>
      </div>

      <div class="footer">
        <!-- 底部 -->
        <div class="bottom">
          <p class="title">快捷操作</p>
          <i class="line" />
          <!-- 快捷操作 -->
          <div class="content">
            <div
              v-for="item in shortcutItems"
              :key="item.name"
              @click="goToPage(item.path)"
            >
              <img :src="item.src" alt="快捷操作">
              <span>{{ item.name }}</span>
            </div>
          </div>
        </div>

        <div class="vip">
          <p class="part-one" @click="goPage()">
            <span>会员特权-免费商机</span>
            <i class="icon el-icon-arrow-right" />
          </p>
          <i class="line" />
          <div class="over">
            <div class="count">
              <p class="total">
                <span>{{ vipData.vipStatus === vipStatus.none?0:vipData.memberData.total }}</span>次
              </p>
              <p class="down">账户结余</p>
            </div>
            <div class="count">
              <p class="total">
                <span>{{ vipData.vipStatus === vipStatus.none?0:vipData.memberData.expedTotal }}</span>次
              </p>
              <p class="down">累计使用</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="recommend">
      <!-- 推荐商机 -->
      <p class="part-one" @click="goToPage('/niche/store')">
        <span>最新商机</span>
        <i class="icon el-icon-arrow-right" />
      </p>
      <i class="line" />
      <Recommend-niche />
    </div>

    <!-- 遮罩层 -->
    <!-- <div v-show="showGuide" id="guide">
      <div id="mask" />
      <div id="step-tip">
        <div id="content">
          <img src="@/assets/images/shop/acc.png" alt="">
          <button id="btn" @click="showGuide = false">我知道了</button>
        </div>
      </div>
    </div> -->

    <Zxb-drawer title="购买记录" :visible.sync="logsVisible" @closed="closeLogs">
      <el-tabs v-model="activeName" @tab-click="handleClickTab">
        <el-tab-pane v-if="vipData.vipStatus !== vipStatus.none" key="vip" label="会员" name="1">
          <el-table v-loading="tableLoading" :data="logs.data" empty-text header-row-class-name="table-header">
            <el-table-column prop="payTimeTxt" label="支付时间" align="center" />
            <el-table-column label="套餐" align="center">
              <template #default="scope">
                【{{ scope.row.vipName }}】
              </template>
            </el-table-column>
            <el-table-column prop="finalPrice" label="金额 (元)" align="center">
              <div slot-scope="scope" class="finalPrice">
                {{ scope.row.finalPrice }}
              </div>
            </el-table-column>
            <el-table-column prop="payType" label="支付方式" align="center" />
            <el-table-column prop="expireOnTxt" label="到期时间" align="center" />
          </el-table>
          <br>
          <Pagination
            v-if="logs.totalCount > 10"
            background
            layout="prev, pager, next ,total"
            :limit.sync="queryInfo.pageSize"
            :page.sync="queryInfo.pageIndex"
            :total="logs.totalCount - 0"
            @pagination="getLogs()"
          />
        </el-tab-pane>
        <el-tab-pane v-if="channel.status !== vipStatus.none" key="channel" label="渠道" name="2">
          <el-table v-loading="tableLoading" :data="logs.data" empty-text header-row-class-name="table-header">
            <el-table-column prop="payTimeTxt" label="支付时间" align="center" />
            <el-table-column label="套餐" align="center" width="80">
              <template #default="scope">
                【{{ scope.row.name }}】
              </template>
            </el-table-column>
            <el-table-column prop="finalPrice" label="金额 (元)" align="center">
              <div slot-scope="scope" class="finalPrice">
                {{ scope.row.finalPrice }}
              </div>
            </el-table-column>
            <el-table-column prop="payType" label="支付方式" align="center" width="80" />
            <el-table-column prop="expireOnTxt" label="到期时间" align="center" />
            <el-table-column label="操作" align="center" width="100">
              <template #default="{row}">
                <el-button type="text" size="mini" @click="showAgreement(row)">查看合同</el-button>
              </template>
            </el-table-column>
          </el-table>
          <br>
          <Pagination
            v-if="logs.totalCount > 10"
            background
            layout="prev, pager, next ,total"
            :limit.sync="queryInfo.pageSize"
            :page.sync="queryInfo.pageIndex"
            :total="logs.totalCount - 0"
            @pagination="getLogs()"
          />
        </el-tab-pane>
      </el-tabs>
    </Zxb-drawer>

    <el-dialog title="《合作协议》" center :visible.sync="agreeDialog" width="1200px" :append-to-body="true">
      <Channel :data="currentCombo" :date="date" record />
    </el-dialog>

    <!-- <el-dialog :close-on-click-modal="false" custom-class="calendar-container" center :visible.sync="showChecking" width="759px" :append-to-body="true">
      <Checking-in :data="signData" @change="getSign" />
    </el-dialog> -->
  </div>
</template>

<script>
// import CheckingIn from './CheckingIn.vue'
import RecommendNiche from './RecommendNiche.vue'
import Banner from './Banner'
import Channel from '@/components/Agreement/Channel'
import { getShopCoin, getShopVip, getShopVipLogs } from '@/api/shop/shop'
import { getBalanceData, getShopProfile } from '@/api/shop/capital'
// import { getSign, saveSign } from '@/api/activity/sign.js'
import enums from '@/api/enum'
import Cookies from 'js-cookie'
import { getChannelLogs, getChannelLogsDetail } from '@/api/shop/channel'
import { mapGetters } from 'vuex'
export default {
  name: 'UserDashboard',
  components: { Banner, Channel, RecommendNiche },
  data() {
    return {
      shortcutItems: [
        {
          name: '商机中心',
          src: require('@/assets/images/shop/store.png'),
          path: '/niche/store'
        },
        {
          name: '精线索',
          src: require('@/assets/images/shop/acc.png'),
          path: '/niche/company'
        },
        {
          name: '在线申报',
          src: require('@/assets/images/shop/goods.png'),
          path: '/declare/apply'
        },
        {
          name: '我的订单',
          src: require('@/assets/images/shop/order.png'),
          path: '/owner/order'
        }
        // {
        //   name: '商铺介绍',
        //   src: require('@/assets/images/shop/information.png'),
        //   path: ''
        // }
      ],

      signData: {},

      queryInfo: {
        pageSize: 10,
        pageIndex: 1
      },

      agreeDialog: false,
      currentCombo: {},
      date: {},

      vipImg: '',
      vipLogo: '',
      loading: true,
      tableLoading: false,
      showGuide: false,
      logsVisible: false,
      showChecking: false,
      coinTotal: 0,
      vipData: {
        memberData: { expireOn: '' }
      },

      profileData: {},
      balanceData: {
        total: 0
      },

      logs: {},

      channel: {},

      activeName: '1',

      vipType: enums.vipType,
      vipStatus: enums.vipStatus,
      adapay_status: enums.adapay_status
    }
  },
  computed: {
    ...mapGetters(['profile', 'permissions']),

    channelImgs() {
      return [

      ]
    }
  },
  mounted() {
    this.initGuide()
    this.fetchData()
    this.getChannelInfor()
    this.getSign()
  },
  methods: {

    // 获取签到信息 初始化获取一次签到信息，供页面使用
    async getSign(count = null, show = false) {
      // try {
      //   const res = await getSign()
      //   res.count = count
      //   res.show = show
      //   this.signData = res
      // } catch (error) {
      //   console.log(error)
      // }
    },

    // 打开签到
    // async openChecking() {
    //   this.showChecking = true
    //   try {
    //     // 未签到情况下需要签到
    //     if (!this.signData.hasSign) {
    //       const count = await saveSign()
    //       this.getSign(count, true)
    //     }
    //   } catch (error) {
    //     console.log(error)
    //   }
    // },

    async showAgreement(row) {
      try {
        const res = await getChannelLogsDetail(row.id)
        this.currentCombo = res

        this.agreeDialog = true
      } catch (error) {
        console.log(error)
      }
    },

    // 获取日志信息
    async getLogs() {
      this.tableLoading = true
      try {
        const res = this.activeName === '1' ? await getShopVipLogs(this.queryInfo) : await getChannelLogs(this.queryInfo)
        this.tableLoading = false
        this.logs = res
      } catch (error) {
        console.log(error)
      }
    },

    handleClickTab() {
      this.queryInfo.pageIndex = 1
      this.queryInfo.pageSize = 10
      this.logs = []
      this.getLogs()
    },

    closeLogs() {
      this.logsVisible = false
      if (this.vipData.vipStatus === this.vipStatus.none) {
        this.activeName = '2'
      } else {
        this.activeName = '1'
      }
    },
    // 显示日志
    showLogs() {
      if (this.vipData.vipStatus === this.vipStatus.none) {
        this.activeName = '2'
      }
      this.getLogs()
      this.logsVisible = true
    },

    async getChannelInfor() {
      try {
        const res = await this.$store.dispatch('user/getChannelInfor')
        this.channel = res
      } catch (error) {
        console.log(error)
      }
    },

    goPage() {
      const router = this.$router.resolve('/vipinfo')
      window.open(router.href, '_blank')
    },

    goToHomePage(path) {
      window.open('http://zixinban.com' + path)
    },

    goToPage(path) {
      if (!path) return
      this.$store.dispatch('user/getUserInfo')
      if (this.profile.settleStatus !== enums.settledStatus.approved) { return this.$router.push({ path: '/apply/go' }) }
      if (!this.permissions.includes(path)) {
        return this.$message.warning('暂无权限，请联系管理员')
      }
      this.$router.push({ path })
    },

    async fetchData() {
      try {
        const coin = getShopCoin()
        const vip = getShopVip()
        const profile = getShopProfile()
        const [coinData, vipData, profileData] = await Promise.all([
          coin,
          vip,
          profile
        ])
        this.changeStyle(vipData)
        const num = coinData.giveCoins.reduce((acc, curr) => acc + curr.total, 0)
        this.coinTotal = num + coinData.total
        this.vipData = vipData
        this.profileData = profileData
        if (profileData.corMemberStatus > this.adapay_status.adapayReject) {
          this.getBalanceData()
        } else {
          this.balanceData.total = 0
        }
        this.$nextTick(() => {
          const total = document.querySelectorAll('.total')
          // 已过期
          if (this.vipData.vipStatus === enums.vipStatus.expire) {
            total.forEach((item) => {
              item.style.color = '#999999'
            })
          } else if (this.vipData.vipStatus === enums.vipStatus.normal) {
            total.forEach((item) => {
              item.style.color = '#0e62fe'
            })
          }
        })
      } catch (error) {
        console.log(error)
      }
    },

    // 获取资金信息
    async getBalanceData() {
      try {
        const res = await getBalanceData({
          startTime: new Date(),
          endTime: new Date(),
          status: -1,
          pageSize: 10,
          pageIndex: 1
        })
        this.balanceData = res
      } catch (error) {
        console.log(error)
      }
    },

    // 页面样式动态变化监听
    changeStyle(val) {
      const vipStatus = val.vipStatus
      // 普通会员不执行
      if (vipStatus === enums.vipStatus.none) {
        return
      }
      const vipCode = val?.memberData?.vipCode || ''
      // 正式会员显示状态
      if (vipStatus === enums.vipStatus.normal) {
        switch (vipCode) {
          case 'BC001':
            this.vipImg = require('@/assets/images/shop/copper-vip.png')
            this.vipLogo = require('@/assets/images/shop/copper-logo.png')
            break
          case 'SC001':
            this.vipImg = require('@/assets/images/shop/silver-vip.png')
            this.vipLogo = require('@/assets/images/shop/silver-logo.png')
            break
          case 'GC001':
            this.vipImg = require('@/assets/images/shop/gold-vip.png')
            this.vipLogo = require('@/assets/images/shop/gold-logo.png')
            break
          case 'DC001':
            this.vipImg = require('@/assets/images/shop/diamond-vip.png')
            this.vipLogo = require('@/assets/images/shop/diamond-logo.png')
            break
          default:
            break
        }
        // 过期会员
      } else if (vipStatus === enums.vipStatus.expire) {
        this.vipLogo = require('@/assets/images/shop/expire-logo.png')
        switch (vipCode) {
          case 'BC001':
            this.vipImg = require('@/assets/images/shop/copper-vip-expire.png')
            break
          case 'SC001':
            this.vipImg = require('@/assets/images/shop/silver-vip-expire.png')
            break
          case 'GC001':
            this.vipImg = require('@/assets/images/shop/gold-vip-expire.png')
            break
          case 'DC001':
            this.vipImg = require('@/assets/images/shop/diamond-vip-expire.png')
            break
          default:
            break
        }
      }
    },

    // 初始化引导信息
    initGuide() {
      const guide = 'ZXB_BUSINCENTER_GUIDE'
      this.showGuide = true

      if (!Cookies.get(guide)) {
        this.showGuide = true
      }
      // 设置有效期
      const expiresTime = new Date(
        new Date().getTime() + 10 * 365 * 24 * 60 * 60 * 1000
      )
      Cookies.set(guide, 1, { expires: expiresTime })
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "src/styles/common.scss";
@import "src/styles/table.scss";

/deep/.calendar-container{
  background: #F8F8F8;
  border-radius: 18px;

  .el-dialog__header{
    z-index: 1;
    position: sticky;
  }
    .el-dialog__header, .el-dialog__body{
      padding: 0;
    }

    .el-dialog__close{
      color: $white;
      font-size: 20px;
    }
}

.app-container {
  display    : flex;
  padding    : 0px;
  background : #eff2f5;
  font-family: PingFangSC-Regular, PingFang SC;

  p {
    margin: 0;
  }

  .top {
    display         : flex;
    position        : relative;
    // width        : 1320px;
    height          : 160px;
    padding         : 34px 0 0 36px;
    background      : #ffffff;
    box-shadow      : 14px 17px 29px 28px rgba(122, 175, 255, 0.03);
    border-radius   : 12px;

    .avatar {
      display        : flex;
      justify-content: center;
      align-items    : center;
      width          : 92px;
      height         : 92px;
      margin-right   : 20px;
      border-radius  : 50%;
      overflow       : hidden;

      .el-avatar {
        background-color: #fff;
      }

      img {
        width : 100%;
        height: 100%;
      }
    }

    .user {
      padding    : 0;
      font-weight: 400;
      font-size  : 12px;
      color      : #666666;

      .name {
        font-size  : 18px;
        line-height: 25px;
        font-weight: 500;
        color      : #333333;
      }

      .mobile {
        margin     : 8px 0 22px 0;
        font-size  : 14px;
        line-height: 20px;

        img {
          margin-left   : 5px;
          vertical-align: middle;
        }
      }

      .record {
        cursor: pointer;
      }
    }

    .vip {
      position   : absolute;
      top        : 0;
      right      : 200px;
      width      : 150px;
      height     : 100%;
      text-align : center;
      font-size  : 12px;
      font-weight: 400;
      color      : #999999;

      .expire-log {
        margin: 20px auto;
      }
    }

    .checking-in{
      position : absolute;
      top: 0;
      right: 30px;
      width: 113px;
      height: 108px;
      margin: 20px auto;
      padding-top: 70px;
      text-align: center;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      text-shadow: 0px 2px 13px rgba(159,118,0,0.15);
      color: $white;
      cursor: pointer;
      background:url('~@/assets/images/home/checking-entry.png') no-repeat;
    }
  }

  .middle {
    display     : flex;
    // min-width: 1320px;
    height      : 340px;
    margin      : 16px 0;

    .left {
      width           : 784px;
      min-width       : 784px;
      background-color: #fff;
      margin-right    : 16px;
      border-radius   : 12px;
      overflow        : hidden;
    }

    .right {
      display        : flex;
      width          : 100%;
      flex-direction : column;
      justify-content: space-between;

      section {
        width           : 100%;
        height          : 162px;
        background-color: #fff;
        border-radius   : 12px;

        .part-two {
          display : flex;
          position: relative;
          padding : 22px 24px;

          .icon {
            display        : flex;
            flex-direction : column;
            justify-content: center;
            align-items    : center;
            width          : 56px;
            height         : 56px;
            margin-right   : 17px;
            border-radius  : 50%;
            background     : #ebf2ff;
          }

          .content {
            span {
              display: block;

              &:first-of-type {
                display    : inline-block;
                height     : 34px;
                font-size  : 30px;
                font-family: DINAlternate-Bold, DINAlternate;
                font-weight: bold;
                color      : #0e62fe;
              }

              &:last-of-type {
                margin-top : 6px;
                font-size  : 12px;
                font-weight: 400;
                color      : #666666;
              }
            }
          }

          .btn {
            position   : absolute;
            right      : 24px;
            width      : 112px;
            height     : 36px;
            line-height: 36px;
            text-align : center;
            margin-top : 20px;
            font-size  : 14px;
            font-weight: 400;
            color      : #ffffff;
            background : linear-gradient(270deg,
                #0e62fe 0%,
                #0ea4fe 100%,
                #0ea9fe 100%);
            border-radius: 7px;
            cursor       : pointer;
          }
        }
      }
    }
  }

  .bottom {
    width        : 784px;
    min-width    : 784px;
    height       : 240px;
    background   : #ffffff;
    box-shadow   : 14px 17px 29px 28px rgba(122, 175, 255, 0.03);
    border-radius: 12px;

    .title {
      height      : 60px;
      padding-left: 30px;
      line-height : 60px;
      font-size   : 16px;
      font-weight : 700;
      color       : #333333;
    }

    .line {
      display         : block;
      width           : 100%;
      height          : 1px;
      opacity         : 0.4;
      background-color: #ccc;
    }

    .content {
      display        : flex;
      justify-content: space-between;
      align-items    : center;
      height         : 177px;
      padding        : 0 50px;

      div {
        display       : flex;
        flex-direction: column;
        align-items   : center;
        cursor        : pointer;

        img {
          width: 90px;
        }

        span {
          font-size  : 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color      : #333333;
          margin-top : -10px;
        }
      }
    }
  }
}

#guide {
  #mask {
    position        : fixed;
    top             : 0;
    left            : 0;
    bottom          : 0;
    right           : 0;
    width           : 100%;
    height          : 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index         : 100001 !important;
  }

  #step-tip {
    position  : absolute;
    width     : 112px;
    height    : 112px;
    padding   : 8px;
    top       : 640px;
    left      : 652px;
    background: url("~@/assets/images/shop/dotted-circle.png") no-repeat;
    z-index   : 100002;

    #content {
      position        : relative;
      width           : 100%;
      height          : 100%;
      border-radius   : 50%;
      background-color: #fff;

      img {
        margin-left: -6px;
      }

      &::before {
        position  : absolute;
        content   : "";
        top       : -85px;
        left      : 38px;
        width     : 76px;
        height    : 76px;
        background: url("~@/assets/images/shop/dotted-arrow.png") no-repeat;
      }

      &::after {
        position   : absolute;
        content    : "商城模式已上线，免费开户，开启免费获客";
        top        : -110px;
        left       : 124px;
        width      : 410px;
        height     : 52px;
        font-size  : 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color      : #ffffff;
        line-height: 52px;
        text-align : center;
        background : url("~@/assets/images/shop/dotted-ellipse.png") no-repeat;
      }

      #btn {
        position     : absolute;
        top          : 37px;
        left         : 266px;
        width        : 130px;
        height       : 36px;
        font-size    : 14px;
        font-family  : PingFangSC-Medium, PingFang SC;
        font-weight  : 500;
        color        : #ffffff;
        background   : linear-gradient(180deg, #219bff 0%, #0e62fe 100%);
        border       : unset;
        border-radius: 18px;
        cursor       : pointer;
      }
    }
  }
}

.finalPrice {
  font-size  : 14px;
  font-family: Arial-Black, Arial;
  font-weight: 900;
  color      : #0e77fe;
}

.app-content {
  margin-right: 16px;
  flex        : 1;
}

.part-one {
  display        : flex;
  justify-content: space-between;
  align-items    : center;
  width          : 100%;
  height         : 60px;
  padding        : 0 18px 0 24px;
  font-size      : 16px;
  font-weight    : 700;
  color          : #333333;
  cursor         : pointer;
}

.line {
  display         : block;
  width           : 100%;
  height          : 1px;
  opacity         : 0.4;
  background-color: #ccc;
}

.footer {
  display: flex;

  .vip {
    width        : 100%;
    min-width    : 300px;
    height       : 239px;
    margin-left  : 16px;
    background   : #FFFFFF;
    box-shadow   : 14px 17px 29px 28px rgba(122, 175, 255, 0.03);
    border-radius: 12px;

    .over {
      display        : flex;
      justify-content: space-between;
      align-items    : center;
      position       : relative;
      height         : calc(100% - 50px);

      &::before {
        position        : absolute;
        content         : "";
        top             : 50%;
        left            : 50%;
        transform       : translateY(-50%);
        width           : 1px;
        height          : 120px;
        opacity         : 0.4;
        background-color: #cccccc;
      }

      .count {
        flex      : 1;
        text-align: center;

        .total {
          font-size  : 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color      : #0e62fe;
          line-height: 17px;

          span {
            font-size  : 30px;
            font-family: Arial-Black, Arial;
            font-weight: 700;
            line-height: 36px;
          }
        }

        .down {
          font-size  : 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color      : #333333;
          line-height: 17px;
        }
      }
    }
  }
}

.recommend {
  display      : inline-block;
  width        : 330px;
  min-width    : 330px;
  height       : 772px;
  background   : #FFFFFF;
  box-shadow   : 14px 17px 29px 28px rgba(122, 175, 255, 0.03);
  border-radius: 12px;
  overflow-y   : auto;

}

/deep/ .drawer-content{
  margin-left: 20px;
}
</style>
