<template>
  <div class="container">
    <div class="receive" @click="receive" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { checkActivity, joinActivity } from '@/api/activity/index'
import enums from '@/api/enum'
import { getActivityTime } from '@/api/maint/cfg'
export default {
  name: '',
  data() {
    return {
      activityTime: {}
    }
  },
  computed: {
    ...mapGetters(['profile'])
  },
  async mounted() {
    try {
      const res = await getActivityTime()
      this.activityTime = JSON.parse(res[0].value)
      console.log(this.activityTime)
    } catch (error) {
      console.log(error)
    }
  },

  methods: {
    async  receive() {
      // // 活动时间
      const end = +new Date(this.activityTime.activityEnd)
      const start = +new Date(this.activityTime.activityStart)

      const now = +new Date()
      if (now < start) {
        return this.$message.warning('活动未开始')
      } else if (now > end) {
        return this.$message.warning('活动已结束')
      }
      if (this.profile.settleStatus !== enums.settledStatus.approved) { return this.$router.push({ path: '/apply/go' }) }
      // 检查是否参加过活动
      try {
        const res = await checkActivity(this.activityTime.code)
        if (!res) {
          await joinActivity(this.activityTime.code)
          this.$confirm('优惠券领取成功，快去使用吧?', '领取成功', {
            confirmButtonText: '开通会员',
            cancelButtonText: '充值资信币',
            center: true,
            distinguishCancelAndClose: true,
            lockScroll: true,
            type: 'success'
          }).then(() => {
            this.$router.push('/finance/membership')
          }).catch((action) => {
            if (action === 'cancel') {
              this.$router.push('/finance/purchasecoin')
            }
          })
        } else {
          return this.$message.warning('您已领取过优惠券，不要太贪心哦～')
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.container {
  position: relative;
  min-width: 1320px;
  height: 3328px;
  background: url('https://zxbpro.oss-cn-beijing.aliyuncs.com/image/banners/pc_detail_2023.webp') no-repeat top;

  .receive {
    position: absolute;
    top: 1170px;
    left: 50.5%;
    transform: translateX(-50%);
    width: 330px;
    height: 88px;
    cursor: pointer;
    // background-color: rgba($color: #000000, $alpha: .7);
  }
}
</style>
