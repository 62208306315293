/**
 * Created by PanJiaChen on 16/11/18.
 */

// import { Message } from 'element-ui'

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value ] }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = url.split('?')[1]
  if (!search) {
    return {}
  }
  return JSON.parse(
    '{"' +
      decodeURIComponent(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')
        .replace(/\+/g, ' ') +
      '"}'
  )
}

// 防抖函数
export function debounce(fn, wait) {
  let timer = null

  return function() {
    if (timer !== null) { clearTimeout(timer) }

    timer = setTimeout(() => {
      fn.apply(this, arguments)
    }, wait)
  }
}

// element分页器bug处理函数
export function changeParams(paramsData, count) {
  const params = Object.assign({}, JSON.parse(JSON.stringify(paramsData)))
  const totalPage = Math.ceil((count - 1) / params.pageSize)
  params.pageIndex = params.pageIndex > totalPage ? totalPage : params.pageIndex
  params.pageIndex = params.pageIndex < 1 ? 1 : params.pageIndex
  return params
}

/**
 * 判断时间范围是否大于3个月
 * @param {*} start
 * @param {*} end
 */
export function threeMonthAgo(start, end, len = 3) {
  const date = end ? new Date(end) : new Date()
  date.setMonth(date.getMonth() - len)
  const res = date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate()
  const threeMonthAgo = new Date(res).getTime()
  const startDate = new Date(start).getTime()
  // if (end && end.getTime() < startDate) {
  //   return Message.success('开始时间不能大于结束时间！')
  // }else{

  // }
  return startDate && startDate < threeMonthAgo
}

/**
 * 阿拉伯数字转中文数字
 * @param {*} source
 * @returns
 */
export function formatNumber(source) {
  const numChar = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九', '十']
  const numUnit = ['', '十', '百', '千'] // 权位
  const numSection = ['', '万', '亿', '万亿', '亿亿'] // 节权位
  const formatSection = (num) => {
    const arr = (num + '').split('').reverse()
    let str = ''
    for (let i = 0; i < arr.length; i++) { // 将0-9转化为零到九
      const char = arr[i] === 0 ? numChar[0] : numChar[arr[i]] + numUnit[i] // 当数字为0时不加权位，非零加权位
      str = char + str
    }
    const s = str.replace(/零+/g, '零').replace(/零+$/, '') // 将多个零合并为一个零，并剔除尾端的零
    return s
  }
  const formatNum = (num, str) => { // 将字符串按个数拆分
    const len = Math.ceil(str.length / num)
    const arr = []
    for (let i = 0; i < len; i++) {
      const reverseStr = str.split('').reverse().join('')
      const s = reverseStr.slice(i * num, i * num + num).split('').reverse().join('')
      arr.unshift(s)
    }
    return arr
  }
  const arr = formatNum(4, source + '') // 将数字每四个拆分一次

  const list = []
  for (let i = 0; i < arr.length; i++) {
    const str = formatSection(arr[i])
    list.push(str)
  }
  const reverseList = list.reverse()
  for (let j = 0; j < reverseList.length; j++) {
    reverseList[j] += numSection[j]
  }
  return reverseList.reverse().join('')
}

export function convertCurrency(money, suffix = true) {
  // 汉字的数字
  // eslint-disable-next-line no-array-constructor
  var cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖')
  // 基本单位
  // eslint-disable-next-line no-array-constructor
  var cnIntRadice = new Array('', '拾', '佰', '仟')
  // 对应整数部分扩展单位
  // eslint-disable-next-line no-array-constructor
  var cnIntUnits = new Array('', '万', '亿', '兆')
  // 对应小数部分单位
  // eslint-disable-next-line no-array-constructor
  var cnDecUnits = new Array('角', '分', '毫', '厘')
  // 整数金额时后面跟的字符
  var cnInteger = suffix ? '整' : ''
  // 整型完以后的单位
  var cnIntLast = suffix ? '圆' : ''
  // 最大处理的数字
  var maxNum = 999999999999999.9999
  // 金额整数部分
  var integerNum
  // 金额小数部分
  var decimalNum
  // 输出的中文金额字符串
  var chineseStr = ''
  // 分离金额后用的数组，预定义
  var parts
  // 传入的参数为空情况
  if (money == '') {
    return ''
  }
  money = parseFloat(money)
  if (money >= maxNum) {
    return ''
  }
  // 传入的参数为0情况
  if (money == 0) {
    chineseStr = cnNums[0] + cnIntLast + cnInteger
    return chineseStr
  }
  // 转为字符串
  money = money.toString()
  // indexOf 检测某字符在字符串中首次出现的位置 返回索引值（从0 开始） -1 代表无
  if (money.indexOf('.') == -1) {
    integerNum = money
    decimalNum = ''
  } else {
    parts = money.split('.')
    integerNum = parts[0]
    decimalNum = parts[1].substr(0, 4)
  }
  // 转换整数部分
  if (parseInt(integerNum, 10) > 0) {
    let zeroCount = 0
    const IntLen = integerNum.length
    for (let i = 0; i < IntLen; i++) {
      const n = integerNum.substr(i, 1)
      const p = IntLen - i - 1
      const q = p / 4
      const m = p % 4
      if (n == '0') {
        zeroCount++
      } else {
        if (zeroCount > 0) {
          chineseStr += cnNums[0]
        }
        zeroCount = 0
        chineseStr += cnNums[parseInt(n)] + cnIntRadice[m]
      }
      if (m == 0 && zeroCount < 4) {
        chineseStr += cnIntUnits[q]
      }
    }
    // 最后+ 元
    chineseStr += cnIntLast
  }
  // 转换小数部分
  if (decimalNum != '') {
    const decLen = decimalNum.length
    for (let i = 0; i < decLen; i++) {
      const n = decimalNum.substr(i, 1)
      if (n != '0') {
        chineseStr += cnNums[Number(n)] + cnDecUnits[i]
      }
    }
  }
  if (chineseStr == '') {
    chineseStr += cnNums[0] + cnIntLast + cnInteger
  } else if (decimalNum == '') {
    chineseStr += cnInteger
  }

  return chineseStr
}

// 通过地区id获取当前地址的code
export function getAreaCode(list, id) {
  if (!list || !id) { return '' }
  let res = ''
  const findCode = (data, id) => {
    for (let i = 0, length = data.length; i < length; i++) {
      const node = data[i]

      if (node.id === id) {
        res = node.code
        if (id === list[0].id) { break }
        break
      } else {
        if (node.children) {
          findCode(node.children, id)
        }
        continue
      }
    }
    return res
  }
  return findCode(list, id)
}

// 通过地区code 获取所在地区的id数组
export function getParentIdList(list, code) {
  if (!list || !code) {
    return ''
  }
  const arr = []
  const findParent = (data, code, pCode) => {
    for (let i = 0, length = data.length; i < length; i++) {
      const node = data[i]

      if (node.code === code) {
        arr.unshift(node.id)
        if (code === list[0].code) {
          break
        }
        findParent(list, pCode)
        break
      } else {
        if (node.children) {
          findParent(node.children, code, node.code)
        }
        continue
      }
    }
    return arr
  }
  return findParent(list, code)
}
