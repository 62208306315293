const getters = {
  sidebar: state => state.app.sidebar,
  permissions: state => state.user.permissions,
  device: state => state.app.device,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  roles: state => state.user.roles,
  tenantId: state => state.user.tenantId,
  profile: state => state.user.profile,
  msgCount: state => state.user.msgCount,
  customCount: state => state.user.customCount,
  channel: state => state.user.channelStatus,
  isAdmin: state => state.user.isAdmin,
  threeAreaInCh: state => state.common.threeArea,
  threeArea: state => state.common.threeArea.slice(1),
  id: state => state.user.id
}
export default getters
