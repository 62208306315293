var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-breadcrumb",
    { staticClass: "app-breadcrumb", attrs: { separator: "/" } },
    _vm._l(_vm.levelLists, function(item, index) {
      return _c("el-breadcrumb-item", { key: item.path }, [
        item.redirect === "noRedirect" || index == _vm.levelList.length - 1
          ? _c("span", { staticClass: "no-redirect" }, [
              _vm._v(_vm._s(item.name))
            ])
          : _c(
              "a",
              {
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.handleLink(item, index)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.generateTitle(item.meta.title, index)))]
            )
      ])
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }