<template>
  <section id="app-main" class="app-main">
    <!-- <transition name="fade-transform" mode="out-in"> -->
    <keep-alive :include="cachedViews">
      <router-view :key="key" />
    </keep-alive>
    <!-- </transition> -->
  </section>
</template>

<script>
import { preConnect } from '@/api/im/im'
import ChatWebSocket from '@/utils/chatWebSocket'
import { mapGetters } from 'vuex'
export default {
  name: 'AppMain',
  data() {
    return {
      socket: null
    }
  },
  computed: {
    ...mapGetters(['token']),
    cachedViews() {
      return this.$store.state.tagsView.cachedViews
    },
    key() {
      return this.$route.fullPath
    }
  },

  watch: {
    // 监听token 失效就关闭链接
    token: {
      handler(newVal) {
        if (!newVal) {
          try {
            this.socket.onClose()
            // 移除监听事件
            this.$EventBus.$off('imMessage')
          } catch (error) {
            console.log(error)
          }
        }
      }
    }
  },
  async mounted() {
    try {
      const res = await preConnect()
      this.socket = new ChatWebSocket(res.connectUrl)
      this.socket.onOpen()
      this.socket.onMessage((res) => {
        this.$EventBus.$emit('imMessage', res)
      })
    } catch (error) {
      console.log(error)
    }
  }
}
</script>

<style lang="scss" scoped>
.app-main {
  /* 70= navbar  70  */
  min-height: calc(100vh - 70px);
  width: 100%;
  background-color: #EFF2F5;
  position: relative;
  overflow: hidden;
  overflow-x: auto;
}

.fixed-header+.app-main {
  padding-top: 70px;
}

.hasTagsView {
  .app-main {
    /* 84 = navbar + tags-view = 50 + 34 */
    min-height: calc(100vh - 94px);
  }

  .fixed-header+.app-main {
    padding-top: 94px;
  }
}
</style>

