import request from '@/utils/request'

export const getChannelList = () =>
  request({
    url: '/niche/channel/shop/list',
    method: 'GET'
  })

/**
 * 获取购买记录
 * @param {*} data
 */
export const getChannelLogs = params =>
  request({
    url: '/shop/channel/logs',
    method: 'GET',
    params
  })

/**
 * 获取购买记录详情
 * @param {*} id
 * @returns
 */
export const getChannelLogsDetail = (id = 0) =>
  request({
    url: '/shop/channel/logs/' + id,
    method: 'GET'
  })

/**
 * 获取个人签约信息
 */
export const getChannelInfor = () =>
  request({
    url: '/shop/channel',
    method: 'GET'
  })

/**
 * 申请渠道开户
 * @param { * } data
 * @returns
 */
export const applyChannel = (data, post) => {
  let method = 'POST'
  let url = '/shop/channel/apply'

  if (data.id && !post) {
    method = 'PUT'
    url = url + '/' + data.id
  }
  return request({ url, method, data })
}

/**
 * 打款验证
 * @param {*} id
 * @returns
 */
export const checkBankAccount = id =>
  request({
    url: '/shop/channel/bank/' + id,
    method: 'PUT'
  })

/**
 * 线下支付标记
 * @param {*} id
 * @returns
 */
export const offlinePaySign = id =>
  request({
    url: '/shop/channel/offline/bank/' + id,
    method: 'PUT'
  })

/**
 * 获取申请开户的详情(商户)
 * @returns
 */
export const getChannelApply = (code) =>
  request({
    url: '/shop/channel/apply/' + code,
    method: 'GET'
  })

/**
 * 获取申请列表
 * @param {*} params
 * @returns
 */
export const getChannelApplyList = params =>
  request({
    url: '/shop/channel/apply/page',
    method: 'GET',
    params
  })

/**
 * 财务相关审核详情
 * @returns
 */
export const getChannelDetailFin = (tid, id) =>
  request({
    url: '/shop/channel/apply/detail/' + tid + '/' + id,
    method: 'GET'
  })

/**
 * 财务相关审核操作
 * @returns
 */
export const checkChannelFin = ({ tid, id }, data) =>
  request({
    url: '/shop/channel/apply/finance/' + tid + '/' + id,
    method: 'PUT',
    data
  })

/**
 * 商务相关审核详情
 * @returns
 */
export const getChannelDetailCom = (tid, id) =>
  request({
    url: '/shop/channel/apply/commerce/' + tid + '/' + id,
    method: 'GET'
  })

/**
 * 能建委审核详情
 * @param {*} tid
 * @param {*} id
 * @returns
 */
export const getChannelDetailNjw = (tid, id) =>
  request({
    url: '/shop/channel/apply/njw/' + tid + '/' + id,
    method: 'GET'
  })

/**
 * 商务相关审核操作
 * @returns
 */
export const checkChannelCom = ({ tid, id }, data) =>
  request({
    url: '/shop/channel/apply/commerce/' + tid + '/' + id,
    method: 'PUT',
    data
  })

/**
 * 能建委相关审核操作
 * @returns
 */
export const checkChannelNjw = ({ tid, id }, data) =>
  request({
    url: '/shop/channel/apply/njw/' + tid + '/' + id,
    method: 'PUT',
    data
  })

/**
 * 获取服务商流水
 * @param {*} params
 * @returns
 */
export const getChannelStream = params => {
  return request({
    url: '/shop/channel/stream',
    method: 'GET',
    params
  })
}

/**
 * 获取机构流水详单
 * @param {*} tid
 * @param {*} params
 * @returns
 */
export const getChannelStreamDetail = (tid, params) => {
  return request({
    url: '/shop/channel/stream/page/' + tid,
    method: 'GET',
    params
  })
}

/**
 * 导出表格
 * @param {*} data
 * @returns
 */
export const exportChannelExcel = data => {
  return request({
    url: '/shop/channel/export',
    method: 'POST',
    data
  })
}

/**
 * 导出表格
 * @param {*} tid
 * @returns
 */
export const syncCrm = (tid, id) => {
  return request({
    url: `/shop/channel/crm/sync/${tid}/${id}`,
    method: 'PUT'
  })
}

/**
 * 设置商务查看流水
 * @param {*} tid
 * @returns
 */
export const setBussiness = (tid, data) => {
  return request({
    url: `/shop/channel/business/${tid}`,
    method: 'PUT',
    data
  })
}

