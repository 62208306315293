import * as signalR from '@microsoft/signalr'
import store from '@/store'
import { getPushNotices, sendUnreadCount } from '@/api/maint/notice'
import { getUnreadCount } from '@/api/im/im'
import { Notification } from 'element-ui'
const baseUrl = process.env.VUE_APP_BASE_IM

export function toConnection(userId) {
  var connection = new signalR.HubConnectionBuilder()
    .withUrl(baseUrl + '/im/unauth/hubs/msg', {
      accessTokenFactory: () => {
        return userId // 返回 管理员ID

      // Get and return the access token.
      // This function can return a JavaScript Promise if asynchronous
      // logic is required to retrieve the access token.
      }
    })
    .withAutomaticReconnect()
    .build()
  connection.serverTimeoutInMilliseconds = 24e4
  connection.keepAliveIntervalInMilliseconds = 12e4

  async function start() {
    try {
      await connection.start()
      sendUnreadCount()
      console.assert(connection.state === signalR.HubConnectionState.Connected)
    } catch (err) {
      console.assert(connection.state === signalR.HubConnectionState.Disconnected)
      console.log(err)
      // setTimeout(() => start(), 5000)
    }
  }
  connection.on('unread', (value) => {
    if (value <= 0) {
      value = null
      store.commit('user/SET_MSG_COUNT', value)
      return
    }

    // 获取推送消息
    getPushNotices(userId).then((res) => {
      // 客服的未读消息数量
      getUnreadCount().then((res) => {
        const customUnreadCount = typeof res === 'number' && res > 0 ? res : null

        store.commit('user/SET_CUSTOM_COUNT', customUnreadCount)
      })

      if (res.length <= 0) return
      const audio = document.getElementById('msgaudio')
      if (audio) {
        audio.play()
      }

      res.forEach(item => {
        setTimeout(() => {
          Notification({
            title: item.createTime,
            message: item.content,
            duration: 3000,
            position: 'bottom-right'
          })
        }, 500)
      })
    })
    store.commit('user/SET_MSG_COUNT', value)
  })

  start()
}

