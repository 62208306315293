export default {
  route: {
    dashboard: '首页',
    home: '首页',
    identity: '用户中心',
    maintain: '运维中心',
    cms: 'CMS管理',
    banner: 'banner管理',
    article: '文章管理',
    editArticle: '编辑文章',
    file: '文件管理',
    contacts: '邀约管理',
    channel: '栏目管理',
    menu: '菜单管理',
    dept: '部门管理',
    user: '用户管理',
    role: '角色管理',
    task: '任务管理',
    taskLog: '任务日志',
    dict: '字典管理',
    loginLog: '登录日志',
    opsLog: '操作日志',
    nlogLog: 'Nlog日志',
    cfg: '参数管理',
    health: '健康检查',
    druid: '性能链路监测',
    swagger: '接口文档',
    messageMgr: '消息管理',
    msg: '历史消息',
    msgTpl: '消息模板',
    msgSender: '消息发送器',
    area: '区域管理',
    qua: '资质管理',
    tranqua: '转让资质管理',
    shop: '商户管理',
    examine: '审核管理',
    shopApply: '商户入驻管理',
    shopInfo: '商户信息',
    shopCase: '典型案例',
    team: '团队管理',
    trans: '转让管理',
    verify: '商户审核管理',
    consult: '咨询管理',
    transConsult: '转让求购咨询',
    qinquiry: '求购管理',
    money: '增值服务',
    niche: '商机管理',
    coin: '资信币套餐',
    membership: '会员套餐',
    finance: '财务管理',
    shopCoin: '资信币',
    shopVip: '会员',
    myNiche: '我的商机',
    nicheCenter: '商机中心',
    goods: '商品管理',
    quartz: '任务调度管理',
    advert: '广告管理',
    report: '资金账单',
    corpmember: '结算账户',
    charges: '手续费账户',
    op_balance: '运营账户',
    memberAccount: '收款账户',
    audit: '审核管理',
    orderSettle: '提现审核',
    accountManage: '账户管理',
    order: '订单管理',
    orderTrade: '交易订单',
    payStream: '支付流水',
    corpMember: '结算账户',
    ownercapital: '我的资金',
    shopOrder: '订单管理',
    ownerOrder: '我的订单',
    orderDetail: '订单详情',
    shopSupplierCoop: '撮合服务',
    cooperatePageDetail: '业务详情',
    message: '消息',
    owner_coin: '我的资信币',
    shopCashOut: '提现审核',
    corpmembers: '结算账户',
    settleOrder: '结算订单',
    coinGrant: '资信币发放',
    applyCoopCredit:'产品合作'
  },
  navbar: {
    logOut: '退出登录',
    profile: '个人资料',
    updatePwd: '修改密码',
    dashboard: '首页',
    home: '首页',
    github: '项目地址',
    screenfull: '全屏',
    theme: '换肤',
    size: '布局大小',
    reload: '清除缓存'
  },
  login: {
    title: '资信办商户后台',
    errorTenant: '请输入租户编号',
    errorAccount: '请输入6到16位的数字和字母',
    errorPassword: '密码至少位6位',
    logIn: '登录',
    register: '注册',
    phone: '手机号',
    tenantId: '租户',
    password: '密码'
  },

  button: {
    add: '添加',
    new: '新增',
    edit: '修改',
    delete: '删除',
    search: '搜索',
    reset: '重置',
    submit: '提交',
    cancel: '取消',
    clear: '清除',
    clearCache: '清除缓存',
    back: '返回',
    export: '导出',
    disable: '禁用',
    enable: '启用',
    show: '查看',
    check: '审核',
    remove: '注销',
    process: '处理',
    confirm: '确认',
    processResult: '处理结果',
    release: '发布',
    offShelf: '下架',
    manage: '管理',
    enabled: '恢复使用',
    disabled: '冻结',
    stream: '流水',
    copy: '复制',
    log: '记录',
    delay: '延迟',
    filter: '筛选',
    cooperate_settle: '签约',
    upems: '上传快递',
    offlinePay: '线下支付',
    finance_check: '财务审核',
    commerce_check: '商务审核',
    njw_check: '能建委审核',
    payfail: '未到账',
    changePrice: '修改价格',
    createOrder: '创建订单',
    changePayType:'切换支付方式'
  },
  common: {
    mustSelectOne: '请选中操作项!',
    deleteConfirm: '你确认删除该记录？',
    tooltip: '提示',
    warning: '警告',
    optionSuccess: '操作成功',
    uploadError: '上传文件失败',
    isRequired: '不能为空',
    week: {
      mon: '周一',
      tue: '周二',
      wed: '周三',
      thu: '周四',
      fri: '周五',
      sat: '周六',
      sun: '周日'
    }
  },
  dashboard: {
    newUser: '新增用户',
    message: '未处理消息',
    income: '收入',
    onlineUser: '在线用户',
    document: '在线文档',
    date: '日期',
    name: '名称',
    addr: '地址',
    email: '邮件营销',
    ad: '联盟广告',
    vedio: '视频广告',
    direct: '直接访问',
    searchEngine: '搜索引擎',
    userFrom: '用户来源'
  },
  config: {
    name: '参数名',
    value: '参数值',
    code: '编码',
    descript: '备注',
    add: '添加参数',
    edit: '编辑参数',
    nameInput: '请输入参数名',
    valueInput: '请输入参数值',
    lengthValidation: '长度在 2 到 20 个字符',
    keyword: '关键字'
  }
}
