import { login, logout, getInfo, phoneLogin } from '@/api/account'
import { getToken, setToken, removeToken, removeChannelStatus } from '@/utils/auth'
import { getShopVip } from '@/api/shop/shop'
import { resetRouter } from '@/router'
import { toConnection } from '@/utils/msgSignalr'
import { getChannelInfor } from '@/api/shop/channel'

const state = {
  token: getToken(),
  name: '',
  tenantId: null,
  id: null,
  avatar: '',
  permissions: null,
  roles: [],
  profile: {},
  vipInfo: {},
  channelStatus: {},
  msgCount: null,
  customCount: null,
  isAdmin: null
}

const mutations = {
  SET_CHANNEL: (state, payload) => {
    state.channelStatus = payload
  },
  SET_CUSTOM_COUNT: (state, count) => {
    state.customCount = count
  },
  SET_MSG_COUNT: (state, msg) => {
    state.msgCount = msg
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_PROFILE: (state, profile) => {
    state.profile = profile
  },
  SET_PERMISSIONS: (state, permissions) => {
    state.permissions = permissions
  },
  // 用户所属企业id
  SET_TENANTID: (state, tenantId) => {
    state.tenantId = tenantId
  },
  // 用户的个人id
  SET_ID: (state, id) => {
    state.id = id
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_USER_TYPE: (state, isAdmin) => {
    state.isAdmin = isAdmin
  },
  SET_VIP_INFOR: (state, vip) => {
    state.vipInfo = vip
  }
}

const actions = {

  async getChannelInfor({ commit }) {
    try {
      const res = await getChannelInfor()
      commit('SET_CHANNEL', res)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  // 获取会员信息
  async getVipInfor({ commit }) {
    try {
      const res = await getShopVip()
      commit('SET_VIP_INFOR', res)
      return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  // user login
  login({ dispatch, commit }, userInfo) {
    const { tenantId, phone, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ tenantId: tenantId, phone: phone.trim(), password: password }).then(data => {
        commit('SET_TOKEN', data.token)
        setToken(data.token)
        resolve()
      }).catch(error => {
        console.log(error)
        reject(error)
      })
    })
  },

  async phoneLogin({ dispatch, commit }, userInfo) {
    const { tenantId, phone, smsValidateCode } = userInfo
    try {
      const data = await phoneLogin({ tenantId, phone: phone.trim(), smsValidateCode })
      commit('SET_TOKEN', data.token)
      setToken(data.token)
      return Promise.resolve()
    } catch (error) {
      console.log(error)
      return Promise.reject(error)
    }
  },

  async getUserInfo({ dispatch, commit, state }) {
    try {
      const data = await getInfo()
      if (!data) {
        return Promise.reject('验证失败，请重新登录')
      }
      const { profile, permissions, roles } = data
      toConnection(data.id)
      if (data.tenantId === 1000000000000) {
        commit('SET_USER_TYPE', true)
      } else {
        commit('SET_USER_TYPE', false)
      }
      commit('SET_NAME', profile.name)
      commit('SET_ID', data.id)
      commit('SET_AVATAR', profile.avatar)
      commit('SET_PROFILE', profile)
      commit('SET_ROLES', roles)
      commit('SET_PERMISSIONS', permissions)
      commit('SET_TENANTID', data.tenantId)
      return Promise.resolve(data)
    } catch (error) {
      console.log(error)
      return Promise.reject(error)
    }
  },

  // get user info
  async getInfo({ dispatch, commit, state }) {
    try {
      resetRouter()
      const data = await dispatch('getUserInfo')
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  // user logout
  async logout({ commit, state }) {
    if (!getToken()) {
      commit('SET_TOKEN', '')
      commit('SET_NAME', '')
      commit('SET_AVATAR', '')
      commit('SET_PROFILE', {})
      commit('SET_ROLES', [])
      commit('SET_PERMISSIONS', [])
      commit('SET_TENANTID', '')
      removeToken()
      resetRouter()
      removeChannelStatus()
      return Promise.resolve()
    }

    try {
      await logout(state.token)
      commit('SET_TOKEN', '')
      commit('SET_NAME', '')
      commit('SET_AVATAR', '')
      commit('SET_PROFILE', {})
      commit('SET_ROLES', [])
      commit('SET_PERMISSIONS', [])
      commit('SET_TENANTID', '')
      removeToken()
      resetRouter()
      removeChannelStatus()
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      removeToken()
      resolve()
    })
  },
  updateToken({ commit }, { token }) {
    console.log('newToken', token)
    commit('SET_TOKEN', token)
    setToken(token)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

