<template>
  <div>

    <div class="content">
      <ul>
        <li v-for="item in recommendNiche" :key="item.id" class="item">
          <p class="title" @click="goToPage('/niche/store')">
            {{ item.title }}
          </p>
          <p class="time">
            <i class="el-icon-time" /> {{ item.publishOn.substring(0,10) }}发布
          </p>
          <p class="address">
            <i class="el-icon-location-outline" /> <span>{{ item.areaName }}</span>{{ item.qName }}
          </p>
          <p class="mask">
            <span>备注：</span>{{ item.mark }}
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getNicheCenterList } from '@/api/shop/niche'
import enums from '@/api/enum'

export default {
  name: '',
  data() {
    return {
      recommendNiche: [],

      queryNiche: {
        pageSize: 5,
        pageIndex: 1
      }

    }
  },
  mounted() {
    this.getNiche()
  },
  methods: {
    goToPage(path) {
      if (!path) return
      this.$store.dispatch('user/getUserInfo')
      if (this.profile.settleStatus !== enums.settledStatus.approved) { return this.$router.push({ path: '/apply/go' }) }
      if (!this.permissions.includes(path)) {
        return this.$message.warning('暂无权限，请联系管理员')
      }
      this.$router.push({ path })
    },

    async getNiche() {
      try {
        const res = await getNicheCenterList(this.queryNiche)
        this.recommendNiche = res.page.data
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style scoped lang="scss">

 .content {
    padding    : 0 25px 0;
    font-family: PingFangSC-Regular, PingFang SC;
    font-size  : 12px;
    font-weight: 400;
    color      : #666;

    .item {
      position      : relative;
      margin        : 20px 0 0 10px;
      padding-bottom: 20px;

      &::before {
        position  : absolute;
        content   : "";
        width     : 3px;
        height    : 13px;
        background: #0E62FE;
        top       : 3px;
        left      : -10px;
      }

      &:nth-of-type(-n+4) {
        &::after {
          position     : absolute;
          content      : "";
          width        : 105%;
          height       : 0;
          border-bottom: 1px dashed #ccc;
          bottom       : 0;
          left         : -7px;
        }
      }

      .title {
        @include Oneline-ellipsis(100%);
        font-size  : 14px;
        font-weight: 700;
        color      : #333;
        line-height: 20px;
        cursor: pointer;
      }

      .time {
        margin: 10px 0 4px;
      }

      .address{
        span{
          margin-right: 40px;
        }
      }

      .mask {
        @include Oneline-ellipsis(100%);
        margin-top : 16px;
        line-height: 17px;

        span {
          &:first-of-type {
            color: #333;
          }
        }
      }
    }
  }
</style>
