import request from '@/utils/request'

/**
 * 获取营销活动列表
 * @param {*} params
 * @returns
 */
export const getActivity = (params) => {
  return request({
    url: '/im/activity/page',
    method: 'GET',
    params
  })
}

/**
 * 保存活动
 * @param {*} data
 * @returns
 */
export const saveActivity = (data) => {
  return request({
    url: '/im/activity',
    method: 'POST',
    data
  })
}

/**
 * 获取详情
 * @param {*} id
 */
export const getItemDetail = (id) => {
  return request({
    url: '/im/activity/detail/' + id,
    method: 'GET'
  })
}

/**
 * 更改活动状态
 * @param {*} id
 * @param {*} status
 * @returns
 */
export const changeActivity = (id, status) => {
  return request({
    url: `/im/activity/status/${id}/${status}`,
    method: 'PUT'
  })
}

/**
 * 删除活动
 * @param {*} id
 * @returns
 */
export const delActivity = (id) => {
  return request({
    url: '/im/activity/' + id,
    method: 'DELETE'
  })
}

/**
 * 获取报表
 * @param {*} params
 * @returns
 */
export const getReport = (params) => {
  return request({
    url: '/im/activity/report',
    method: 'GET',
    params
  })
}

/**
 * 参与活动
 * @param {*} code
 * @returns
 */
export const joinActivity = (code) => {
  return request({
    url: '/im/action/receive/' + code,
    method: 'POST'
  })
}

/**
 * 检查是否参与活动
 * @param {*} code
 * @returns
 */
export const checkActivity = (code) => {
  return request({
    url: '/im/action/receive/' + code,
    method: 'GET'
  })
}
