var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-container" },
    [
      _c(
        "el-row",
        { staticClass: "panel-group", attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 6 } }, [
            _c(
              "div",
              {
                staticClass: "card-panel",
                on: {
                  click: function($event) {
                    return _vm.handleSetOnlineData()
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "card-panel-icon-wrapper icon-people" },
                  [
                    _c("svg-icon", {
                      attrs: {
                        "icon-class": "peoples",
                        "class-name": "card-panel-icon"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "card-panel-description" },
                  [
                    _c("div", { staticClass: "card-panel-text" }, [
                      _vm._v("\n            在线人数\n          ")
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-tooltip",
                      { attrs: { placement: "right", effect: "light" } },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: { width: "600px" },
                            attrs: { slot: "content" },
                            slot: "content"
                          },
                          [
                            _c(
                              "el-table",
                              { attrs: { data: _vm.online.users } },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    width: "160px",
                                    property: "time",
                                    label: "时间"
                                  }
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    width: "160px",
                                    property: "shop_name",
                                    label: "商户"
                                  }
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: { property: "name", label: "用户名" }
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    property: "account",
                                    label: "登录账户"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("count-to", {
                          staticClass: "card-panel-num",
                          attrs: {
                            "start-val": 0,
                            "end-val": _vm.online.count,
                            duration: 2600
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 24 } }, [
            _c(
              "div",
              { staticClass: "chart section" },
              [_c("v-chart", { attrs: { options: _vm.lineData } })],
              1
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "footer" }, [
        _c("div", { staticClass: "bottom" }, [
          _c("p", { staticClass: "title" }, [_vm._v("快捷审核入口")]),
          _vm._v(" "),
          _c("i", { staticClass: "line" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "content" },
            _vm._l(_vm.shortcutItems, function(item) {
              return _c(
                "div",
                {
                  key: item.name,
                  on: {
                    click: function($event) {
                      return _vm.goToPage(item.path)
                    }
                  }
                },
                [
                  _c("img", { attrs: { src: item.src, alt: item.name } }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(item.name))])
                ]
              )
            }),
            0
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }