import User from './components/User.vue'
import Admin from './components/Admin.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'Home',
  components: { User, Admin },
  props: {},
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters(['isAdmin'])
  },
  methods: {
  }
}
