<template>
  <div class="navbar">
    <hamburger v-show="!sidebar.opened" :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar" />

    <breadcrumb class="breadcrumb-container" />

    <div class="right-menu">
      <template>
        <div class="international right-menu-item" @click="$router.push('/')">
          <svg-icon class-name="icon" icon-class="home" />首页
        </div>
        <div class="international right-menu-item" @click="toBrochure">
          <svg-icon class-name="icon" icon-class="education" style="color:#666" />操作手册
        </div>
        <div v-permission="['/message']" class="international right-menu-item" @click="$router.push('/message')">
          <el-badge :value="msgCount" :max="99" class="hover-effect">
            <svg-icon class-name="icon" icon-class="msg_plain_black" />{{ isAdmin?'消息':'客户消息' }}
          </el-badge>
        </div>
        <div class="international right-menu-item">
          <svg-icon class-name="icon" icon-class="QRcode_black" />
          小程序
          <p class="applets">
            <img src="@/assets/images/applets.jpg" alt="微信小程序">
            <span>您也可以扫描二维码</span>
            <span>在手机上查看店铺</span>
          </p>
        </div>
      </template>
      <el-dropdown class="avatar-container right-menu-item" trigger="click">
        <div class="avatar-wrapper">
          <img v-if="profile.avatar" class="user-avatar" :src="profile.avatar">
          <img v-else class="user-avatar" src="@/assets/img/avatar.gif">
          <span>{{ profile.phone }}</span>
          <i class="el-icon-arrow-down" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <router-link to="/account/profile">
            <el-dropdown-item>
              {{ $t("navbar.profile") }}
            </el-dropdown-item>
          </router-link>
          <router-link to="/account/updatePwd">
            <el-dropdown-item>
              {{ $t("navbar.updatePwd") }}
            </el-dropdown-item>
          </router-link>
          <el-dropdown-item>
            <span style="display: block" @click="reload">
              {{
                $t("navbar.reload")
              }}
            </span>
          </el-dropdown-item>
          <el-dropdown-item divided>
            <span style="display: block" @click="logout">
              {{
                $t("navbar.logOut")
              }}
            </span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger'
import { getSessionCode } from '@/api/identity/admin'

export default {
  components: { Breadcrumb, Hamburger },
  data() {
    return {
      socket: null
    }
  },
  computed: {
    ...mapGetters(['sidebar', 'avatar', 'profile', 'msgCount', 'isAdmin', 'device'])
  },
  methods: {

    async toBrochure() {
      try {
        const code = await getSessionCode()
        window.open(process.env.VUE_APP_STUDY_HOST + code)
      } catch (error) {
        console.log(error)
      }
    },

    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    reload() {
      location.reload(true)
    },
    logout() {
      this.$loading({

        text: 'logouting……',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$store
        .dispatch('user/logout')
        .then(() => {
          this.$router.push('/login')
          this.$loading().close()
        })
        .catch((err) => {
          console.log(err)
          this.$loading().close()
        })
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.navbar {
  height: 70px;
  line-height: 70px;
  border-radius: 0px !important;
  box-shadow: 2px 0 3px -1px #333;
  .hamburger-container {
    height: 50px;
    float: left;
    padding: 0 10px;
  }
  .breadcrumb-container {
    float: left;
  }
  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }
  .right-menu {
    display: flex;
    float: right;
    height: 100%;
    &:focus {
      outline: none;
    }
    .right-menu-item {
      position: relative;
      display: inline-block;
      margin: 0 25px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      cursor: pointer;

      &:hover {
        .applets {
          opacity: 1;
          visibility: inherit;
          transform: translateY(-30px);
        }
      }

      .applets {
        position: absolute;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        top: 80px;
        right: -25px;
        width: 154px;
        height: 180px;
        padding: 7px;
        background-color: #fff;
        box-sizing: border-box;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s;
        box-shadow: 0px 2px 10px 4px rgba(0, 0, 0, 0.12);
        z-index: 20;

        img {
          width: 112px;
          height: 112px;
          margin: 0;
        }

        span {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333;
          line-height: 17px;
        }

        &::after {
          position: absolute;
          content: "";
          top: -6px;
          left: 100px;
          transform: rotateZ(45deg);
          width: 15px;
          height: 15px;
          background-color: #fff;
        }
      }
    }

    .icon {
      width: 18px;
      margin-right: 3px;
    }

    .el-badge{
      line-height: 25px;
    }

    .screenfull {
      height: 20px;
    }
    .international {
      line-height: 70px;
    }
    .theme-switch {
      vertical-align: 15px;
    }
    .avatar-container {
      height: 70px;
      margin-right: 30px;
      .avatar-wrapper {
        cursor: pointer;
        height: 70px;
        font-size: 12px;
        position: relative;
        .user-avatar {
          width: 30px;
          height: 30px;
          margin: 20px 8px 0 0;
          border-radius: 50%;
          vertical-align: top;
          box-shadow: 0px 1px 2px 1px rgba(224, 224, 224, 0.82);
          overflow: hidden;
        }
        .el-icon-caret-bottom {
          position: absolute;
          right: -20px;
          top: 18px;
          margin-left: 8px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
