import request from '@/utils/request'

export function cancel(tenantid) {
  return request({
    url: `/shop/cancel/${tenantid}`,
    method: 'put'
  })
}

export function getShopCoin() {
  return request({
    url: `/shop/shop_coin`,
    method: 'GET'
  })
}

export function getShopVip() {
  return request({
    url: `/shop/shop_vip`,
    method: 'GET'
  })
}

/**
 * 获取会员购买记录
 * @param {*} params
 * @returns
 */
export function getShopVipLogs(params) {
  return request({
    url: `/shop/shop_vip/logs`,
    method: 'GET',
    params
  })
}

/**
 * 获取服务商id和名字
 * @param {*} name
 * @returns
 */
export const getSupplier = (name) => {
  return request({
    url: `/shop/search/${name}`,
    method: 'GET'
  })
}