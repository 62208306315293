var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "app-content" }, [
        _c("div", { staticClass: "top" }, [
          _c(
            "div",
            { staticClass: "avatar" },
            [
              _c(
                "el-avatar",
                {
                  attrs: { size: 92, src: _vm.profile.avatar },
                  on: {
                    error: function($event) {
                      !!_vm.profile.avatar
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: { src: require("@/assets/images/shop/user.png") }
                  })
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "user" }, [
            _c("p", { staticClass: "name" }, [
              _vm._v(_vm._s(_vm.profile.shopName))
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "mobile" }, [
              _c("span", [
                _c("i", { staticClass: "el-icon-mobile" }),
                _vm._v(" " + _vm._s(_vm.profile.phone))
              ]),
              _vm._v(" "),
              _vm.vipData.vipStatus !== _vm.vipStatus.none
                ? _c("img", { attrs: { src: _vm.vipImg } })
                : _vm._e(),
              _vm._v(" "),
              _vm.channel.status !== _vm.vipStatus.none &&
              _vm.channel.code &&
              _vm.channel.code !== "CS001"
                ? _c("img", {
                    attrs: {
                      src: require("@/assets/images/channel/" +
                        _vm.channel.code +
                        (_vm.channel.status === _vm.vipStatus.expire
                          ? "_expire"
                          : "") +
                        ".png")
                    }
                  })
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm.vipData.vipStatus !== _vm.vipStatus.none ||
            _vm.channel.status !== _vm.vipStatus.none
              ? _c(
                  "p",
                  { staticClass: "record", on: { click: _vm.showLogs } },
                  [
                    _vm._v("\n          购买记录"),
                    _c("i", { staticClass: "el-icon-arrow-right" })
                  ]
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm.vipData.vipStatus !== _vm.vipStatus.none
            ? _c(
                "div",
                { staticClass: "vip" },
                [
                  _vm.vipData.vipStatus === _vm.vipStatus.normal
                    ? [
                        _c("p", { staticClass: "img" }, [
                          _c("img", {
                            attrs: { src: _vm.vipLogo, alt: "会员logo" }
                          })
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "有效期至：" +
                              _vm._s(_vm.vipData.memberData.expireOn)
                          )
                        ])
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.vipData.vipStatus === _vm.vipStatus.expire
                    ? [
                        _c("div", { staticClass: "expire-log" }, [
                          _c("img", {
                            attrs: { src: _vm.vipLogo, alt: "过期会员" }
                          })
                        ])
                      ]
                    : _vm._e()
                ],
                2
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "middle" }, [
          _c("div", { staticClass: "left" }, [_c("Banner")], 1),
          _vm._v(" "),
          _c("div", { staticClass: "right" }, [
            _c("section", [
              _c(
                "p",
                {
                  staticClass: "part-one",
                  on: {
                    click: function($event) {
                      return _vm.goToPage("/owner_coin")
                    }
                  }
                },
                [
                  _c("span", [_vm._v("我的资信币")]),
                  _vm._v(" "),
                  _c("i", { staticClass: "icon el-icon-arrow-right" })
                ]
              ),
              _vm._v(" "),
              _c("i", { staticClass: "line" }),
              _vm._v(" "),
              _c("div", { staticClass: "part-two" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("p", { staticClass: "content" }, [
                  _c("span", [_vm._v(_vm._s(_vm.coinTotal))]),
                  _vm._v(" "),
                  _c("span", [_vm._v("资信币总余额")])
                ]),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass: "btn",
                    on: {
                      click: function($event) {
                        return _vm.goToPage("/finance/purchasecoin")
                      }
                    }
                  },
                  [_vm._v("立即充值")]
                )
              ])
            ]),
            _vm._v(" "),
            _c("section", [
              _c(
                "p",
                {
                  staticClass: "part-one",
                  on: {
                    click: function($event) {
                      return _vm.goToPage("/owner/capital")
                    }
                  }
                },
                [
                  _c("span", [_vm._v("我的资金")]),
                  _vm._v(" "),
                  _c("i", { staticClass: "icon el-icon-arrow-right" })
                ]
              ),
              _vm._v(" "),
              _c("i", { staticClass: "line" }),
              _vm._v(" "),
              _c("div", { staticClass: "part-two" }, [
                _vm._m(1),
                _vm._v(" "),
                _c("p", { staticClass: "content" }, [
                  _c("span", [_vm._v(_vm._s(_vm.balanceData.total))]),
                  _vm._v(" "),
                  _c("span", [_vm._v("总余额")])
                ])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "footer" }, [
          _c("div", { staticClass: "bottom" }, [
            _c("p", { staticClass: "title" }, [_vm._v("快捷操作")]),
            _vm._v(" "),
            _c("i", { staticClass: "line" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "content" },
              _vm._l(_vm.shortcutItems, function(item) {
                return _c(
                  "div",
                  {
                    key: item.name,
                    on: {
                      click: function($event) {
                        return _vm.goToPage(item.path)
                      }
                    }
                  },
                  [
                    _c("img", { attrs: { src: item.src, alt: "快捷操作" } }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(item.name))])
                  ]
                )
              }),
              0
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "vip" }, [
            _c(
              "p",
              {
                staticClass: "part-one",
                on: {
                  click: function($event) {
                    return _vm.goPage()
                  }
                }
              },
              [
                _c("span", [_vm._v("会员特权-免费商机")]),
                _vm._v(" "),
                _c("i", { staticClass: "icon el-icon-arrow-right" })
              ]
            ),
            _vm._v(" "),
            _c("i", { staticClass: "line" }),
            _vm._v(" "),
            _c("div", { staticClass: "over" }, [
              _c("div", { staticClass: "count" }, [
                _c("p", { staticClass: "total" }, [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.vipData.vipStatus === _vm.vipStatus.none
                          ? 0
                          : _vm.vipData.memberData.total
                      )
                    )
                  ]),
                  _vm._v("次\n            ")
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "down" }, [_vm._v("账户结余")])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "count" }, [
                _c("p", { staticClass: "total" }, [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.vipData.vipStatus === _vm.vipStatus.none
                          ? 0
                          : _vm.vipData.memberData.expedTotal
                      )
                    )
                  ]),
                  _vm._v("次\n            ")
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "down" }, [_vm._v("累计使用")])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "recommend" },
        [
          _c(
            "p",
            {
              staticClass: "part-one",
              on: {
                click: function($event) {
                  return _vm.goToPage("/niche/store")
                }
              }
            },
            [
              _c("span", [_vm._v("最新商机")]),
              _vm._v(" "),
              _c("i", { staticClass: "icon el-icon-arrow-right" })
            ]
          ),
          _vm._v(" "),
          _c("i", { staticClass: "line" }),
          _vm._v(" "),
          _c("Recommend-niche")
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "Zxb-drawer",
        {
          attrs: { title: "购买记录", visible: _vm.logsVisible },
          on: {
            "update:visible": function($event) {
              _vm.logsVisible = $event
            },
            closed: _vm.closeLogs
          }
        },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleClickTab },
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            [
              _vm.vipData.vipStatus !== _vm.vipStatus.none
                ? _c(
                    "el-tab-pane",
                    { key: "vip", attrs: { label: "会员", name: "1" } },
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.tableLoading,
                              expression: "tableLoading"
                            }
                          ],
                          attrs: {
                            data: _vm.logs.data,
                            "empty-text": "",
                            "header-row-class-name": "table-header"
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "payTimeTxt",
                              label: "支付时间",
                              align: "center"
                            }
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "套餐", align: "center" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        "\n              【" +
                                          _vm._s(scope.row.vipName) +
                                          "】\n            "
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2155075956
                            )
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "finalPrice",
                              label: "金额 (元)",
                              align: "center"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return _c(
                                      "div",
                                      { staticClass: "finalPrice" },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(scope.row.finalPrice) +
                                            "\n            "
                                        )
                                      ]
                                    )
                                  }
                                }
                              ],
                              null,
                              false,
                              1113518705
                            )
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "payType",
                              label: "支付方式",
                              align: "center"
                            }
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "expireOnTxt",
                              label: "到期时间",
                              align: "center"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _vm.logs.totalCount > 10
                        ? _c("Pagination", {
                            attrs: {
                              background: "",
                              layout: "prev, pager, next ,total",
                              limit: _vm.queryInfo.pageSize,
                              page: _vm.queryInfo.pageIndex,
                              total: _vm.logs.totalCount - 0
                            },
                            on: {
                              "update:limit": function($event) {
                                return _vm.$set(
                                  _vm.queryInfo,
                                  "pageSize",
                                  $event
                                )
                              },
                              "update:page": function($event) {
                                return _vm.$set(
                                  _vm.queryInfo,
                                  "pageIndex",
                                  $event
                                )
                              },
                              pagination: function($event) {
                                return _vm.getLogs()
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.channel.status !== _vm.vipStatus.none
                ? _c(
                    "el-tab-pane",
                    { key: "channel", attrs: { label: "渠道", name: "2" } },
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.tableLoading,
                              expression: "tableLoading"
                            }
                          ],
                          attrs: {
                            data: _vm.logs.data,
                            "empty-text": "",
                            "header-row-class-name": "table-header"
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "payTimeTxt",
                              label: "支付时间",
                              align: "center"
                            }
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "套餐",
                              align: "center",
                              width: "80"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        "\n              【" +
                                          _vm._s(scope.row.name) +
                                          "】\n            "
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              105508283
                            )
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "finalPrice",
                              label: "金额 (元)",
                              align: "center"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return _c(
                                      "div",
                                      { staticClass: "finalPrice" },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(scope.row.finalPrice) +
                                            "\n            "
                                        )
                                      ]
                                    )
                                  }
                                }
                              ],
                              null,
                              false,
                              1113518705
                            )
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "payType",
                              label: "支付方式",
                              align: "center",
                              width: "80"
                            }
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "expireOnTxt",
                              label: "到期时间",
                              align: "center"
                            }
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              align: "center",
                              width: "100"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var row = ref.row
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text", size: "mini" },
                                          on: {
                                            click: function($event) {
                                              return _vm.showAgreement(row)
                                            }
                                          }
                                        },
                                        [_vm._v("查看合同")]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3918217164
                            )
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _vm.logs.totalCount > 10
                        ? _c("Pagination", {
                            attrs: {
                              background: "",
                              layout: "prev, pager, next ,total",
                              limit: _vm.queryInfo.pageSize,
                              page: _vm.queryInfo.pageIndex,
                              total: _vm.logs.totalCount - 0
                            },
                            on: {
                              "update:limit": function($event) {
                                return _vm.$set(
                                  _vm.queryInfo,
                                  "pageSize",
                                  $event
                                )
                              },
                              "update:page": function($event) {
                                return _vm.$set(
                                  _vm.queryInfo,
                                  "pageIndex",
                                  $event
                                )
                              },
                              pagination: function($event) {
                                return _vm.getLogs()
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "《合作协议》",
            center: "",
            visible: _vm.agreeDialog,
            width: "1200px",
            "append-to-body": true
          },
          on: {
            "update:visible": function($event) {
              _vm.agreeDialog = $event
            }
          }
        },
        [
          _c("Channel", {
            attrs: { data: _vm.currentCombo, date: _vm.date, record: "" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "icon" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/shop/coin.png"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "icon" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/shop/wallet.png"), alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }