import request from '@/utils/request'

export function list(params) {
  return request({
    url: '/maint/area',
    method: 'GET',
    params
  })
}

export function save(data) {
  let methodName = 'post'
  let url = '/maint/area'
  if (data.id > 0) {
    methodName = 'put'
    url = url + '/' + data.id
  }
  return request({
    url: url,
    method: methodName,
    data
  })
}

export function del(id) {
  return request({
    url: `/maint/area/${id}`,
    method: 'DELETE'
  })
}

export function getHotArea(params) {
  const { type, count } = params
  return request({
    url: `/maint/area/hot/${type}/${count}`,
    method: 'GET'
  })
}

/**
   * 获取区域数据（三级）
   */
export function getAreaThreeTree(includeCH = true) {
  return request({
    url: '/maint/area/' + includeCH,
    method: 'GET'
  })
}

/**
 * 获取区域数据 （二级）
 * @param {*} includeCH
 * @returns
 */
export function getAreaSecondTree(includeCH = false) {
  return request({
    url: '/maint/area/second/' + includeCH,
    method: 'GET'
  })
}
