export default function() {
  const ID = '2025600224'
  return new Promise(function(resolve) {
    // 实例化滑块验证码
    try {
      // eslint-disable-next-line
      let captcha = new TencentCaptcha(ID, function (response) {
        resolve(response)
        captcha.destroy()
        captcha = null
      },
      { bizState: 'data-biz-state' })
      captcha.show() // 显示验证码
    } catch (error) {
      console.log(error)
    }
  })
}
