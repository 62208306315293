<template>
  <AppMain />
</template>

<script>
import { AppMain } from '../components'
export default {
  components: { AppMain }
}
</script>

