<template>
  <el-drawer v-bind="customizedAttrs" v-on="$listeners">
    <span slot="title" class="drawer__title">
      {{ title?`${title}：`:'' }}
    </span>
    <section class="drawer-content">
      <slot />
    </section>
  </el-drawer>
</template>

<script>
export default {
  name: 'ZxbDrawer',
  inheritAttrs: false,
  props: {
    size: {
      type: String,
      default: '700px'
    },
    title: {
      type: String,
      default: ''
    }
  },
  computed: {
    customizedAttrs() {
      return {
        size: this.size,
        // 支持传过来的size覆盖默认的size
        ...this.$attrs
      }
    }
  }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.drawer__title {
  font-weight: 700;
  color: #333333;
}

.drawer-content {
  padding: 0 10px 0 0;
  margin-left: 60px;

  /deep/.is-required{
    margin-left: -10px;

    .el-form-item__content{
      margin-left: 110px !important;
    }
  }

  /deep/.el-form {

    .el-form-item__content {
      line-height: 36px;

      .el-input__count {
        color: #666;
      }

      .vue-treeselect,
      .el-cascader,
      &>.el-select,
      &>.el-input {
        width: 300px;
      }

      .el-textarea {
        width: 460px;
      }
    }

    .el-radio .el-radio__label {
      color: #999;
      font-size: 12px;
    }

    .el-form-item__content,
    .el-form-item__label {
      font-weight: normal;
      color: #666;
      font-size: 14px;
    }

    .el-form-item__label {
      color: #333;
    }

    .el-input-group__append {
      font-size: 12px;
      color: #666;
      text-align: center;
    }

    .tips {
      display: inline-block;
      margin: 0 0 10px 10px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 17px;
      vertical-align: bottom;

      p {
        margin: 0;
      }

      i {
        display: inline-block;
        width: 5px;
        height: 5px;
        background-color: #bbb;
        border-radius: 50%;
        margin: 0 5px 2px 0;
      }
    }

    .form-footer--button {
      .el-button {
        width: 124px;
      }
    }
  }
}
</style>
